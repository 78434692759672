import React, { useContext, useEffect, useState } from "react";
import LayoutOther from "./layout/Layout";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { ApplicationContext } from "../providers/ApplicationProvider";
import KytkaWhite from "../assets/images/logo-white.svg";
import polevka from "../assets/images/soup-min.jpg";
import predkrm from "../assets/images/predkrm-min.jpg";
import kureci from "../assets/images/kureci-min.jpg";
import veprove from "../assets/images/veprove-min.jpg";
import beef from "../assets/images/beef-min.jpg";
import dezerty from "../assets/images/dezert-min.jpeg";
import testovinygnocchi from "../assets/images/testoviny-min.jpg";
import priloha from "../assets/images/prilohy-min.jpg";
import salat from "../assets/images/salat-min.jpg";
import omackyimg from "../assets/images/omackyimg.jpg";
import grills from "../assets/images/grill.jpg";
import axios from "axios";
import { Fade } from "react-awesome-reveal";
import Loading from "./pages/Loading";
import ukrainian from "../assets/images/ukrajinske_jidla.jpg";
import specialities from "../assets/images/bramboraky.jpg";
import burgersimg from "../assets/images/burger.jpg";
import pizzasimg from "../assets/images/pizzas.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Restaurant = () => {
  const [{ lang }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [daily, setDaily] = useState([]);
  const [dailyPolevka, setDailyPolevka] = useState([]);
  const [predrkm, setPredrkm] = useState([]);
  const [polevky, setPolevky] = useState([]);
  const [salaty, setSalaty] = useState([]);
  const [deserty, setDeserty] = useState([]);
  const [testoviny, setTestoviny] = useState([]);
  const [veprovemaso, setVeprovemaso] = useState([]);
  const [hovezimaso, setHovezimaso] = useState([]);
  const [kurecimaso, setKurecimaso] = useState([]);
  const [prilohy, setPrilohy] = useState([]);
  const [omacky, setOmacky] = useState([]);
  const [grill, setGrill] = useState([]);
  const [dipy, setDipy] = useState([]);
  const [specialitiesdishes, setSpecialitiesdishes] = useState([]);
  const [ukrainiandishes, setUkrainiandishes] = useState([]);
  const [pizzas, setPizzas] = useState([]);
  const [burgers, setBurgers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let endpoints = [
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/0`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/1`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/2`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/3`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/4`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/5`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/6`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/7`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/8`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/9`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/12`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/18`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/10`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/19`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/23`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/24`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/25`,
    `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/26`,
  ];

  useEffect(() => {
    setIsLoading(true);
    axios
      .all(
        endpoints.map((endpoint) => axios.get(endpoint), {
          headers: {
            "Content-Type": "application/json",
          },
        })
      )
      .then((data) => setFood(data))
      .catch((err) => console.log(err))
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  const setFood = (data) => {
    setDaily(data[0].data);
    setPredrkm(data[1].data);
    setPolevky(data[2].data);
    setDeserty(data[9].data);
    setKurecimaso(data[3].data);
    setHovezimaso(data[5].data);
    setVeprovemaso(data[4].data);
    setTestoviny(data[6].data);
    setSalaty(data[7].data);
    setPrilohy(data[8].data);
    setDailyPolevka(data[10].data);
    setOmacky(data[11].data);
    setGrill(data[12].data);
    setDipy(data[13].data);
    setSpecialitiesdishes(data[14].data);
    setUkrainiandishes(data[15].data);
    setPizzas(data[16].data);
    setBurgers(data[17].data);
  };

  function renderDailyMenu(e) {
    var array = null;

    if (lang === "cs") {
      array = e.map((item) => (
        <>
          <div className="rest-text">
            <p className="name-head">
              <strong>{item.nameCS}</strong>
            </p>
            <p className="price">
              {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
              <strong>{item.price}&nbsp;Kč</strong>
            </p>
          </div>
          <div className="rest-text">
            <p className="rest-text-desc">{item.descriptionCS}</p>
          </div>
        </>
      ));
    } else if (lang === "de") {
      array = e.map((item) => (
        <>
          <div className="rest-text">
            <p className="name-head">
              <strong>{item.nameDE}</strong>
            </p>
            <p className="price">
              {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
              <strong>{item.price}&nbsp;Kč</strong>
            </p>
          </div>
          <div className="rest-text">
            <p className="rest-text-desc">{item.descriptionDE}</p>
          </div>
        </>
      ));
    } else if (lang === "en") {
      array = e.map((item) => (
        <>
          <div className="rest-text">
            <p className="name-head">
              <strong>{item.nameEN}</strong>
            </p>
            <p className="price">
              {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
              <strong>{item.price}&nbsp;Kč</strong>
            </p>
          </div>
          <div className="rest-text">
            <p className="rest-text-desc">{item.descriptionEN}</p>
          </div>
        </>
      ));
    }

    return array;
  }

  function renderMenu(e) {
    var array = "";
    if (lang === "cs") {
      array = e.map((item) => (
        <>
          <div className="menu-text">
            <p className="name-head">
              <strong>{item.nameCS}</strong>
            </p>
            <p className="price">
              {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
              <strong>{item.price}&nbsp;Kč</strong>
            </p>
          </div>
          <div className="menu-text">
            <p className="menu-text-desc">{item.descriptionCS}</p>
          </div>
        </>
      ));
    } else if (lang === "de") {
      array = e.map((item) => (
        <>
          <div className="menu-text">
            <p className="name-head">
              <strong>{item.nameDE}</strong>
            </p>
            <p className="price">
              {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
              <strong>{item.price}&nbsp;Kč</strong>
            </p>
          </div>
          <div className="menu-text">
            <p className="menu-text-desc">{item.descriptionDE}</p>
          </div>
        </>
      ));
    } else if (lang === "en") {
      array = e.map((item) => (
        <>
          <div className="menu-text">
            <p className="name-head">
              <strong>{item.nameEN}</strong>
            </p>
            <p className="price">
              {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
              <strong>{item.price}&nbsp;Kč</strong>
            </p>
          </div>
          <div className="menu-text">
            <p className="menu-text-desc">{item.descriptionEN}</p>
          </div>
        </>
      ));
    }

    return array;
  }

  function renderDailyCard() {
    return (
      <>
        <Row>
          <h3 className="text-center">
            {lang === "cs"
              ? "Denní menu"
              : lang === "en"
              ? "Daily menu"
              : "Menü des Tages"}
          </h3>
          <p className="text-center"><strong>11:00 - 14:00</strong></p>
        </Row>
        <Row>
          <Col lg>
            <h4>
              {lang === "cs" ? "Polévky" : lang === "en" ? "soups" : "Suppen"}
            </h4>
            {renderDailyMenu(dailyPolevka)}
          </Col>
          <Col lg>
            <h4>
              {lang === "cs"
                ? "Hotová jídla"
                : lang === "en"
                ? "ready-made dishes"
                : "Fertiggerichte"}
            </h4>
            {renderDailyMenu(daily)}
          </Col>
        </Row>
      </>
    );
  }

  function renderDailyCardMobile() {
    return (
      <>
        <Row className="hotel-row">
          <Card>
            <CardBody>
              <Col lg>
                <h3>
                  {lang === "cs"
                    ? "Denní menu"
                    : lang === "en"
                    ? "Daily menu"
                    : "Menü des Tages"}
                </h3>
                <h4>Polévky</h4>
                {renderDailyMenu(dailyPolevka)}
                <h4>Hotová jídla</h4>
                {renderDailyMenu(daily)}
              </Col>
            </CardBody>
          </Card>
        </Row>
      </>
    );
  }
  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!isLoading) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Hotelová restaurace uspokojí náročné chutě každého, kdo má rád českou kuchyni v moderním pojetí. Pod taktovkou šéfkuchaře si hosté vychutnají pestré, zdravé a přitom plnohodnotné snídaně, klasické obědy i moderně připravenou českou večeři."
          />
          <title>
            Wellness Hotel Edelweiss | Restaurace Liberec, obědy, večeře
          </title>
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Restaurace Liberec, obědy, večeře"
          />
          <link
            rel="canonical"
            href="https://www.hoteledelweiss.cz/restaurant"
          ></link>
          <meta
            itemprop="name"
            content="Wellness Hotel Edelweiss | Restaurace Liberec, obědy, večeře"
          />
          <meta
            itemprop="description"
            content="Hotelová restaurace uspokojí náročné chutě každého, kdo má rád českou kuchyni v moderním pojetí. Pod taktovkou šéfkuchaře si hosté vychutnají pestré, zdravé a přitom plnohodnotné snídaně, klasické obědy i moderně připravenou českou večeři."
          />
          <meta
            itemprop="image"
            content="https://hoteledelweiss.cz/static/media/restaurant-min.44204af3f5c4a5725a69.jpg"
          />

          <meta
            property="og:url"
            content="https://hoteledelweiss.cz/restaurant"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Restaurace Liberec, obědy, večeře"
          />
          <meta
            property="og:description"
            content="Hotelová restaurace uspokojí náročné chutě každého, kdo má rád českou kuchyni v moderním pojetí. Pod taktovkou šéfkuchaře si hosté vychutnají pestré, zdravé a přitom plnohodnotné snídaně, klasické obědy i moderně připravenou českou večeři."
          />
          <meta
            property="og:image"
            content="https://hoteledelweiss.cz/static/media/restaurant-min.44204af3f5c4a5725a69.jpg"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Wellness Hotel Edelweiss | Restaurace Liberec, obědy, večeře"
          />
          <meta
            name="twitter:description"
            content="Hotelová restaurace uspokojí náročné chutě každého, kdo má rád českou kuchyni v moderním pojetí. Pod taktovkou šéfkuchaře si hosté vychutnají pestré, zdravé a přitom plnohodnotné snídaně, klasické obědy i moderně připravenou českou večeři."
          />
          <meta
            name="twitter:image"
            content="https://hoteledelweiss.cz/static/media/restaurant-min.44204af3f5c4a5725a69.jpg"
          />

          <meta
            name="description"
            content="Hotelová restaurace uspokojí náročné chutě každého, kdo má rád českou kuchyni v moderním pojetí. Pod taktovkou šéfkuchaře si hosté vychutnají pestré, zdravé a přitom plnohodnotné snídaně, klasické obědy i moderně připravenou českou večeři."
          />
          <meta
            itemprop="name"
            content="Wellness Hotel Edelweiss | Restaurace Liberec, obědy, večeře"
          />
          <meta
            itemprop="description"
            content="Hotelová restaurace uspokojí náročné chutě každého, kdo má rád českou kuchyni v moderním pojetí. Pod taktovkou šéfkuchaře si hosté vychutnají pestré, zdravé a přitom plnohodnotné snídaně, klasické obědy i moderně připravenou českou večeři."
          />
        </Helmet>
        <Fade>
          <LayoutOther>
            <div className="showcase-restaurant">
              <div className="showcase-content">
                <div className="showcase-hotel-table">
                  <Container>
                    <div className="welcome-text hotel-text col-md-8">
                      <h1>
                        {lang === "cs" ? "NAŠE RESTAURACE" : "RESTAURANT"}
                      </h1>
                      {lang === "cs" ? (
                        <>
                          <p>
                            Hotelová restaurace uspokojí náročné chutě každého,
                            kdo má rád českou kuchyni v&nbsp;moderním pojetí.
                            Pod taktovkou šéfkuchaře si hosté vychutnají pestré,
                            zdravé a&nbsp;přitom plnohodnotné snídaně, klasické
                            obědy i&nbsp;moderně připravenou českou večeři.
                          </p>
                          <p>
                            Restauraci pro třicet osob mohou navštívit
                            i&nbsp;hosté z&nbsp;venku. Náš tým rád připraví také
                            obchodní setkání, rodinné oslavy, svatby či firemní
                            teambuildingy.
                          </p>
                        </>
                      ) : lang === "en" ? (
                        <>
                          <p>
                            The hotel restaurant will satisfy the&nbsp;demanding
                            tastes of anyone who likes Czech cuisine
                            in&nbsp;a&nbsp;modern concept. Under the&nbsp;baton
                            of&nbsp;the&nbsp;chef, guests can enjoy
                            a&nbsp;varied, healthy yet full breakfast, classic
                            lunches and&nbsp;a&nbsp;modern Czech dinner.
                          </p>
                          <p>
                            The restaurant for thirty people can also be visited
                            by guests from outside. Our team will also be happy
                            to prepare business meetings, family celebrations,
                            weddings or corporate teambuilding.
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Das Hotelrestaurant befriedigt die anspruchsvollen
                            Geschmäcker aller Liebhaber der tschechischen Küche
                            in einem modernen Konzept. Unter der Leitung des
                            Küchenchefs können die Gäste ein
                            abwechslungsreiches, gesundes und vollwertiges
                            Frühstück, klassische Mittagessen und ein modernes
                            tschechisches Abendessen genießen.
                          </p>
                          <p>
                            Das Restaurant für dreißig Personen kann auch von
                            externen Gästen besucht werden. Unser Team bereitet
                            auch gerne Geschäftstreffen, Familienfeiern,
                            Hochzeiten oder Firmen-Teambuildings vor.
                          </p>
                        </>
                      )}
                      <p></p>
                      <Link to="/restaurant/menu/select">
                        <button className="rest-button">
                          {lang === "cs"
                            ? "MENU"
                            : lang === "en"
                            ? "MENU"
                            : "MENU"}
                        </button>
                      </Link>
                      <p>
                        <br></br>
                      </p>
                    </div>
                  </Container>
                  <img src={KytkaWhite} className="kytka-white" alt="kytka" />
                </div>
              </div>
            </div>
            {/*
            {lang === "cs" ? (
              <Container>
                <div className="akce-vecery-sil">
                  <Col align="center">
                    <h2 className="m-0">SVATOMARTINSKÉ MENU</h2>
                    <p className="text-center">09. - 11.11.2024</p>
                    <p>
                      Ochutnejte pravou svatomartinskou klasiku!<br></br>
                      Připravili jsme pro Vás speciální menu z těch
                      nejkvalitnějších surovin. Křupavá husa, domácí knedlíky,
                      červené zelí a mladé víno – to vše v jedinečné kombinaci.
                    </p>
                  </Col>
                  <Col align="center">
                    <a
                      href="https://hoteledelweiss.cz/Menu/svatomartinske_menu_2024.pdf"
                      target="_blank"
                      className="icon-text"
                    >
                      <button className="rest-button valentine mt-0 my-sm-2">
                        MENU
                      </button>
                    </a>
                  </Col>
                </div>
              </Container>
            ) : lang === "en" ? (
              <Container>
                <div className="akce-vecery-sil">
                  <Col align="center">
                    <h2 className="m-0">St. Martin's Day menu</h2>
                    <p className="text-center">09. - 11.11.2024</p>
                    <p>
                      Indulge in the traditional flavors of St. Martin's Day.
                      <br></br>
                      Our specially crafted menu showcases the finest
                      ingredients. Savor the delectable combination of crispy
                      goose, homemade dumplings, red cabbage, and young wine.
                    </p>
                  </Col>
                  <Col align="center">
                    <a
                      href="https://hoteledelweiss.cz/Menu/svatomartinske_menu_2024.pdf"
                      target="_blank"
                      className="icon-text"
                    >
                      <button className="rest-button valentine mt-0 my-sm-2">
                        MENU
                      </button>
                    </a>
                  </Col>
                </div>
              </Container>
            ) : (
              <Container>
                <div className="akce-vecery-sil">
                  <Col align="center">
                    <h2 className="m-0">Martinsgansl-Menü</h2>
                    <p className="text-center">09. - 11.11.2024</p>
                    <p>
                      Lassen Sie sich von den traditionellen Aromen des
                      Martinstags verführen. <br></br>
                      Unser sorgfältig zusammengestelltes Menü präsentiert die
                      feinsten Zutaten. Genießen Sie die köstliche Kombination
                      aus knuspriger Gans, hausgemachten Knödeln, Rotkohl und
                      jungem Wein.
                    </p>
                  </Col>
                  <Col align="center">
                    <a
                      href="https://hoteledelweiss.cz/Menu/svatomartinske_menu_2024.pdf"
                      target="_blank"
                      className="icon-text"
                    >
                      <button className="rest-button valentine mt-0 my-sm-2">
                        MENU
                      </button>
                    </a>
                  </Col>
                </div>
              </Container>
            )}*/}
            {/*
            {lang === "cs" ? (
              <Container>
                <div className="akce-vecery">
                  <Col align="center">
                    <h2 className="m-0">VALENTÝNSKÉ MENU</h2>
                    <p className="text-center">14.02.2024</p>
                    <p>
                      Přijďte rozzářit svá srdce a dejte si naše Valentýnské
                      menu, neboť staré rčení praví, že láska prochází žaludkem
                      a my v Hotel Edelweiss věříme, že je to pravda.
                    </p>
                  </Col>
                  <Col align="center">
                    <a
                      href="https://hoteledelweiss.cz/Menu/valentyn_menu.pdf"
                      target="_blank"
                      className="icon-text"
                    >
                      <button className="rest-button valentine mt-0 my-sm-2">
                        MENU
                      </button>
                    </a>
                  </Col>
                </div>
              </Container>
            ) : lang === "de" ? (
              <Container>
                <div className="akce-vecery">
                  <Col align="center">
                    <h2 className="m-0">VALENTINSMENÜ</h2>
                    <p className="text-center">14.02.2024</p>
                    <p>
                      Kommen Sie und bringen Sie Ihre Herzen zum Leuchten und
                      genießen Sie das Valentinstagsmenü, denn das alte
                      Sprichwort, dass Liebe durch den Magen geht, ist wahr und
                      wir im Hotel Edelweiss glauben an diese Weisheit.
                    </p>
                  </Col>
                  <Col align="center">
                    <a
                      href="https://hoteledelweiss.cz/Menu/valentyn_menu.pdf"
                      target="_blank"
                      className="icon-text"
                    >
                      <button className="rest-button valentine mt-0 my-sm-2">
                        MENU
                      </button>
                    </a>
                  </Col>
                </div>
              </Container>
            ) : (
              <Container>
                <div className="akce-vecery">
                  <Col align="center">
                    <h2 className="m-0">VALENTINE'S MENU</h2>
                    <p className="text-center">14.02.2024</p>
                    <p>
                      Come and light up your hearts and enjoy the Valentine's
                      Day menu, for the old saying is true that love goes
                      through the stomach and we in Hotel Edelweiss believe it
                      to be true.
                    </p>
                  </Col>
                  <Col align="center">
                    <a
                      href="https://hoteledelweiss.cz/Menu/valentyn_menu.pdf"
                      target="_blank"
                      className="icon-text"
                    >
                      <button className="rest-button valentine mt-0 my-sm-2">
                        MENU
                      </button>
                    </a>
                  </Col>
                </div>
              </Container>
            )}*/}
            <div className="restaurant-content-mobile">
              <div className="container restaurant-page">
                {renderDailyCardMobile()}
              </div>
            </div>
            <div className="restaurant-content-others">
              <div className="restaurant-page container">
                {renderDailyCard()}
              </div>
            </div>
            <a class="anchor" id="dailymenu"></a>
            <Container fluid className="well-rest rest-menu">
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs"
                        ? "Předkrmy"
                        : lang === "en"
                        ? "appetizers"
                        : "Vorspeisen"}
                      <span></span>
                    </h3>
                    {renderMenu(predrkm)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img src={predkrm} alt="polévka" className="restaurant-img" />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img src={polevka} alt="předrkm" className="wellness-img" />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs"
                        ? "polévky"
                        : lang === "en"
                        ? "soups"
                        : "Suppen"}
                    </h3>
                    {renderMenu(polevky)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs"
                        ? "kuřecí maso"
                        : lang === "en"
                        ? "chicken meat"
                        : "Hähnchen"}
                      <span></span>
                    </h3>
                    {renderMenu(kurecimaso)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img
                    src={kureci}
                    alt="kuřecí maso"
                    className="restaurant-img"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img
                    src={veprove}
                    alt="vepřové maso"
                    className="wellness-img"
                  />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs"
                        ? "vepřové maso"
                        : lang === "en"
                        ? "pork meat"
                        : "Schweinefleisch"}
                    </h3>
                    {renderMenu(veprovemaso)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs"
                        ? "hovězí maso"
                        : lang === "en"
                        ? "beef meat"
                        : "Rindfleisch"}
                      <span></span>
                    </h3>
                    {renderMenu(hovezimaso)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img
                    src={beef}
                    alt="hovězí maso"
                    className="restaurant-img"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img
                    src={testovinygnocchi}
                    alt="těstoviny"
                    className="wellness-img"
                  />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs"
                        ? "těstoviny a gnocchi"
                        : lang === "en"
                        ? "pasta and gnocchi"
                        : "Nudeln und Gnocchi"}
                    </h3>
                    {renderMenu(testoviny)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs"
                        ? "Speciality našeho podniku"
                        : lang === "en"
                        ? "our specialties"
                        : "Hausspezialitäten"}
                      <span></span>
                    </h3>
                    {renderMenu(specialitiesdishes)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img
                    src={specialities}
                    alt="naše speciality"
                    className="restaurant-img"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img
                    src={ukrainian}
                    alt="ukrajinská jídla"
                    className="wellness-img"
                  />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs"
                        ? "Ukrajinská jídla"
                        : lang === "en"
                        ? "Ukrainian dishes"
                        : "ukrainische küche"}
                    </h3>
                    {renderMenu(ukrainiandishes)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs"
                        ? "saláty"
                        : lang === "en"
                        ? "salads"
                        : "Salate"}
                      <span></span>
                    </h3>
                    {renderMenu(salaty)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img src={salat} alt="salát" className="restaurant-img" />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img
                    src={priloha}
                    alt="restaurant"
                    className="wellness-img"
                  />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs"
                        ? "přílohy"
                        : lang === "en"
                        ? "Side dishes"
                        : "Beilagen"}
                    </h3>
                    {renderMenu(prilohy)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs"
                        ? "omáčky a dipy"
                        : lang === "en"
                        ? "sauces and dips"
                        : "Saucen und Dips"}
                      <span></span>
                    </h3>
                    {renderMenu(omacky)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img
                    src={omackyimg}
                    alt="restaurant"
                    className="restaurant-img"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img
                    src={dezerty}
                    alt="restaurant"
                    className="restaurant-img"
                  />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs"
                        ? "dezerty"
                        : lang === "en"
                        ? "desserts"
                        : "Nachspeisen"}
                      <span></span>
                    </h3>
                    {renderMenu(deserty)}
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="showcase-garden-restaurant" id="garden-restaurant">
              <div className="showcase-content">
                <div className="showcase-hotel-table">
                  <Container>
                    <div className="welcome-text hotel-text col-md-8">
                      <h3>
                        {lang === "cs"
                          ? "ZAHRADNÍ RESTAURACE"
                          : lang === "en"
                          ? "GARDEN RESTAURANT"
                          : "GARTENRESTAURANT"}
                      </h3>
                      {lang === "cs" ? (
                        <p>
                          Letní sezení s&nbsp;venkovním grilem a udírnou navíc
                          zpestří zahradní terasa určená dalším více než třem
                          desítkám hostů. Přijďte ochutnat naše vyhlášené domácí
                          uzené maso!
                        </p>
                      ) : lang === "en" ? (
                        <p>
                          In&nbsp;addition, the&nbsp;summer sitting
                          with&nbsp;an&nbsp;outdoor grill and&nbsp;smokehouse
                          will be enriched by the&nbsp;garden terrace intended
                          for more than three dozen other guests. Come
                          and&nbsp;taste our renowned homemade smoked meat!
                        </p>
                      ) : (
                        <p>
                          Darüber hinaus wird die Sommersitzung mit Außengrill
                          und Räucherei durch die Gartenterrasse bereichert, die
                          für mehr als drei Dutzend andere Gäste bestimmt ist.
                          Kommen Sie und probieren Sie unser berühmtes
                          hausgemachtes Rauchfleisch!
                        </p>
                      )}
                    </div>
                  </Container>
                  <img src={KytkaWhite} className="kytka-white" alt="kytka" />
                </div>
              </div>
            </div>
            <Container fluid className="well-rest rest-menu grill">
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs" ? "PIZZY" : "PIZZAS"}
                      <span></span>
                    </h3>
                    {renderMenu(pizzas)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img
                    src={pizzasimg}
                    alt="restaurant"
                    className="restaurant-img"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img
                    src={burgersimg}
                    alt="restaurant"
                    className="restaurant-img"
                  />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs"
                        ? "BURGERY, TWISTERY"
                        : lang === "en"
                        ? "BURGERS, TWISTERS"
                        : "BURGER, TWISTER"}
                      <span></span>
                    </h3>
                    {renderMenu(burgers)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 well-rest-card">
                  <div className="restaurant-text">
                    <h3>
                      {lang === "cs" ? "GRIL" : "GRILL"}
                      <span></span>
                    </h3>
                    {renderMenu(grill)}
                  </div>
                </Col>
                <Col md={6} className="p-0 m-0">
                  <img
                    src={grills}
                    alt="restaurant"
                    className="restaurant-img"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-0 m-0 order-5 order-md-1">
                  <img
                    src={omackyimg}
                    alt="restaurant"
                    className="restaurant-img"
                  />
                </Col>
                <Col
                  md={6}
                  className="p-0 well-rest-card green order-1 order-md-5"
                >
                  <div className="wellness-text">
                    <h3>
                      {lang === "cs" ? "dipy" : lang === "en" ? "dips" : "Dips"}
                      <span></span>
                    </h3>
                    {renderMenu(dipy)}
                  </div>
                </Col>
              </Row>
            </Container>
          </LayoutOther>
        </Fade>
      </>
    );
  }
};

export default Restaurant;
