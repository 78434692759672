import React, { useEffect, useContext, useState } from "react";
import {
  ApplicationContext,
  SET_LANG,
} from "../../providers/ApplicationProvider";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import { Turn as Hamburger } from "hamburger-react";
import axios from "axios";
import "../styles/typography.css";
import "../styles/sidebar.css";

const NavmenuOther = () => {
  const [{ lang }, dispatch] = useContext(ApplicationContext);

  useEffect(() => {
    let no = window.localStorage.getItem("lang");
    if (no === null) {
      no = "cs";
    }
    dispatch({ type: SET_LANG, payload: no });
  }, [dispatch]);

  useEffect(() => {
    window.localStorage.setItem("lang", lang);
  }, [lang]);

  const logout = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Account/logout`)
      .then(() => {
        window.location.reload();
      });
  };

  const [hamb, setHamb] = useState(false);

  return (
    <header>
      <nav className={`sidebar ${hamb ? "active" : ""}`}>
        <ul class={`sidebar-list ${hamb ? "active" : ""}`}>
          <NavItem className="restaurant-nav-item">
            <NavLink className="hover-underline-animation" to="/" tag={Link}>
              HOME
            </NavLink>
          </NavItem>
          <NavItem className="restaurant-nav-item">
            <NavLink
              className="hover-underline-animation"
              to="/recepce/profile"
              tag={Link}
            >
              RESTAURACE
            </NavLink>
          </NavItem>
          <NavItem className="restaurant-nav-item">
            <NavLink
              className="hover-underline-animation"
              to="/recepce/actions"
              tag={Link}
            >
              AKCE
            </NavLink>
          </NavItem>
          <NavItem className="restaurant-nav-item">
            <NavLink
              className="hover-underline-animation"
              to="/admin/welcome-card"
              tag={Link}
            >
              UVÍTACÍ KARTY
            </NavLink>
          </NavItem>
          <NavItem className="restaurant-nav-item">
            <NavLink
              className="hover-underline-animation"
              to="/admin/help"
              tag={Link}
            >
              POMOC | HELP
            </NavLink>
          </NavItem>
          <NavItem className="restaurant-nav-item">
            <NavLink
              className="hover-underline-animation"
              to="/admin/qr-code"
              tag={Link}
            >
              QR kódy
            </NavLink>
          </NavItem>
          <NavItem className="restaurant-nav-item">
            <NavLink
              className="hover-underline-animation"
              to="/admin/gallery-list/type"
              tag={Link}
            >
              GALERIE
            </NavLink>
          </NavItem>
          <NavItem className="devider">
            <hr className="devider-sidebar"></hr>
          </NavItem>
          <NavItem>
            <NavLink
              className="hover-underline-animation lang"
              onClick={() => logout()}
            >
              <strong>ODHLÁSIT SE</strong>
            </NavLink>
          </NavItem>
        </ul>
      </nav>
      <nav className={`navbar-color top-nav fixed-top ${hamb ? "" : "active"}`}>
        <Hamburger
          direction="right"
          className="hamburger-react"
          rounded
          color="#cb8e00"
          duration={0.5}
          toggle={setHamb}
          toggled={hamb}
          size={45}
        />
        <ul className={`navbar-nav mr-auto mt-lg-0`}>
          <div className="center">
            <NavItem className="restaurant-nav-item">
              <NavLink className="hover-underline-animation" to="/" tag={Link}>
                HOME
              </NavLink>
            </NavItem>
            <NavItem className="restaurant-nav-item">
              <NavLink
                className="hover-underline-animation"
                to="/login"
                tag={Link}
              >
                RESTAURACE
              </NavLink>
            </NavItem>
            <NavItem className="restaurant-nav-item">
              <NavLink
                className="hover-underline-animation"
                to="/recepce/actions"
                tag={Link}
              >
                AKCE
              </NavLink>
            </NavItem>
            <NavItem className="restaurant-nav-item">
              <NavLink
                className="hover-underline-animation"
                to="/admin/welcome-card"
                tag={Link}
              >
                UVÍTACÍ KARTY
              </NavLink>
            </NavItem>
            <NavItem className="restaurant-nav-item">
              <NavLink
                className="hover-underline-animation"
                to="/admin/help"
                tag={Link}
              >
                POMOC | HELP
              </NavLink>
            </NavItem>
            <NavItem className="restaurant-nav-item">
              <NavLink
                className="hover-underline-animation"
                to="/admin/qr-code"
                tag={Link}
              >
                QR kódy
              </NavLink>
            </NavItem>
            <NavItem className="restaurant-nav-item">
              <NavLink
                className="hover-underline-animation"
                to="/admin/gallery-list/type"
                tag={Link}
              >
                GALERIE
              </NavLink>
            </NavItem>
          </div>

          <div className="right">
            <NavItem>
              <NavLink
                className="hover-underline-animation lang"
                onClick={() => logout()}
              >
                <strong>ODHLÁSIT SE</strong>
              </NavLink>
            </NavItem>
          </div>
        </ul>
      </nav>
    </header>
  );
};

export default NavmenuOther;
