import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import LayoutOther from "../../layout/LayoutOther";
import { Container, Input, Label } from "reactstrap";
import axios from "axios";
import FileUploadEdit from "./FileUploadEdit";

const FoodEdit = () => {
  return (
    <LayoutOther>
      <Container>
        <div className="admin-content">
          <Link to={`/recepce/actions`}>
            <button className="login-button">Zpět</button>
          </Link>
          <FileUploadEdit />
        </div>
      </Container>
    </LayoutOther>
  );
};

export default FoodEdit;
