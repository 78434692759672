import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import LayoutOther from "../../layout/LayoutOther";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../pages/AccessDenied";
import { Container, Table } from "reactstrap";
import ReactDOMServer from "react-dom/server";
import toast, { Toaster } from "react-hot-toast";
import { ReactSortable } from "react-sortablejs";
import "../../pages/style.css";

const FoodByType = () => {
  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);
  const [language, setLanguage] = useState(" ");
  const { id } = useParams();

  useEffect(() => {
    renderTemp();
  }, [id]);

  function renderTemp() {
    var temp1,
      temp2,
      temp3,
      temp4 = "";
    if (id === "0" || id === "12") {
      temp1 = 12;
      temp2 = 0;
    } else if (id === "1" || id === "2") {
      temp1 = 1;
      temp2 = 2;
    } else if (id === "30" || id === "31" || id === "32" || id === "33") {
      temp1 = 30;
      temp2 = 31;
      temp3 = 32;
      temp4 = 33;
    } else if (id === "3" || id === "4") {
      temp1 = 3;
      temp2 = 4;
    } else if (id === "5" || id === "6") {
      temp1 = 5;
      temp2 = 6;
    } else if (id === "7" || id === "8") {
      temp1 = 7;
      temp2 = 8;
    } else if (id === "9" || id === "18") {
      temp1 = 18;
      temp2 = 9;
    } else if (id === "14" || id === "17") {
      temp1 = 17;
      temp2 = 14;
    } else if (id === "15" || id === "16") {
      temp1 = 15;
      temp2 = 16;
    } else if (id === "10" || id === "19") {
      temp1 = 10;
      temp2 = 19;
    } else if (id === "20" || id === "21") {
      temp1 = 20;
      temp2 = 21;
    } else if (id === "23" || id === "24") {
      temp1 = 23;
      temp2 = 24;
    } else if (id === "25" || id === "26") {
      temp1 = 25;
      temp2 = 26;
    } else if (id === "13") {
      temp1 = 13;
      temp2 = 0;
    }

    return [temp1, temp2, temp3, temp4];
  }

  function renderEndpoints() {
    let endpoints = [];
    if (id === "13") {
      endpoints = `${
        process.env.REACT_APP_BACKEND_URL
      }/restaurant/getByType/admin/${renderTemp()[0]}`;
    } else if (id === "30" || id === "31" || id === "32" || id === "33") {
      endpoints = [
        `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/admin/${
          renderTemp()[0]
        }`,
        `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/admin/${
          renderTemp()[1]
        }`,

        `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/admin/${
          renderTemp()[2]
        }`,

        `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/admin/${
          renderTemp()[3]
        }`,
      ];
    } else if (id !== "13") {
      endpoints = [
        `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/admin/${
          renderTemp()[0]
        }`,
        `${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/admin/${
          renderTemp()[1]
        }`,
      ];
    }
    return endpoints;
  }

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/Restaurant/getByType/admin/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setIsLoading(false));
  }, [id]);

  const removeItem = (index) => {
    data.splice(index, 1);
    setData([...data]);
  };

  function deleteFood(item, index) {
    setIsLoading(true);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/remove/${item}`)
      .then(() => {
        removeItem(index);
        setDone(true);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return data;
  }

  function renderType(types) {
    if (language === "de") {
      switch (types.toString()) {
        case "0":
          return "Denní menu - hotovky";
        case "1":
          return "Vorspeisen";
        case "2":
          return "Suppen";
        case "3":
          return "Hähnchen";
        case "4":
          return "Schweinefleisch";
        case "5":
          return "Rindfleisch";
        case "6":
          return "Nudeln und Gnocchi";
        case "7":
          return "Salate";
        case "8":
          return "Beilagen";
        case "9":
          return "Nachspeisen";
        case "10":
          return "Grill";
        case "11":
          return "MINIBAR";
        case "12":
          return "Denní menu - polévky";
        case "13":
          return "Kaffee und Tee";
        case "14":
          return "Bier und Fass";
        case "15":
          return "Aperitifs und Spirituosen";
        case "16":
          return "Wein";
        case "17":
          return "alkoholfreie Getränke";
        case "18":
          return "Saucen und Dips";
        case "19":
          return "Dips";
        case "20":
          return "alkoholfreie Cocktails";
        case "21":
          return "alkohol Cocktails";
        case "23":
          return "Hausspezialitäten";
        case "24":
          return "ukrainische küche";
        case "25":
          return "Pizzen";
        case "26":
          return "Burger und twister";
        case "30":
          return "Suppen";
        case "31":
          return "Hauptgerichte";
        case "32":
          return "Salate";
        case "33":
          return "Desserts";
        default:
          return "Denní menu";
      }
    } else if (language === "en") {
      switch (types.toString()) {
        case "0":
          return "Denní menu - hotovky";
        case "1":
          return "Appetizers";
        case "2":
          return "Soups";
        case "3":
          return "Chicken meat";
        case "4":
          return "Pork meat";
        case "5":
          return "Beef meat";
        case "6":
          return "Pasta and gnocchi";
        case "7":
          return "Salads";
        case "8":
          return "Side dishes";
        case "9":
          return "Desserts";
        case "10":
          return "Grill";
        case "11":
          return "MINIBAR";
        case "12":
          return "Daily menu - soups";
        case "13":
          return "Coffee and tea";
        case "14":
          return "Beer and draft";
        case "15":
          return "aperitifs and spirits";
        case "16":
          return "wine";
        case "17":
          return "nonalcoholic drinks";
        case "18":
          return "Sauces and dips";
        case "19":
          return "Dips";
        case "20":
          return "nonalcoholic cocktails";
        case "21":
          return "alcoholic cocktails";
        case "23":
          return "our specialties";
        case "24":
          return "Ukrainian food";
        case "25":
          return "Pizzas";
        case "26":
          return "Burgers and twisters";
        case "30":
          return "Soups";
        case "31":
          return "Main Dishes";
        case "32":
          return "Salads";
        case "33":
          return "Desserts";
        default:
          return "Denní menu";
      }
    } else {
      switch (types.toString()) {
        case "0":
          return "Denní menu - hotovky";
        case "1":
          return "Předkrmy";
        case "2":
          return "Polévky";
        case "3":
          return "Kuřecí maso";
        case "4":
          return "Vepřové maso";
        case "5":
          return "Hovězí maso";
        case "6":
          return "Těstoviny a gnocchi";
        case "7":
          return "Saláty";
        case "8":
          return "Přílohy";
        case "9":
          return "Dezerty";
        case "10":
          return "Grill";
        case "11":
          return "MINIBAR";
        case "12":
          return "Denní menu - polévky";
        case "13":
          return "Káva a čaj";
        case "14":
          return "Pivo a čepované";
        case "15":
          return "aperitivy a destiláty";
        case "16":
          return "víno";
        case "17":
          return "nealkoholické nápoje";
        case "18":
          return "omáčky a dipy";
        case "19":
          return "Dipy";
        case "20":
          return "Nealkoholické koktejly";
        case "21":
          return "Alkoholické koktejly";
        case "23":
          return "Speciality našeho podniku";
        case "24":
          return "Ukrajinská jídla";
        case "25":
          return "Pizzy";
        case "26":
          return "Burgery a twistery";
        case "30":
          return "Polévky";
        case "31":
          return "Hlavní jídla";
        case "32":
          return "Saláty";
        case "33":
          return "Dezerty";
        default:
          return "Denní menu";
      }
    }
  }

  function renderDailyMenu() {
    if (id === "0" || id === "12") {
      return (
        <>
          {language === "cz" || language === "de" || language === "en" ? (
            <>
              <button
                className="login-button me-3"
                onClick={() => sendMenu("printMenu")}
              >
                Vygenerovat denní menu
              </button>
            </>
          ) : (
            <>
              Zvolte jazyk před generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("cz");
                }}
              >
                CZ
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("en");
                }}
              >
                EN
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("de");
                }}
              >
                DE
              </button>
            </>
          )}

          <Link to="/admin/daily-menu/list">
            <button className="login-button">List denních menu</button>
          </Link>
        </>
      );
    }
    if (id === "30" || id === "31" || id === "32" || id === "33") {
      return (
        <>
          {language === "cz" || language === "de" || language === "en" ? (
            <>
              <button
                className="login-button me-3"
                onClick={() => sendMenu("printDomovList")}
              >
                Vygenerovat denní menu DOMOV
              </button>
            </>
          ) : (
            <>
              Zvolte jazyk před generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("cz");
                }}
              >
                CZ
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("en");
                }}
              >
                EN
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("de");
                }}
              >
                DE
              </button>
            </>
          )}

          <Link to="/admin/domov-daily-menu/list">
            <button className="login-button">List denních menu DOMOV</button>
          </Link>
        </>
      );
    } else if (id === "11") {
      return (
        <>
          {language === "cz" || language === "de" || language === "en" ? (
            <>
              <button
                className="login-button me-3"
                onClick={() => {
                  sendMenu("printMinibar");
                  setLanguage(" ");
                }}
              >
                Vygenerovat minibar
              </button>
            </>
          ) : (
            <>
              Zvolte jazyk před generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("cz");
                }}
              >
                CZ
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("en");
                }}
              >
                EN
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("de");
                }}
              >
                DE
              </button>
            </>
          )}

          <Link to="/admin/minibar/list">
            <button className="login-button">List minibar</button>
          </Link>
        </>
      );
    } else if (
      id === "13" ||
      id === "14" ||
      id === "15" ||
      id === "16" ||
      id === "17" ||
      id === "20" ||
      id === "21"
    ) {
      return (
        <>
          {language === "cz" || language === "de" || language === "en" ? (
            <>
              <button
                className="login-button me-3"
                onClick={() => {
                  sendMenu("printDrinkList");
                  setLanguage(" ");
                }}
              >
                Vygenerovat nápojový lístek
              </button>
            </>
          ) : (
            <>
              Zvolte jazyk před generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("cz");
                }}
              >
                CZ
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("en");
                }}
              >
                EN
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("de");
                }}
              >
                DE
              </button>
            </>
          )}
          <Link to="/admin/drink-list/list">
            <button className="login-button">List nápojových lístků</button>
          </Link>
        </>
      );
    } else if (id === "25" || id === "26") {
      return (
        <>
          {language === "cz" || language === "de" || language === "en" ? (
            <>
              <button
                className="login-button me-3"
                onClick={() => {
                  sendMenu("printPizzas");
                  setLanguage(" ");
                }}
              >
                Vygenerovat jídelnní lístek
              </button>
            </>
          ) : (
            <>
              Zvolte jazyk před generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("cz");
                }}
              >
                CZ
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("en");
                }}
              >
                EN
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("de");
                }}
              >
                DE
              </button>
            </>
          )}
          <Link to="/admin/season-menu/list">
            <button className="login-button">List jídelnních lístků</button>
          </Link>
        </>
      );
    } else {
      return (
        <>
          {language === "cz" || language === "de" || language === "en" ? (
            <>
              <button
                className="login-button me-3"
                onClick={() => {
                  sendMenu("printSeasonMenu");
                  setLanguage(" ");
                }}
              >
                Vygenerovat jídelnní lístek
              </button>
            </>
          ) : (
            <>
              Zvolte jazyk před generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("cz");
                }}
              >
                CZ
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("en");
                }}
              >
                EN
              </button>
              <button
                className="login-button me-3"
                onClick={() => {
                  setLanguage("de");
                }}
              >
                DE
              </button>
            </>
          )}
          <Link to="/admin/season-menu/list">
            <button className="login-button">List jídelnních lístků</button>
          </Link>
        </>
      );
    }
  }

  const notify = () => toast.success("Denní menu bylo vygenerováno!");
  const notifyError = () =>
    toast.error(
      "Někde se stala chyba, zkuste to znovu! Nebo napište správci: tom.kulhavy@seznam.cz"
    );

  function setAllFood() {
    if (id === "13") {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/Restaurant/getByType/admin/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => setPrintData(res))
        .catch((err) => console.log(err))
        .then(() => setIsLoading(false));
    } else {
      axios
        .all(
          renderEndpoints().map((endpoint) => axios.get(endpoint), {
            headers: { "Content-Type": "application/json" },
          })
        )
        .then((res) => setPrintData(res))
        .catch((err) => console.log(err))
        .then(() => setIsLoading(false));
    }
  }

  const [temp, setTemp] = useState("h");

  function setPrintData(data) {
    setFinalData([]);
    if (id === "0" || id === "12") {
      setFinalData([...data[1].data, ...temp, ...data[0].data]);
    }
    if (id === "30" || id === "31" || id === "32" || id === "33") {
      setFinalData([
        ..."p",
        ...data[0].data,
        ..."h",
        ...data[1].data,
        ..."s",
        ...data[2].data,
        ..."d",
        ...data[3].data,
      ]);
    } else if (id === "13") {
      setFinalData([...data.data]);
    } else {
      setFinalData([...data[0].data, ...temp, ...data[1].data]);
    }
  }

  function sendMenu(printId) {
    if (id !== "11") {
      setAllFood();
    }

    let htmlString;
    if (printId === "printMinibar") {
      let temp = ReactDOMServer.renderToStaticMarkup(renderBodyMinibar());
      temp = ReactDOMServer.renderToStaticMarkup(renderBodyMinibar());
      htmlString = temp;
    } else if (printId === "printSeasonMenu") {
      let temp = ReactDOMServer.renderToStaticMarkup(
        renderBodyMenu(renderType(renderTemp()[1]))
      );
      temp = ReactDOMServer.renderToStaticMarkup(
        renderBodyMenu(renderType(renderTemp()[1]))
      );
      htmlString = temp;
    } else if (printId === "printMenu") {
      let temp = ReactDOMServer.renderToStaticMarkup(renderBody());
      temp = ReactDOMServer.renderToStaticMarkup(renderBody());
      htmlString = temp;
    } else if (printId === "printDomovList") {
      let temp = ReactDOMServer.renderToStaticMarkup(renderBodyDomov());
      temp = ReactDOMServer.renderToStaticMarkup(renderBodyDomov());
      htmlString = temp;
    } else if (printId === "printPizzas") {
      let temp = ReactDOMServer.renderToStaticMarkup(renderPizzas());
      temp = ReactDOMServer.renderToStaticMarkup(renderPizzas());
      htmlString = temp;
    } else if (printId === "printDrinkList") {
      let temp = ReactDOMServer.renderToStaticMarkup(
        renderBodyDrink(renderType(renderTemp()[1]))
      );
      temp = ReactDOMServer.renderToStaticMarkup(
        renderBodyDrink(renderType(renderTemp()[1]))
      );
      htmlString = temp;
    }

    let axiosTemp = "";

    console.log(renderTemp()[0]);

    if (id === "13") {
      axiosTemp = printId === "printDrinkList" ? "/" + id : "";
    } else if (
      id === "14" ||
      id === "15" ||
      id === "16" ||
      id === "17" ||
      id === "20" ||
      id === "21"
    ) {
      axiosTemp = printId === "printDrinkList" ? "/" + renderTemp()[0] : "";
    } else if (id === "25" || id === "26") {
      axiosTemp = printId === "printPizzas" ? "/" + renderTemp()[0] : "";
    } else {
      axiosTemp = printId === "printSeasonMenu" ? "/" + renderTemp()[0] : "";
    }
    let menuTemp = "";

    if (id === "0" || id === "12") {
      menuTemp = "/" + new Date().toLocaleDateString();
    }
    if (id === "25" || id === "26") {
      printId = "printSeasonMenu";
    }

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/Restaurant/${printId}${axiosTemp}${menuTemp}/${language}`,
        {
          Menu: htmlString,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        setDone(true);
        notify();
      })
      .catch((error) => {
        setError(true);
        console.log(error);
        notifyError();
      })
      .finally(() => {
        setIsLoading(false);
        setLanguage(" ");
      });
  }

  function renderBodyMenu(type) {
    var temp = "";
    return finalData.map((item, index) => {
      if (item === "h") {
        temp = index;
        return (
          <div class="logo_set half_page second">
            <p>{type.toUpperCase()}</p>
          </div>
        );
      } else if (item.isActive === true) {
        return (
          <>
            <div class={`${index > temp ? "menu-text half_page" : ""}`}>
              <div
                class={`${item.type === 8 ? "priloha" : ""} menu`}
                key={index}
              >
                <div class="rest-text">
                  <p class="name-head">
                    <strong>
                      {language === "cz"
                        ? item.nameCS
                        : language === "de"
                        ? item.nameDE
                        : item.nameEN}
                    </strong>
                  </p>
                  <p class="price">
                    {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
                    <strong>{item.price} Kč</strong>
                  </p>
                </div>
                {item.type === (8 || 12 || 18) ? (
                  <></>
                ) : (
                  <div class="rest-text">
                    <p class="rest-text-desc">
                      {language === "cz"
                        ? item.descriptionCS
                        : language === "de"
                        ? item.descriptionDE
                        : item.descriptionEN}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });
  }

  function renderPizzas(type) {
    var temp = 100;
    var tempLeft,
      tempRight = [];
    finalData.map((item, index) => {
      if (item === "h") {
        temp = index;
      }
    });

    tempLeft = finalData.slice(0, temp).map((item, index) => {
      if (item === "h") {
        temp = index;
      } else if (item.isActive === true) {
        return (
          <>
            <div class="">
              <div class="menu" key={index}>
                <div class="rest-text">
                  <p class="name-head">
                    <strong>
                      {language === "cz"
                        ? item.nameCS
                        : language === "de"
                        ? item.nameDE
                        : item.nameEN}
                    </strong>
                  </p>
                  <p class="price">
                    {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
                    <strong>{item.price} Kč</strong>
                  </p>
                </div>
                {item.type === (8 || 12 || 15) ? (
                  <></>
                ) : (
                  <div class="rest-text">
                    <p class="rest-text-desc">
                      {language === "cz"
                        ? item.descriptionCS
                        : language === "de"
                        ? item.descriptionDE
                        : item.descriptionEN}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });
    tempRight = finalData.slice(temp, finalData.length).map((item, index) => {
      if (item === "h") {
        temp = index;
      } else if (item.isActive === true) {
        return (
          <>
            <div class="">
              <div class="menu" key={index}>
                <div class="rest-text">
                  <p class="name-head">
                    <strong>
                      {language === "cz"
                        ? item.nameCS
                        : language === "de"
                        ? item.nameDE
                        : item.nameEN}
                    </strong>
                  </p>
                  <p class="price">
                    {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
                    <strong>{item.price} Kč</strong>
                  </p>
                </div>
                {item.type === (8 || 12) ? (
                  <></>
                ) : (
                  <div class="rest-text">
                    <p class="rest-text-desc">
                      {language === "cz"
                        ? item.descriptionCS
                        : language === "de"
                        ? item.descriptionDE
                        : item.descriptionEN}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });

    return (
      <>
        <div class="column_1">
          <p class="col_head">{renderType(renderTemp()[0]).toUpperCase()}</p>
          {tempLeft}
        </div>
        {id !== "13" ? (
          <div class="column_2">
            <p class="col_head">{renderType(renderTemp()[1]).toUpperCase()}</p>
            {tempRight}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderBodyDrink(type) {
    var temp = 100;
    var tempLeft,
      tempRight = [];
    finalData.map((item, index) => {
      if (item === "h") {
        temp = index;
      }
    });

    tempLeft = finalData.slice(0, temp).map((item, index) => {
      if (item === "h") {
        temp = index;
      } else if (item.isActive === true) {
        return (
          <>
            <div class="">
              <div class="menu" key={index}>
                <div class="rest-text">
                  <p class="name-head">
                    <strong>
                      {language === "cz"
                        ? item.nameCS
                        : language === "de"
                        ? item.nameDE
                        : item.nameEN}
                    </strong>
                  </p>
                  <p class="price">
                    {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
                    <strong>{item.price} Kč</strong>
                  </p>
                </div>
                {item.type === (8 || 12 || 15) ? (
                  <></>
                ) : (
                  <div class="rest-text">
                    <p class="rest-text-desc">
                      {language === "cz"
                        ? item.descriptionCS
                        : language === "de"
                        ? item.descriptionDE
                        : item.descriptionEN}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });

    tempRight = finalData.slice(temp, finalData.length).map((item, index) => {
      if (item === "h") {
        temp = index;
      } else if (item.isActive === true) {
        return (
          <>
            <div class="">
              <div class="menu" key={index}>
                <div class="rest-text">
                  <p class="name-head">
                    <strong>
                      {language === "cz"
                        ? item.nameCS
                        : language === "de"
                        ? item.nameDE
                        : item.nameEN}
                    </strong>
                  </p>
                  <p class="price">
                    {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
                    <strong>{item.price} Kč</strong>
                  </p>
                </div>
                {item.type === (8 || 12) ? (
                  <></>
                ) : (
                  <div class="rest-text">
                    <p class="rest-text-desc">
                      {language === "cz"
                        ? item.descriptionCS
                        : language === "de"
                        ? item.descriptionDE
                        : item.descriptionEN}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });

    return (
      <>
        <div class="column_1">
          <p class="col_head">{renderType(renderTemp()[0]).toUpperCase()}</p>
          {tempLeft}
        </div>
        {id !== "13" ? (
          <div class="column_2">
            <p class="col_head">{renderType(renderTemp()[1]).toUpperCase()}</p>
            {tempRight}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderBody() {
    return finalData.map((item, index) => {
      if (item === "h") {
        return (
          <h3>
            {language === "cz"
              ? "Hotová jídla"
              : language === "en"
              ? "Ready-made dishes"
              : "Fertiggerichte"}
          </h3>
        );
      } else if (item.isActive === true) {
        return (
          <div class="menu" key={index}>
            <div class="rest-text">
              <p class="name-head">
                <strong>
                  {language === "cz"
                    ? item.nameCS
                    : language === "de"
                    ? item.nameDE
                    : item.nameEN}
                </strong>
              </p>
              <p class="price">
                {item.amount} {item.amount.length > 1 ? "/" : ""}{" "}
                <strong>{item.price} Kč</strong>
              </p>
            </div>
            <div class="rest-text">
              <p class="rest-text-desc">
                {language === "cz"
                  ? item.descriptionCS
                  : language === "de"
                  ? item.descriptionDE
                  : item.descriptionEN}
              </p>
            </div>
          </div>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });
  }

  function renderBodyDomov() {
    return finalData.map((item, index) => {
      if (item === "p") {
        return (
          <div class="type-daily">
            <h1>POLÉVKY</h1>
          </div>
        );
      }
      else if (item === "h") {
        return (
          <div class="type-daily">
            <h1>HLAVNÍ JÍDLA</h1>
          </div>
        );
      }
      else if (item === "s") {
        return (
          <div class="type-daily">
            <h1>SALÁTY</h1>
          </div>
        );
      }
      else if (item === "d") {
        return (
          <div class="type-daily">
            <h1>DEZERTY</h1>
          </div>
        );
      } else if (item.isActive === true) {
        return (
          <div class="menu" key={index}>
            <div class="rest-text">
              <p class="gram">
                <strong>{item.amount}</strong>
              </p>
              <p class="name-head">
                <strong>
                  {language === "cz"
                    ? item.nameCS
                    : language === "de"
                    ? item.nameDE
                    : item.nameEN}
                </strong>
              </p>
              <p class="price">
                <strong>{item.price} Kč</strong>
              </p>
            </div>
            <div class="rest-text">
              <p class="gram invisible">{item.amount}</p>
              <p class="name-head italic">
                <i>
                  (
                  {language === "cz"
                    ? item.descriptionCS
                    : language === "de"
                    ? item.descriptionDE
                    : item.descriptionEN}
                  )
                </i>
              </p>
              <p class="price invisible">{item.price} Kč</p>
            </div>
          </div>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });
  }

  function renderBodyMinibar() {
    return data.map((item, index) => {
      if (item.isActive === true) {
        return (
          <div class="menu" key={index}>
            <span>O</span>
            <h1>
              {language === "cz"
                ? item.nameCS
                : language === "de"
                ? item.nameDE
                : item.nameEN}
            </h1>
            <h6>
              <strong>{item.price} Kč</strong>
            </h6>
          </div>
        );
      } else if (item.isActive === false) {
        return <></>;
      }
    });
  }

  function sortProjects() {
    data.map((item, index) => {
      item.position = index;
      sendData(index);
    });
  }

  function sendData(index) {
    var datas = data[index];
    setDone(false);
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/sort`, datas, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {})
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setData(null);
        }
      })
      .then(() => {
        setDone(true);
      });
  }

  function setActive(id, active) {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Restaurant/active/${id}/${active}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        setDone(true);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function renderProjects() {
    const array = data.map((item, index) => {
      return (
        <tr className="text-white align-middle border" key={item.id}>
          <td>
            <input
              className="ms-3"
              type="checkbox"
              defaultChecked={item.isActive}
              onChange={() => setActive(item.id, !item.isActive)}
            />
          </td>
          <td>
            <span>
              <ion-icon name="menu-outline"></ion-icon>
            </span>
          </td>
          <td>
            <span>{item.nameCS}</span>
          </td>
          <td>
            <span>{item.descriptionCS}</span>
          </td>
          <td>
            <span>{item.amount}</span>
          </td>
          <td>
            <span>{item.price} Kč</span>
          </td>
          <td>
            <Link to={`/admin/food/${item.id}/edit`}>
              <button className="login-button">Upravit</button>
            </Link>
          </td>
          <td>
            <button
              className="login-button"
              refresh="true"
              onClick={() => {
                deleteFood(item.id, index);
              }}
            >
              Smazat
            </button>
          </td>
        </tr>
      );
    });
    return array;
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  } else if (accessToken) {
    return (
      <LayoutOther>
        <Container>
          <div className="admin-content">
            <h2>{renderType(id)}</h2>
            <Link to={`/${role.toLowerCase()}/profile`}>
              <button className="login-button me-3">Zpět</button>
            </Link>
            <Link to={`/admin/food/${id}/add`}>
              <button className="login-button me-3">Přidat</button>
            </Link>
            {renderDailyMenu()}
            {isLoading ? (
              <>
                <div class="snippet my-5 ms-5" data-title=".dot-typing">
                  <div class="stage">
                    <div class="dot-typing"></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Table responsive className="bg-light mt-3 p-1">
                  <tbody>
                    <ReactSortable
                      animation={200}
                      delayOnTouchStart={true}
                      delay={2}
                      list={data}
                      setList={setData}
                    >
                      {renderProjects()}
                    </ReactSortable>
                  </tbody>
                </Table>
              </>
            )}
            <button
              className="login-button me-3"
              onClick={() => sortProjects()}
            >
              ULOŽIT ŘAZENÍ
            </button>
          </div>
          <Toaster position="bottom-right" reverseOrder={false} />
        </Container>
      </LayoutOther>
    );
  }
};

export default FoodByType;
