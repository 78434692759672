import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import LayoutOther from "../../layout/LayoutOther";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../pages/AccessDenied";
import { Row, Container, Input, Label } from "reactstrap";
import { useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const WelcomeCard = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const history = useHistory();
  const [language, setLanguage] = useState(" ");

  const notify = () => toast.success("Uvítací karta byla vygenerována!");
  const notifyError = () =>
    toast.error(
      "Někde se stala chyba, zkuste to znovu. Nebo napište správci: tom.kulhavy@seznam.cz"
    );

  const formik = useFormik({
    initialValues: {
      osloveni: "",
    },
    onSubmit: (values) => {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/welcomeCard`, {
          Osloveni: values.osloveni,
        })
        .then(() => {
          notify();
          setLanguage(" ");
        })
        .catch((error) => {
          notifyError();
        });
    },
  });

  function generateCard(lang) {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/Restaurant/welcomeCard/${lang}`
      )
      .then(() => {
        notify();
        setLanguage(" ");
      })
      .catch((error) => {
        notifyError();
      });
  }

  if (!accessToken) {
    history.push("/");
    return (
      <>
        <AccessDenied />
      </>
    );
  } else if (accessToken) {
    return (
      <>
        <LayoutOther>
          <Container>
            <div className="admin-content">
              <h1>UVÍTACÍ KARTY</h1>
              <Link to={`/admin/welcome-card/list`}>
                <button className="login-button mt-5">
                  List hostů s kartou
                </button>
              </Link>
              {language === " " ? (
                <>
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zvolte jazyk před
                    generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      className="login-button me-3"
                      onClick={() => {
                        setLanguage("cz");
                      }}
                    >
                      CZ
                    </button>
                    <button
                      className="login-button me-3"
                      onClick={() => {
                        setLanguage("en");
                      }}
                    >
                      EN
                    </button>
                    <button
                      className="login-button me-3"
                      onClick={() => {
                        setLanguage("de");
                      }}
                    >
                      DE
                    </button>
                  </>
                </>
              ) : (
                <></>
              )}
              {language === "cz" ? (
                <>
                  <Row className="mt-1 mb-1 mb-md-3 text-start">
                    <form onSubmit={formik.handleSubmit}>
                      <Label className="mt-3" htmlFor="osloveni">
                        Oslovení
                      </Label>
                      <Input
                        id="osloveni"
                        name="osloveni"
                        type="text"
                        placeholder="Vážený Franto Vomáčko / Vážená Marie Vomáčková"
                        onChange={formik.handleChange}
                        value={formik.values.osloveni}
                      />
                      <button className="login-button mt-4" type="submit">
                        Vygenerovat uvítací kartu
                      </button>
                    </form>
                  </Row>
                </>
              ) : language === "de" ? (
                <>
                  <button
                    className="login-button mt-4"
                    type="submit"
                    onClick={() => generateCard("de")}
                  >
                    Vygenerovat uvítací kartu
                  </button>
                </>
              ) : language === "en" ? (
                <>
                  <button
                    className="login-button mt-4"
                    onClick={() => generateCard("en")}
                  >
                    Vygenerovat uvítací kartu
                  </button>
                </>
              ) : (<></>)}
            </div>
            <Toaster position="bottom-right" reverseOrder={false} />
          </Container>
        </LayoutOther>
      </>
    );
  }
};

export default WelcomeCard;
