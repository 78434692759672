import React, { useContext } from "react";
import "../styles/style.css";
import "../styles/typography.css";
import Logo from "../../assets/images/logo-alt_new.svg";
import { Container, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../../providers/ApplicationProvider";

const Footer = () => {
  const [{ lang }] = useContext(ApplicationContext);

  return (
    <>
      <footer>
        <Container>
          <img src={Logo} alt="logo" className="footer-logo" />
          <div className="footer-content">
            <p className="text-center">
              <a
                className="hover-underline-animation"
                href="https://www.google.com/maps/place/Za+Domovem+333,+Horn%C3%AD+Hanychov,+460+08+Liberec,+%C4%8Cesko/@50.7385061,15.0090088,17z/data=!4m5!3m4!1s0x470936415fe131cb:0x896a7bcc44ad4497!8m2!3d50.7385027!4d15.0111975?hl=cs-CZ"
                target="_blank"
                rel="noreferrer"
              >
                Za Domovem 333, Liberec - Horní Hanychov, 460 08, Česká
                Republika
              </a>
            </p>
            <div className="footer-items">
              <p>
                <a
                  href="https://www.hoteledelweiss.cz"
                  className="hover-underline-animation"
                >
                  www.hoteledelweiss.cz
                </a>
              </p>
              <p className="navbar-navll">|</p>
              <p>
                <a
                  href="mailto:info@hoteledelweiss.cz"
                  className="hover-underline-animation"
                >
                  info@hoteledelweiss.cz
                </a>
              </p>
            </div>
            <p>
              <a
                href="tel:+420 777 336 810"
                className="hover-underline-animation"
              >
                +420 777 336 810
              </a>
            </p>
            <div className="links">
            <a
              href="https://www.instagram.com/wellness_hotel_edelweiss/"
              target="_blank"
              alt="instagram-wellness hotel edelweiss" rel="noreferrer"
            >
              <ion-icon
                name="logo-instagram"
                style={{ width: "30px", height: "30px" }}
              ></ion-icon>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100086427892621"
              target="_blank" rel="noreferrer"
            >
              <ion-icon
                name="logo-facebook"
                style={{ width: "30px", height: "30px" }}
              ></ion-icon>
            </a>
            </div>
            <div className="row">
              <Col md={4}>
                <Link to="/contact">
                  <p className="hover-underline-animation footer-text">
                    {new Date().getFullYear()} © Wellness Hotel Edeleweiss
                  </p>
                </Link>
              </Col>
              <Col md={4}>
                <a
                  href="https://www.tomas-kulhavy.cz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="hover-underline-animation cby footer-text">
                    Made with 💛 by <span className="anchor-ref">@tomaskulhavy</span>
                  </p>
                </a>
              </Col>
              <Col md={4}>
                <div className="footer-items">
                  <Link to="/terms-conditions">
                    <p className="hover-underline-animation footer-text">
                      {lang === "cs"
                        ? "Všeobecné obchodní podmínky"
                        : lang === "en"
                        ? "Terms and Conditions"
                        : "Geschäftsbedingungen"}
                    </p>
                  </Link>
                </div>
              </Col>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
