import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import LayoutOther from "../../layout/LayoutOther";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../pages/AccessDenied";
import { Container, Table } from "reactstrap";
import ReactDOMServer from "react-dom/server";
import toast, { Toaster } from "react-hot-toast";
import { ReactSortable } from "react-sortablejs";
import "../../pages/style.css";

const FoodByType = () => {
  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);
  const [language, setLanguage] = useState(" ");
  const id = "";

  useEffect(() => {
    renderTemp();
  }, []);

  function renderTemp() {
    var temp1,
      temp2 = "";
    if (id === "0" || id === "12") {
      temp1 = 0;
      temp2 = 12;
    } else if (id === "1" || id === "2") {
      temp1 = 1;
      temp2 = 2;
    } else if (id === "3" || id === "4") {
      temp1 = 3;
      temp2 = 4;
    } else if (id === "5" || id === "6") {
      temp1 = 5;
      temp2 = 6;
    } else if (id === "7" || id === "8") {
      temp1 = 7;
      temp2 = 8;
    } else if (id === "9" || id === "18") {
      temp1 = 18;
      temp2 = 9;
    } else if (id === "14" || id === "17") {
      temp1 = 17;
      temp2 = 14;
    } else if (id === "15" || id === "16") {
      temp1 = 15;
      temp2 = 16;
    } else if (id === "10" || id === "19") {
      temp1 = 10;
      temp2 = 19;
    } else if (id === "20" || id === "21") {
      temp1 = 20;
      temp2 = 21;
    } else if (id === "13") {
      temp1 = 13;
      temp2 = 0;
    }

    return [temp1, temp2];
  }

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/Restaurant/getByType/admin/22`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setIsLoading(false));
  }, [id]);

  const removeItem = (index) => {
    data.splice(index, 1);
    setData([...data]);
  };

  function deleteFood(item, index) {
    setIsLoading(true);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/remove/${item}`)
      .then(() => {
        removeItem(index);
        setDone(true);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return data;
  }

  function renderType(types) {
    if (language === "de") {
      return "Aktionen/Hochzeiten/Feiern";
    } else if (language === "en") {
      return "actions/weddings/celebration";
    } else {
      return "Akce/svatby/oslavy";
    }
  }

  function renderDailyMenu() {
    return (
      <>
        {language === "cz" || language === "de" || language === "en" ? (
          <>
            <button
              className="login-button me-3"
              onClick={() => sendMenu("printActions")}
            >
              Vygenerovat list jídel pro akce
            </button>
          </>
        ) : (
          <>
            Zvolte jazyk před generováním:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="login-button me-3"
              onClick={() => {
                setLanguage("cz");
              }}
            >
              CZ
            </button>
            <button
              className="login-button me-3"
              onClick={() => {
                setLanguage("en");
              }}
            >
              EN
            </button>
            <button
              className="login-button me-3"
              onClick={() => {
                setLanguage("de");
              }}
            >
              DE
            </button>
          </>
        )}

        <Link to="/recepce/actions/list">
          <button className="login-button">List jídel (akce)</button>
        </Link>
      </>
    );
  }

  const notify = () => toast.success("Jídla pro akce byla vygenerována!");
  const notifyError = () =>
    toast.error(
      "Někde se stala chyba, zkuste to znovu! Nebo napište správci: tom.kulhavy@seznam.cz"
    );

  const [temp, setTemp] = useState("h");

  function sendMenu(printId) {
    let htmlString;
    let temp = ReactDOMServer.renderToStaticMarkup(
      <>
        <div className="row">{renderProjectsGenerate()}</div>
      </>
    );
    temp = ReactDOMServer.renderToStaticMarkup(
      <>
        <div className="row">{renderProjectsGenerate()}</div>
      </>
    );
    htmlString = temp;

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/Restaurant/${printId}/${language}`,
        {
          Menu: htmlString,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        setDone(true);
        notify();
      })
      .catch((error) => {
        setError(true);
        console.log(error);
        notifyError();
      })
      .finally(() => {
        setIsLoading(false);
        setLanguage(" ");
      });
  }

  function sortProjects() {
    data.map((item, index) => {
      item.position = index;
      sendData(index);
    });
  }

  function sendData(index) {
    var datas = data[index];
    setDone(false);
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/sort`, datas, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {})
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setData(null);
        }
      })
      .then(() => {
        setDone(true);
      });
  }

  function setActive(id, active) {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Restaurant/active/${id}/${active}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        setDone(true);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function renderProjectsGenerate() {
    var array = data.map((item, index) => {
      if (item.isActive === true) {
        return (
          <>
            <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
              <div class="card card-content-normal mt-3">
                <img
                  src={`/assets/images/${item.id}/${item.id}.jpeg`}
                  alt="img"
                />
                <div class="card-body text-center">
                  <div class="card-title">
                    <h4>
                      {language === "cz"
                        ? item.nameCS
                        : language === "en"
                        ? item.nameEN
                        : item.nameDE}
                    </h4>
                  </div>
                </div>
                <div class="card-body d-flex flex-column">
                  <div class="card-text">
                    <p>
                      {language === "cz"
                        ? item.descriptionCS
                        : language === "en"
                        ? item.descriptionEN
                        : item.descriptionDE}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    });

    return (
      <>
        <div class="cards container-lg">
          <div class="card-content">
            <div class="row mt-1 mb-1 mb-md-3 text-center">{array}</div>
          </div>
        </div>
      </>
    );
  }

  function renderProjects() {
    const array = data.map((item, index) => {
      return (
        <tr className="text-white align-middle border" key={item.id}>
          <td>
            <input
              className="ms-3"
              type="checkbox"
              defaultChecked={item.isActive}
              onChange={() => setActive(item.id, !item.isActive)}
            />
          </td>
          <td>
            <span>
              <ion-icon name="menu-outline"></ion-icon>
            </span>
          </td>
          <td>
            <span>{item.nameCS}</span>
          </td>
          <td>
            <span>{item.descriptionCS}</span>
          </td>
          <td>
            <Link to={`/recepce/actions/image/edit/${item.id}`}>
              <button className="login-button">Fotka</button>
            </Link>
          </td>
          <td>
            <Link to={`/recepce/actions/edit/${item.id}`}>
              <button className="login-button">Upravit</button>
            </Link>
          </td>
          <td>
            <button
              className="login-button"
              refresh="true"
              onClick={() => {
                deleteFood(item.id, index);
              }}
            >
              Smazat
            </button>
          </td>
        </tr>
      );
    });
    return array;
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  } else if (accessToken) {
    return (
      <LayoutOther>
        <Container>
          <div className="admin-content">
            <h2>{renderType(id)}</h2>
            <Link to={`/recepce/actions/add`}>
              <button className="login-button me-3">Přidat</button>
            </Link>
            {renderDailyMenu()}
            {isLoading ? (
              <>
                <div class="snippet my-5 ms-5" data-title=".dot-typing">
                  <div class="stage">
                    <div class="dot-typing"></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Table responsive className="bg-light mt-3 p-1">
                  <tbody>
                    <ReactSortable
                      animation={200}
                      delayOnTouchStart={true}
                      delay={2}
                      list={data}
                      setList={setData}
                    >
                      {renderProjects()}
                    </ReactSortable>
                  </tbody>
                </Table>
              </>
            )}
            <button
              className="login-button me-3"
              onClick={() => sortProjects()}
            >
              ULOŽIT ŘAZENÍ
            </button>
          </div>
          <Toaster position="bottom-right" reverseOrder={false} />
        </Container>
      </LayoutOther>
    );
  }
};

export default FoodByType;
