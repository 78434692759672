import React, { useEffect, useContext } from "react";
import { ApplicationContext } from "../providers/ApplicationProvider";
import "./styles/style.css";
import "./styles/typography.css";
import Layout from "./layout/LayoutContact";
import { Container } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const Podminky = () => {
  const [{ lang }] = useContext(ApplicationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Wellness Hotel Edelweiss | Všeobecné obchodní podmínky</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Všeobecné obchodní podmínky"
        />
        <link
          rel="canonical"
          href="https://www.hoteledelweiss.cz/terms-conditions"
        ></link>
      </Helmet>
      <Fade>
        <Layout>
          <Container>
            {lang === "cs" ? (
              <>
                <div className="podminky">
                  <p>
                    <br></br>
                  </p>
                  <h2 className="text-center">
                    Všeobecné obchodní podmínky – ubytovací řád
                  </h2>
                  <h2>Wellness Hotelu Edelweiss</h2>
                  <h4 className="mt-5">1. Všeobecná ustanovení</h4>
                  <ol>
                    <li>
                      Tyto všeobecné obchodní podmínky – ubytovací řád (dále
                      také jako <strong>„VOP“</strong>) upravují smluvní vztah
                      mezi provozovatelem ubytovacího zařízení s názvem
                      Edelweiss, na adrese Za Domovem 333, 460 08 Liberec,
                      kterým je společnost Profibüro s.r.o., IČ: 037 32 746,
                      se sídlem Jiráskova 121/11, Liberec XIII - Nové Pavlovice,
                      460 01 Liberec (dále také jako{" "}
                      <strong>„Provozovatel“</strong>) a ubytovaným/uživatelem
                      služeb (dále také jako <strong>„Klient“</strong>).
                    </li>
                    <li>
                      Wellness hotel je ubytovací zařízení, ve kterém je
                      ubytován Klient na základě objednávky – uzavřené smlouvy.{" "}
                    </li>
                    <li>
                      Službami poskytovanými Provozovatelem se rozumí
                      poskytování ubytování Klientovi v hotelu, a s tím spojené
                      služby poskytované Provozovatelem. Přesnou specifikaci
                      pobytu a poskytovaných služeb obsahuje dohoda
                      Provozovatele a Klienta.
                    </li>
                    <li>
                      Využíváním poskytovaných služeb Klient souhlasí s úplným
                      zněním těchto VOP. Pokud Klient s některou částí VOP
                      nesouhlasí, není oprávněn služby využívat, nedohodne-li se
                      Klient s Provozovatelem jinak. Poskytovatel si vyhrazuje
                      právo VOP měnit. Uzavřený smluvní vztah se řídí těmi VOP,
                      které byly účinné v době uzavření smlouvy. Každý Klient je
                      povinen se s tímto ubytovacím řádem seznámit, na jeho
                      neznalost nebude brán zřetel. Ubytovací řád je k dispozici
                      na recepci hotelu.
                    </li>
                    <li>
                      Provozovatel neodpovídá za škodu na věcech vnesených
                      Klientem do wellness hotelu a za škodu na odložených
                      věcech. Za peníze a cennosti odpovídá Provozovatel jen
                      tehdy, převzal-li je do úschovy oproti písemnému
                      potvrzení.
                    </li>
                  </ol>
                  <h4 className="mt-5">2. Objednání pobytu</h4>
                  <ol className="v">
                    <li>
                      Klient si může objednat pobyt telefonicky, elektronicky
                      e-mailem či osobně. Smlouva o ubytování je uzavřena
                      okamžikem potvrzení objednávky Provozovatelem. Potvrzení
                      objednávky je Provozovatelem zpravidla činěno cestou
                      elektronické komunikace a bez zbytečných prodlení.{" "}
                    </li>
                  </ol>
                  <h4 className="mt-5">3. Cena pobytu</h4>
                  <ol>
                    <li>
                      Orientační ceny ubytování a případných dalších služeb jsou
                      uvedeny v prezentačních materiálech Provozovatele
                      (internetové stránky, prospekty, apod.), přičemž uvedení
                      těchto cen není nabídkou ve smyslu § 1731 nebo § 1732 zák.
                      č. 89/2012 Sb., občanského zákoníku.
                    </li>
                    <li>
                      Závazná je cena, která je uvedena v potvrzení objednávky
                      či emailové nabídce. Provozovatel vystaví ohledně plateb
                      prováděných na základě smlouvy Klientovi daňový doklad –
                      fakturu, který slouží jako doklad pro zaplacení služby.
                      Výsledná cena pobytu vychází z Klientových požadavků na
                      pobyt a je sestavena na základě aktuálního ceníku služeb
                      Provozovatele. Provozovatel si vyhrazuje právo upravit
                      ceny v případě, že v průběhu období od stanovení cen po
                      zrealizování pobytu dojde ke změně legislativních a
                      daňových předpisů a k dalším změnám z důvodů tzv. „vyšší
                      moci“.
                    </li>
                  </ol>
                  <h4 className="mt-5">4. Platební podmínky</h4>
                  <ol>
                    <li>
                      Klient obdrží (zálohovou) fakturu za ubytování, kterou je
                      třeba uhradit na číslo účtu do termínu splatnosti nebo
                      provedením hotovostní platby nebo doplatku na místě.
                    </li>
                    <li>
                      V případě neuhrazení faktury do dne splatnosti se
                      objednávka automaticky ruší. Variabilní symbol je uveden
                      na faktuře a slouží k identifikaci Klienta. Pro veškeré
                      formy bezhotovostních plateb je rozhodné připsání platby
                      na účet Provozovatele a/nebo úhrada hotovosti při nástupu
                      k ubytování nebo užívání poskytovaných služeb
                      Provozovatelem.
                    </li>
                    <li>
                      Platba je zpravidla jednorázová. Klient je povinen při
                      bezhotovostní platbě uhradit cenu společně s uvedením
                      správného variabilního symbolu platby, jinak Provozovatel
                      nebude schopen platbu identifikovat a poskytnout včas
                      požadované plnění.
                    </li>
                    <li>
                      Faktura je splatná ve lhůtě na ní uvedené, nejpozději však
                      do nástupu k ubytování, není-li sjednáno, že ubytování a
                      další poskytnuté služby budou uhrazeny až po jejich
                      poskytnutí. Závazek Klienta uhradit cenu za službu je
                      splněn okamžikem připsání příslušné částky na účet
                      Provozovatele nebo předáním hotovosti v místě plnění
                      služeb.
                    </li>
                    <li>
                      V případě prodlení s úhradou jakékoliv částky (ceny za
                      ubytování) má právo Provozovatel bez zbytečného odkladu
                      odstoupit od smlouvy.
                    </li>
                    <li>
                      Sortiment zboží v minibaru není zahrnut do ceny ubytování.
                      Případnou konzumaci zboží z minibaru je Klient povinen
                      uhradit. Provozovatel je oprávněn požadovat úhradu zálohy
                      na cenu zboží v minibaru.
                    </li>
                  </ol>
                  <h4 className="mt-5">5. Povinnosti klienta</h4>
                  <ol>
                    <li>
                      Klient je povinen poskytnout Provozovateli veškeré
                      náležitosti potřebné k řádnému zajištění a poskytnutí
                      služeb, zejména pravdivě a úplně vyplnit objednávku pobytu
                      a poskytnout mu požadované doklady.
                    </li>
                    <li>
                      Klient je povinen ve stanoveném termínu splatnosti uhradit
                      platbu za pobyt a další poskytnuté služby.
                    </li>
                    <li>
                      Klient je povinen řídit se ubytovacím řádem Provozovatele
                      v rovněž v bodech níže uvedených:
                    </li>
                    <ol className="n">
                      <li>
                        Klient užívá pokoj po dobu, která byla sjednána.
                        Nebyla-li doba pobytu sjednána jinak, nástup na
                        ubytování je od 14:00 hodin do 18:00 hodin (po této
                        hodině může být pobyt stornován a pokoj může být
                        nabídnut jinému Klientovi – pokud není předem dohodnutý
                        pozdější nástup), poslední den pobytu Klient uvolní
                        pokoj nejpozději do 10:00 hodin. Pokud provoz
                        ubytovacího zařízení dovoluje, je možno domluvit
                        i pozdější hodinu pro uvolnění pokoje.
                      </li>
                      <li>
                        V době od 22:00 hodin do 7:00 hodin je Klient povinen
                        dodržovat noční klid.
                      </li>
                      <li>Ve wellness hotelu není povoleno kouřit.</li>
                      <li>
                        Klient je povinen počínat si tak, aby svým chováním
                        neobtěžoval ostatní hosty wellness hotelu.
                      </li>
                      <li>
                        V pokoji nesmí Klient bez souhlasu majitele přemisťovat
                        zařízení a vybavení, provádět opravy a jakékoliv zásahy
                        do elektrické sítě nebo jiné instalace.
                      </li>
                      <li>
                        Není dovoleno užívat vlastních elektrospotřebičů (např.
                        varné konvice, vařiče, toustovače apod.), ale pouze
                        spotřebiče z výbavy hotelu.
                      </li>
                      <li>
                        Při odchodu z pokoje je Klient povinen uzavřít vodovodní
                        kohoutky, zhasnout světla, vypnout televizi, uzavřít
                        okna a při ukončení pobytu odevzdat klíč.(popř. čip,
                        el.kartu)
                      </li>
                      <li>
                        Z bezpečnostních důvodů není dovoleno ponechávat děti
                        mladší 12ti let bez dozoru dospělé osoby v pokoji a
                        ostatních prostorách hotelu. Rodiče odpovídají za
                        bezpečnost svých dětí ve všech prostorech hotelu.
                      </li>
                      <li>
                        Klient je povinen dbát zásad opatrnosti. Zvýšené
                        opatrnosti je Klient povinen dbát zejména v zimních
                        měsících, kdy hrozí nebezpečí náledí a pád sněhu.
                        Provozovatel neodpovídá Klientovi za škodu v případě
                        porušení těchto povinností.
                      </li>
                      <li>
                        Za škody způsobené na majetku wellness hotelu odpovídá
                        Klient v plném rozsahu.
                      </li>
                      <li>
                        Ubytování spolu se zvířetem je povoleno pouze po
                        předchozím souhlasu provozovatele. Majitel zvířete je
                        povinen prokázat jeho nezávadný zdravotní stav
                        předložením platného očkovacího průkazu je-li zákonná
                        povinnost tohle zvíře očkovat. Není dovoleno,
                        aby zvířata byla ponechána v pokoji bez dozoru. Zvířata
                        nesmí využívat postel a ostatní zařízení pro hosty.
                        Nelze užívat sprchy nebo umyvadla ke koupání nebo mytí
                        zvířat.
                      </li>
                    </ol>
                  </ol>
                  <h4 className="mt-5">
                    6. Odstoupení od smlouvy ze strany Klienta
                  </h4>
                  <ol>
                    <li>
                      Klient je oprávněn odstoupit od smlouvy s Provozovatelem
                      kdykoliv před nástupem k ubytování nebo užívání
                      poskytovaných služeb Provozovatelem. Pro stanovení
                      stornovacího poplatku je rozhodující okamžik přijetí
                      odstoupení od smlouvy ze strany Provozovatele.
                    </li>
                    <li>
                      Při odstoupení od smlouvy ze strany Klienta postupem podle
                      tohoto článku je Klient povinen uhradit stornovací
                      poplatek.{" "}
                    </li>
                    <li>Stornovací poplatek činí:</li>
                    <ol className="n">
                      <li>30 a více dní před počátkem pobytu 0%;</li>
                      <li>29 – 20 dnů před počátkem pobytu 10%;</li>
                      <li>19 – 10 dnů před počátkem pobytu 25%;</li>
                      <li>9 a méně dnů před počátkem pobytu 40%.</li>
                    </ol>
                    <li>
                      Pokud Klient zruší účast na pobytu, resp. zruší objednávku
                      pobytu, je Provozovatel povinen vrátit Klientovi
                      zaplacenou sumu sníženou o příslušný stornovací poplatek
                      do 14 dnů ode dne, kdy odstoupení od smlouvy bylo přijato
                      Provozovatelem. Finanční zálohy se vracejí formou
                      bankovního převodu na číslo účtu uvedené Klientem.
                      Transakční náklady spojené s refundací financí jdou na
                      vrub Klienta.
                    </li>
                  </ol>
                  <h4 className="mt-5">7. Vyloučení Klienta z pobytu</h4>
                  <ol className="v">
                    <li>
                      Klient, který v době pobytu poruší právní předpisy ČR,
                      opakovaně porušuje povinnosti stanovené ubytovacím řádem,
                      odmítá se řídit oprávněnými požadavky a pokyny
                      Provozovatele, slovně napadá zástupce Provozovatele,
                      poškozuje majetek v ubytovacím zařízení a svým chováním
                      omezuje práva Provozovatele či ostatních hostů, může být
                      vyloučen z pokračování pobytu, přičemž mu zaniká nárok na
                      náhradu za nevyužité služby.
                    </li>
                  </ol>
                  <h4 className="mt-5">
                    8. Odstoupení od smlouvy ze strany Provozovatele
                  </h4>
                  <ol className="v">
                    <li>
                      Provozovatel oprávněn od smlouvy okamžitě odstoupit, pokud
                      zjistí, že Klient porušil smlouvu podstatným způsobem. Pro
                      účely této smlouvy se za podstatné porušení této smlouvy
                      považuje zejména:
                    </li>
                  </ol>
                  <ol className="n">
                    <li>
                      prodlení Klienta s úhradou ceny za poskytované služby;
                    </li>
                    <li>nedodržení rezervovaného termínu k ubytování; </li>
                    <li>porušení povinností stanovené VOP.</li>
                  </ol>
                  <h4 className="mt-5">9. Změny dohodnutých služeb</h4>
                  <ol>
                    <li>
                      Jestliže nastanou mimořádné či neočekávané okolnosti či
                      okolnosti v důsledku tzv. „vyšší moci“, může Provozovatel
                      pobyt zrušit, nebo po dohodě s Klientem změnit (termín,
                      cenu). Pokud pobyt Klienta již začal, má Provozovatel
                      právo na úhradu doposud poskytnutých služeb.
                    </li>
                    <li>
                      Při zrušení pobytu ze strany Provozovatele je Provozovatel
                      povinen tuto skutečnost oznámit Klientovi bez zbytečného
                      odkladu a do 14 dnů od oznámení mu vrátit zaplacenou
                      částku za nevyužité služby.
                    </li>
                  </ol>
                  <h4 className="mt-5">10. Reklamační řízení</h4>
                  <ol>
                    <li>
                      V případě reklamace je Klient povinen uplatnit své
                      požadavky na závady bez zbytečného odkladu přímo u
                      zástupce Provozovatele v době pobytu a v místě konání
                      pobytu, nejlépe při nástupu ubytování a využívání služeb
                      tak, aby mohly být odstraněny ještě na místě. Pokud toto
                      není možné, sepíše s Provozovatelem písemnou reklamaci.
                      Tento protokol je podkladem pro řešení reklamace. Protokol
                      se obvykle sepisuje ve dvou vyhotoveních (1 pro
                      provozovatele a 1 pro klienta) a musí být podepsán jak
                      Klientem, tak Provozovatelem.
                    </li>
                    <li>
                      Pokud nebude reklamace podána okamžitě nebo ihned, jak je
                      to ze strany Provozovatele a Klienta možné, zaniká nárok
                      zákazníka na náhradu později vzniklých škod, kterým by
                      mohl včasným podáním zabránit. U poskytovaných služeb se
                      za předmět reklamace nepovažují škody a majetkové nebo
                      nemajetkové újmy, které vznikly Klientovým zaviněním.
                    </li>
                  </ol>
                  <h4 className="mt-5">11. Ochrana osobních údajů</h4>
                  <ol>
                    <li>
                      Provozovatel se zavazuje, že osobní údaje, které mu budou
                      na základě smlouvy poskytnuty, chránit a zpracovávat
                      v souladu s právními předpisy, zejména Nařízením
                      Evropského parlamentu a Rady (EU) 2016/679 ze dne 27.
                      dubna 2016 o ochraně fyzických osob v souvislosti
                      se zpracováním osobních údajů a o volném pohybu těchto
                      údajů a o zrušení směrnice 95/46/ES.
                    </li>
                    <li>
                      Z bezpečnostních důvodů a na ochranu hostů je v chodbách a
                      dalších veřejných prostranstvích hotelu instalován
                      kamerový systém. Nakládání s těmito záznamy se provádí dle
                      příslušných právních předpisů na ochranu osobnosti.
                    </li>
                  </ol>
                  <h4 className="mt-5">12. Závěrečná ustanovení</h4>
                  <ol>
                    <li>
                      Otázky neupravené těmito VOP se řídí právními předpisy
                      České republiky, zejména příslušnými ustanoveními zákona
                      č. 89/2012 Sb., občanský zákoník. V případě, že je nebo
                      se stane kterékoli ustanovení těchto VOP do rozporu
                      s kogentními ustanovení právních předpisů, bude aplikována
                      příslušná zákonná úprava a nebude to mít vliv na platnost
                      VOP jako celku.
                    </li>
                    <li>
                      Veškeré právní spory vzniklé v souvislosti se smluvním
                      vztahem mezi Klientem a Provozovatelem budou řešeny v
                      občanském soudním řízení obecnými soudy České republiky,
                      spotřebitel má právo i na mimosoudní řešení. Subjektem
                      mimosoudního řešení podle zákona č. 634/1992 Sb., o
                      ochraně spotřebitele, je Česká obchodní inspekce. Veškeré
                      podrobnosti k mimosoudnímu řešení jsou uvedeny na
                      stránkách České obchodní inspekce www.coi.cz
                    </li>
                    <li>
                      Provozovatel si vyhrazuje právo u vybraných produktů
                      upravit další práva a povinnosti smluvních stran nad rámec
                      těchto VOP. Toto aktuální znění VOP je platné a účinné od
                      1. 6. 2022.
                    </li>
                  </ol>
                </div>
              </>
            ) : lang === "de" ? (
              <>
                <div className="podminky">
                  <p>
                    <br></br>
                  </p>
                  <h2 className="text-center">
                    ALLGEMEINE GESCHÄFTSBEDINGUNGEN – UNTERKUNFTSORDNUNG
                  </h2>
                  <h2>Wellness Hotel Edelweiss</h2>
                  <h4 className="mt-5">1. ALLGEMEINE BESTIMMUNGEN</h4>
                  <ol>
                    <li>
                      Diese Allgemeinen Geschäftsbedingungen –
                      Unterkunftsordnung (nachfolgend auch „AGB“ genannt) regeln
                      das Vertragsverhältnis zwischen dem Betreiber des
                      Unterkunftsobjekts Edelweiss, Za Domovem 333, 460 08
                      Liberec, d. h. der Gesellschaft Profibüro s.r.o., ID-Nr.:
                      037 32 746, mit Sitz in Jiráskova 121/11, Liberec XIII –
                      Nové Pavlovice, 460 01 Liberec (nachfolgend auch
                      „Betreiber“ genannt) und dem
                      Unterkunfts-/Dienstleistungsnehmer (nachfolgend auch
                      „Kunde“ genannt).
                    </li>
                    <li>
                      Das Wellnesshotel ist ein Unterkunftsobjekt, in dem der
                      Kunde aufgrund einer Bestellung – eines abgeschlossenen
                      Vertrags – untergebracht wird.
                    </li>
                    <li>
                      Unter Dienstleistungen des Betreibers versteht man die
                      Unterbringung des Kunden im Hotel und die damit
                      verbundenen Dienstleistungen des Betreibers. Die genaue
                      Spezifikation des Aufenthalts und der erbrachten
                      Dienstleistungen ist im Vertrag zwischen dem Betreiber und
                      dem Kunden enthalten.
                    </li>
                    <li>
                      Durch die Nutzung der angebotenen Dienstleistungen erklärt
                      sich der Kunde mit dem vollständigen Wortlaut der
                      vorliegenden AGB einverstanden. Wenn der Kunde mit einem
                      Teil der AGB nicht einverstanden ist, ist er nicht
                      berechtigt, die Dienstleistungen zu nutzen, es sei denn,
                      der Kunde vereinbart etwas anderes mit dem Betreiber. Der
                      Betreiber behält sich das Recht vor, die AGB zu ändern.
                      Für das abgeschlossene Vertragsverhältnis gelten die AGB,
                      die zum Zeitpunkt des Vertragsabschlusses in Kraft waren.
                      Jeder Kunde ist verpflichtet, sich mit diesen AGB vertraut
                      zu machen, seine Unkenntnis wird nicht berücksichtigt. Die
                      Unterkunftsordnung ist an der Rezeption des Hotels
                      erhältlich.
                    </li>
                    <li>
                      Der Betreiber haftet nicht für Schäden an vom Kunden in
                      das Wellnesshotel mitgebrachten Gegenständen und für
                      Schäden an zurückgelassenen Gegenständen. Für Geld und
                      Wertsachen haftet der Betreiber nur, wenn er sie gegen
                      schriftliche Bestätigung in Verwahrung genommen hat.
                    </li>
                  </ol>
                  <h4 className="mt-5">2. BUCHUNG DES AUFENTHALTS</h4>
                  <ol className="v">
                    <li>
                      Der Kunde kann einen Aufenthalt telefonisch, elektronisch
                      per E-Mail oder persönlich buchen. Der
                      Beherbergungsvertrag wird zum Zeitpunkt der Bestätigung
                      der Bestellung durch den Betreiber abgeschlossen. Die
                      Bestätigung der Bestellung erfolgt in der Regel durch den
                      Betreiber auf elektronischem Wege und ohne unnötige
                      Verzögerungen.
                    </li>
                  </ol>
                  <h4 className="mt-5">3. PREIS DES AUFENTHALTS</h4>
                  <ol>
                    <li>
                      Die Rahmenpreise für die Unterkunft und alle anderen
                      Dienstleistungen werden in den Präsentationsmaterialien
                      des Betreibers (Webseiten, Broschüren usw.) angegeben,
                      wobei die Auflistung dieser Preise kein Angebot im Sinne
                      von § 1731 oder § 1732 des Gesetzes Nr. 89/2012 Slg. des
                      tschechischen Bürgerlichen Gesetzbuches darstellt.
                    </li>
                    <li>
                      Der verbindliche Preis ist der in der Auftragsbestätigung
                      oder im E-Mail-Angebot angegebene Preis. Der Betreiber
                      stellt dem Kunden für die im Rahmen des Vertrags
                      geleisteten Zahlungen einen Steuerbeleg – eine Rechnung
                      aus, die als Beleg für die Bezahlung der Dienstleistungen
                      dient. Der endgültige Preis für den Aufenthalt richtet
                      sich nach den Anforderungen des Kunden für den Aufenthalt
                      und basiert auf der aktuellen Preisliste für die
                      Dienstleistungen des Betreibers. Der Betreiber behält sich
                      das Recht vor, die Preise anzupassen, falls es in der Zeit
                      zwischen der Preisfestsetzung und der Realisierung des
                      Aufenthalts zu einer Änderung der gesetzlichen und
                      steuerlichen Vorschriften sowie zu anderen Änderungen
                      aufgrund von so genannter „höherer Gewalt“ kommt.
                    </li>
                  </ol>
                  <h4 className="mt-5">4. ZAHLUNGSBEDINGUNGEN</h4>
                  <ol>
                    <li>
                      Der Kunde erhält eine (Vorschuss-)Rechnung für die
                      Unterkunft, die bis zum Fälligkeitsdatum auf die
                      Kontonummer oder durch Barzahlung oder Zahlung vor Ort zu
                      begleichen ist.
                    </li>
                    <li>
                      Wird die Rechnung nicht bis zum Fälligkeitsdatum bezahlt,
                      wird der Auftrag automatisch storniert. Die
                      Zahlungsreferenz ist auf der Rechnung angegeben und dient
                      der Identifizierung des Kunden. Für alle Formen der
                      bargeldlosen Zahlung ist die Gutschrift auf dem Konto des
                      Betreibers und/oder die Barzahlung bei der Ankunft in der
                      Unterkunft oder bei der Inanspruchnahme der vom Betreiber
                      angebotenen Dienstleistungen maßgebend.
                    </li>
                    <li>
                      Die Zahlung erfolgt in der Regel einmalig. Der Kunde ist
                      verpflichtet, bei einer bargeldlosen Zahlung den Preis
                      zusammen mit der korrekten Zahlungsreferenz zu zahlen, da
                      der Betreiber sonst nicht in der Lage ist, die Zahlung zu
                      identifizieren und die erforderliche Leistung rechtzeitig
                      zu erbringen.
                    </li>
                    <li>
                      Die Rechnung ist innerhalb der auf der Rechnung
                      angegebenen Frist, spätestens jedoch bei der Ankunft in
                      der Unterkunft zu bezahlen, es sei denn, es wurde
                      vereinbart, dass die Unterkunft und die anderen erbrachten
                      Dienstleistungen erst nach ihrer Erbringung bezahlt
                      werden. Die Verpflichtung des Kunden zur Zahlung des
                      Preises für die Dienstleistung wird zum Zeitpunkt der
                      Gutschrift des entsprechenden Betrags auf dem Konto des
                      Betreibers oder durch Übergabe von Bargeld am Ort der
                      Dienstleistungserbringung erfüllt.
                    </li>
                    <li>
                      Im Falle eines Zahlungsverzuges mit einem Betrag
                      (Unterkunftspreis) ist der Betreiber berechtigt,
                      unverzüglich vom Vertrag zurückzutreten.
                    </li>
                    <li>
                      Das Angebot der Minibar ist nicht im Zimmerpreis
                      enthalten. Der Kunde ist verpflichtet, für den Verbrauch
                      von Gütern aus der Minibar zu zahlen. Der Betreiber ist
                      berechtigt, eine Kaution für den Preis der Minibargüter zu
                      verlangen.
                    </li>
                  </ol>
                  <h4 className="mt-5">5. VERPFLICHTUNGEN DES KUNDEN</h4>
                  <ol>
                    <li>
                      Der Kunde ist verpflichtet, dem Betreiber alle für eine
                      ordnungsgemäße Leistungserbringung erforderlichen Angaben
                      zu geben, insbesondere das Aufenthaltsformular
                      wahrheitsgemäß und vollständig auszufüllen und dem
                      Betreiber die erforderlichen Dokumente zur Verfügung zu
                      stellen.
                    </li>
                    <li>
                      Der Kunde ist verpflichtet, das Entgelt für den Aufenthalt
                      und die anderen erbrachten Dienstleistungen fristgerecht
                      zu zahlen.
                    </li>
                    <li>
                      Der Kunde ist verpflichtet, die Unterkunftsordnung des
                      Betreibers in den unten aufgeführten Punkten zu befolgen:
                    </li>
                    <ol className="n">
                      <li>
                        Der Kunde nutzt das Zimmer für die vereinbarte
                        Zeitspanne. Sofern nicht anders vereinbart, ist die
                        Check-in-Zeit von 14:00 bis 18:00 Uhr (nach dieser Zeit
                        kann der Aufenthalt storniert und das Zimmer einem
                        anderen Kunden angeboten werden – es sei denn, eine
                        spätere Check-in-Zeit wird im Voraus vereinbart), am
                        letzten Tag des Aufenthalts muss der Kunde das Zimmer
                        spätestens um 10:00 Uhr räumen. Wenn es der Betrieb des
                        Unterkunftsobjektes zulässt, kann eine spätere Uhrzeit
                        für die Zimmerübergabe vereinbart werden.
                      </li>
                      <li>
                        Der Kunde ist verpflichtet, die Nachtruhe von 22:00 bis
                        07:00 Uhr einzuhalten.
                      </li>
                      <li>
                        c. Das Rauchen ist im Wellness-Hotel nicht erlaubt.
                      </li>
                      <li>
                        Der Kunde ist verpflichtet, sich so zu verhalten, dass
                        sein Verhalten die anderen Gäste des Wellnesshotels
                        nicht stört.
                      </li>
                      <li>
                        Der Kunde darf ohne Zustimmung des Betreibers keine
                        Geräte und Einrichtungsgegenstände im Zimmer
                        verschieben, Reparaturen vornehmen oder Eingriffe in das
                        Stromnetz oder andere Installationen vornehmen.
                      </li>
                      <li>
                        Der Kunde darf keine eigenen Elektrogeräte (z. B.
                        Wasserkocher, Herd, Toaster usw.) benutzen, sondern nur
                        die vom Hotel bereitgestellten.
                      </li>
                      <li>
                        Beim Verlassen des Zimmers ist der Kunde verpflichtet,
                        die Wasserhähne abzudrehen, das Licht auszuschalten, den
                        Fernseher auszuschalten, die Fenster zu schließen und
                        den Schlüssel (oder Chip, E-Karte) am Ende des
                        Aufenthalts abzugeben.
                      </li>
                      <li>
                        Aus Sicherheitsgründen dürfen Kinder unter 12 Jahren
                        nicht ohne Aufsicht von Erwachsenen im Zimmer oder in
                        anderen Bereichen des Hotels gelassen werden. Die Eltern
                        sind für die Sicherheit ihrer Kinder in allen Bereichen
                        des Hotels verantwortlich.
                      </li>
                      <li>
                        Der Kunde ist verpflichtet, die Grundsätze der Vorsicht
                        zu beachten. Der Kunde ist verpflichtet, erhöhte
                        Vorsicht walten zu lassen, insbesondere in den
                        Wintermonaten, wenn die Gefahr von Eis und Schneefall
                        besteht. Der Betreiber haftet dem Kunden gegenüber nicht
                        für Schäden im Falle einer Verletzung dieser Pflichten.
                      </li>
                      <li>
                        Der Kunde haftet in vollem Umfang für alle Schäden, die
                        am Eigentum des Wellnesshotels entstehen.
                      </li>
                      <li>
                        Die Unterbringung eines Tieres ist nur mit vorheriger
                        Zustimmung des Betreibers gestattet. Der Besitzer des
                        Tieres ist verpflichtet, den Gesundheitszustand des
                        Tieres durch Vorlage eines gültigen Impfausweises
                        nachzuweisen, wenn das Gesetz die Impfung des Tieres
                        festsetzt. Tiere dürfen nicht unbeaufsichtigt im Zimmer
                        gelassen werden. Tiere dürfen das Bett und andere
                        Gästeeinrichtungen nicht benutzen. Duschen oder
                        Waschbecken dürfen nicht zum Baden oder Waschen von
                        Tieren benutzt werden.
                      </li>
                    </ol>
                  </ol>
                  <h4 className="mt-5">
                    6. RÜCKTRITT VOM VERTRAG DURCH DEN KUNDEN
                  </h4>
                  <ol>
                    <li>
                      Der Kunde ist berechtigt, jederzeit vor Beginn der
                      Unterbringung oder der Inanspruchnahme von Leistungen des
                      Betreibers vom Vertrag mit dem Betreiber zurückzutreten.
                      Für die Bemessung der Stornogebühr ist der Zeitpunkt der
                      Annahme des Rücktritts durch den Betreiber maßgeblich.
                    </li>
                    <li>
                      Tritt der Kunde gemäß diesem Artikel vom Vertrag zurück,
                      so hat er die Stornogebühr zu zahlen.
                    </li>
                    <li>Die Stornogebühr beträgt:</li>
                    <ol className="n">
                      <li>30 Tage oder mehr vor Beginn des Aufenthalts 0%;</li>
                      <li>29–20 Tage vor Beginn des Aufenthalts 10%;</li>
                      <li>19–10 Tage vor Beginn des Aufenthalts 25%;</li>
                      <li>
                        9 Tage oder weniger vor Beginn des Aufenthalts 40%.
                      </li>
                    </ol>
                    <li>
                      Wenn der Kunde die Teilnahme am Aufenthalt storniert oder
                      die Bestellung des Aufenthalts storniert, ist der
                      Betreiber verpflichtet, den vom Kunden gezahlten Betrag
                      abzüglich der entsprechenden Stornogebühr innerhalb von 14
                      Tagen ab dem Datum, an dem der Betreiber den Rücktritt vom
                      Vertrag akzeptiert hat, zurückzuzahlen. Finanzielle
                      Vorschüsse werden per Banküberweisung auf die vom Kunden
                      angegebene Kontonummer zurückerstattet. Die mit der
                      Rückzahlung verbundenen Transaktionskosten gehen zu Lasten
                      des Kunden.
                    </li>
                  </ol>
                  <h4 className="mt-5">
                    7. AUSSCHLUSS DES KUNDEN AUS DER UNTERKUNFT
                  </h4>
                  <ol className="v">
                    <li>
                      Ein Kunde, der in der Zeit des Aufenthalts gegen die
                      Gesetze der Tschechischen Republik verstößt, wiederholt
                      gegen die in der Unterkunftsordnung festgelegten
                      Verpflichtungen verstößt, sich weigert, die rechtmäßigen
                      Anforderungen und Anweisungen des Betreibers zu befolgen,
                      die Vertreter des Betreibers verbal angreift, das Eigentum
                      des Unterkunftsobjekts beschädigt und durch sein Verhalten
                      die Rechte des Betreibers oder anderer Gäste einschränkt,
                      kann von der Fortsetzung des Aufenthalts ausgeschlossen
                      werden, wobei sein Anspruch auf eine Entschädigung für
                      nicht in Anspruch genommene Leistungen verwirkt wird.
                    </li>
                  </ol>
                  <h4 className="mt-5">
                    RÜCKTRITT VOM VERTRAG DURCH DEN BETREIBER
                  </h4>
                  <ol className="v">
                    <li>
                      Der Betreiber ist zum sofortigen Rücktritt vom Vertrag
                      berechtigt, wenn er feststellt, dass der Kunde den Vertrag
                      in erheblicher Weise verletzt hat. Als wesentliche
                      Vertragsverletzung im Sinne dieses Vertrages gilt
                      insbesondere:
                    </li>
                  </ol>
                  <ol className="n">
                    <li>
                      der Verzug des Kunden bei der Zahlung des Preises für die
                      erbrachten Dienstleistungen;
                    </li>
                    <li>
                      die Nichteinhaltung des gebuchten Unterkunftstermins;
                    </li>
                    <li>
                      ceinen Verstoß gegen die in den AGB festgelegten
                      Verpflichtungen.
                    </li>
                  </ol>
                  <h4 className="mt-5">
                    9. ÄNDERUNGEN DER VEREINBARTEN LEISTUNGEN
                  </h4>
                  <ol>
                    <li>
                      Im Falle von außergewöhnlichen oder unerwarteten Umständen
                      oder Umständen, die auf „höhere Gewalt“ zurückzuführen
                      sind, kann der Betreiber den Aufenthalt stornieren oder in
                      Absprache mit dem Kunden ändern (Datum, Preis). Wenn der
                      Aufenthalt des Kunden bereits begonnen hat, hat der
                      Betreiber das Recht, die bis dahin erbrachten Leistungen
                      bezahlt zu bekommen.
                    </li>
                    <li>
                      Im Falle einer Stornierung des Aufenthalts durch den
                      Betreiber ist der Betreiber verpflichtet, den Kunden
                      unverzüglich zu benachrichtigen und den für nicht in
                      Anspruch genommene Leistungen gezahlten Betrag innerhalb
                      von 14 Tagen nach der Benachrichtigung zu erstatten.
                    </li>
                  </ol>
                  <h4 className="mt-5">10. REKLAMATIONSVERFAHREN</h4>
                  <ol>
                    <li>
                      Im Falle einer Reklamation ist der Kunde verpflichtet,
                      Mängel unverzüglich direkt beim Vertreter des Betreibers
                      zum Zeitpunkt des Aufenthalts und am Aufenthaltsort zu
                      rügen, vorzugsweise bei der Ankunft in der Unterkunft und
                      der Inanspruchnahme der Dienstleistungen, damit sie an Ort
                      und Stelle behoben werden können. Wenn dies nicht möglich
                      ist, erstellt er/sie eine schriftliche Reklamation beim
                      Betreiber. Dieses Protokoll ist die Grundlage für die
                      Beilegung der Reklamation. Das Protokoll wird in der Regel
                      in zwei Exemplaren (eines für den Betreiber und eines für
                      den Kunden) erstellt und muss sowohl vom Kunden als auch
                      vom Betreiber unterzeichnet werden.
                    </li>
                    <li>
                      Wird die Reklamation nicht unverzüglich oder so schnell
                      wie möglich vom Betreiber und vom Kunden eingereicht, so
                      erlischt der Anspruch des Kunden auf Ersatz des später
                      entstandenen Schadens, der bei rechtzeitiger Einreichung
                      hätte verhindert werden können. Für die erbrachten
                      Dienstleistungen gelten Schäden und Sach- oder
                      Nicht-Sachschäden, die durch das Verschulden des Kunden
                      verursacht wurden, nicht als Gegenstand der Reklamation.
                    </li>
                  </ol>
                  <h4 className="mt-5">11. DATENSCHUTZ</h4>
                  <ol>
                    <li>
                      Der Betreiber verpflichtet sich, die ihm aufgrund des
                      Vertrages zur Verfügung gestellten personenbezogenen Daten
                      im Einklang mit den gesetzlichen Bestimmungen,
                      insbesondere der Verordnung (EU) 2016/679 des Europäischen
                      Parlaments und des Rates vom 27. April 2016 zum Schutz
                      natürlicher Personen bei der Verarbeitung
                      personenbezogener Daten, zum freien Datenverkehr und zur
                      Aufhebung der Richtlinie 95/46/EG, zu schützen und zu
                      verarbeiten.
                    </li>
                    <li>
                      Aus Sicherheitsgründen und zum Schutz der Gäste ist in den
                      Korridoren und anderen öffentlichen Bereichen des Hotels
                      ein Kamerasystem installiert. Der Umgang mit diesen
                      Aufnahmen erfolgt in Übereinstimmung mit der einschlägigen
                      Gesetzgebung zum Schutz der Persönlichkeit.
                    </li>
                  </ol>
                  <h4 className="mt-5">12. SCHLUSSBESTIMMUNGEN</h4>
                  <ol>
                    <li>
                      Für Angelegenheiten, die in diesen AGB nicht geregelt
                      sind, gilt das Recht der Tschechischen Republik,
                      insbesondere die einschlägigen Bestimmungen des Gesetzes
                      Nr. 89/2012 Slg., des Bürgerlichen Gesetzbuchs. Sollte
                      eine Bestimmung dieser AGB mit zwingenden gesetzlichen
                      Bestimmungen in Konflikt stehen, so gelten die
                      entsprechenden gesetzlichen Bestimmungen und berühren
                      nicht die Gültigkeit der AGB als Ganzes.
                    </li>
                    <li>
                      Für Angelegenheiten, die in diesen AGB nicht geregelt
                      sind, gilt das Recht der Tschechischen Republik,
                      insbesondere die einschlägigen Bestimmungen des Gesetzes
                      Nr. 89/2012 Slg., des Bürgerlichen Gesetzbuchs. Sollte
                      eine Bestimmung dieser AGB mit zwingenden gesetzlichen
                      Bestimmungen in Konflikt stehen, so gelten die
                      entsprechenden gesetzlichen Bestimmungen und berühren
                      nicht die Gültigkeit der AGB als Ganzes.
                    </li>
                    <li>
                      Der Betreiber behält sich das Recht vor, über diese AGB
                      hinaus weitere Rechte und Pflichten der Parteien für
                      ausgewählte Produkte zu ändern. Diese aktuelle Version der
                      AGB ist ab dem 1. Juni 2022 gültig und wirksam.
                    </li>
                  </ol>
                </div>
              </>
            ) : (
              <>
                <div className="podminky">
                  <p>
                    <br></br>
                  </p>
                  <h2 className="text-center">
                    GENERAL TERMS AND CONDITIONS - ACCOMMODATION RULES
                  </h2>
                  <h2>WELLNESS HOTEL EDELWEISS</h2>
                  <h4 className="mt-5">1. GENERAL PROVISIONS</h4>
                  <ol>
                    <li>
                      These General Terms and Conditions - Accommodation Rules
                      (hereinafter also referred to as "GTC") regulate the
                      contractual relationship between the Operator of the
                      accommodation facility called Edelweiss, address Za
                      Domovem 333, 460 08 Liberec, which is the company
                      Profibüro s.r.o., ID No.: 037 32 746, with its registered
                      office at Jiráskova 121/11, Liberec XIII - Nové Pavlovice,
                      460 01 Liberec (hereinafter also referred to as
                      "Operator") and the accommodated person / service user
                      (hereinafter also referred to as a “Client”).
                    </li>
                    <li>
                      Wellness hotel is an accommodation facility in which is
                      the Client accommodated on the basis of an order -
                      concluded contract.
                    </li>
                    <li>
                      Services provided by the Operator mean the provision of
                      accommodation to the Client in the hotel and the related
                      services also provided by the Operator. The exact
                      specification of the stay and services provided is
                      contained in the agreement between the Operator and the
                      Client.
                    </li>
                    <li>
                      By using the services provided, the Client agrees to the
                      full text of these GTC. If the Client does not agree with
                      any part of the GTC, he/she is not entitled to use the
                      services unless the Client and the Operator agree
                      otherwise. The Provider reserves the right to change the
                      GTC. The concluded contractual relationship is governed by
                      those GTC that were in force at the time of conclusion of
                      the contract. Each Client is obliged to familiarize
                      him/herself with these Terms and Conditions, his/her
                      ignorance will not be taken into account. The
                      Accommodation Rules are available at the hotel reception.
                    </li>
                    <li>
                      The Operator shall not be responsible for damage to items
                      brought by the Client into the wellness hotel or for
                      damage to items left behind. The Operator is only
                      responsible for money and valuables if these have been
                      taken into custody against a written confirmation.
                    </li>
                  </ol>
                  <h4 className="mt-5">2. BOOKING OF THE ACCOMMODATION</h4>
                  <ol className="v">
                    <li>
                      The Client can book the stay by phone, electronically by
                      e-mail or in person. The accommodation contract is
                      concluded at the moment of confirmation of the order by
                      the Operator. Confirmation of the order is usually made by
                      the Operator by electronic communication and without
                      unnecessary delays.
                    </li>
                  </ol>
                  <h4 className="mt-5">3. PRICE OF THE ACCOMMODATION</h4>
                  <ol>
                    <li>
                      The indicative prices of accommodation and any other
                      services are given in the Operator's presentation
                      materials (website, brochures, etc.), while the indication
                      of these prices does not constitute an offer within the
                      meaning of § 1731 or § 1732 of Act No. 89/2012 Coll., the
                      Civil Code.
                    </li>
                    <li>
                      The price stated in the order confirmation or email offer
                      is binding. The Operator shall issue a tax document -
                      invoice to the Client regarding payments made under the
                      Agreement, which shall serve as a document for payment for
                      the service. The final price of the stay is based on the
                      Client's requirements for the stay and is based on the
                      current price list of the Operator's services. The
                      Operator reserves the right to adjust prices in such a
                      case that during the period from the determination of
                      price to the realization of the accommodation happens a
                      change in legislative and tax regulations and other
                      changes due to "force majeure".
                    </li>
                  </ol>
                  <h4 className="mt-5">4. Platební podmínky</h4>
                  <ol>
                    <li>
                      The Client will receive an (advance) invoice for the
                      accommodation, which must be paid to the account number by
                      the due date or by making a cash payment or by payment on
                      the spot
                    </li>
                    <li>
                      If the invoice is not paid by the due date, the booking is
                      automatically cancelled. The variable symbol is indicated
                      on the invoice and is used to identify the Client. For all
                      forms of cashless payments, the crediting to the
                      Operator's account and/or the payment of cash upon arrival
                      at the accommodation or use of the services provided by
                      the Operator shall be decisive.
                    </li>
                    <li>
                      Payment is usually a one-off payment. In case of a
                      cashless payment, is the Client obliged to pay the price
                      together with the correct variable symbol of the payment,
                      otherwise the Operator will not be able to identify the
                      payment and provide the required performance in time.
                    </li>
                    <li>
                      The invoice shall be due within the period stated thereon,
                      but no later than the date of arrival at the
                      accommodation, unless it is agreed that the accommodation
                      and other services provided will be paid after they have
                      been provided. The Client's obligation to pay the price
                      for the service is fulfilled at the moment of crediting
                      the relevant amount to the Operator's account or by
                      handover of cash at the place of services performance.
                    </li>
                    <li>
                      In case of delay in payment of any amount (price for
                      accommodation), has the Operator right to withdraw from
                      the contract without undue delay.
                    </li>
                    <li>
                      The range of goods in the minibar is not included in the
                      price of accommodation. The Client is obliged to pay for
                      any consumption of goods from the minibar. The Operator is
                      entitled to demand a deposit for the price of the minibar
                      goods
                    </li>
                  </ol>
                  <h4 className="mt-5">5. CLIENT'S OBLIGATIONS</h4>
                  <ol>
                    <li>
                      The Client is obliged to provide the Operator with all the
                      details necessary for the proper provision of services, in
                      particular to fill in the order form truthfully and
                      completely. The Client is also obliged to provide the
                      Operator with the required documents.
                    </li>
                    <li>
                      The Client is obliged to pay the payment for the stay and
                      other services provided by the due date.
                    </li>
                    <li>
                      The Client is obliged to follow the Operator's
                      Accommodation Rules as well as the points listed below:
                    </li>
                    <ol className="n">
                      <li>
                        The Client is obliged to follow the Operator's
                        Accommodation Rules as well as the points listed below
                      </li>
                      <li>
                        The Client is obliged to observe night-time silence
                        between 22:00 – 7:00.
                      </li>
                      <li>Smoking is not allowed in the wellness hotel.</li>
                      <li>Smoking is not allowed in the wellness hotel.</li>
                      <li>
                        The Client must not move equipment and facilities in the
                        room, make repairs or any interference with the
                        electrical network or other installations without the
                        owner's consent.
                      </li>
                      <li>
                        The Client is not allowed to use his/her own electrical
                        appliances (e.g. kettles, cookers, toasters, etc.). Only
                        the used of the hotel's equipment electrical appliances
                        are allowed.
                      </li>
                      <li>
                        When leaving the room, the Client is obliged to turn off
                        the taps, turn off the lights, turn off the TV, close
                        the windows and hand over the key at the end of the stay
                        (or chip, electrical card).
                      </li>
                      <li>
                        For safety reasons it is not allowed to leave children
                        under 12 years of age without adult supervision in the
                        room and other areas of the hotel. Parents are
                        responsible for the safety of their children in all
                        areas of the hotel.
                      </li>
                      <li>
                        The client is obliged to observe the principles of
                        caution. The Client is obliged to exercise increased
                        caution especially during the winter months when there
                        is a risk of ice and snow fall. The Operator shall not
                        be liable to the Client for damages in the event of a
                        breach of these obligations.
                      </li>
                      <li>
                        The Client is fully liable for any damage caused to the
                        property of the wellness hotel.
                      </li>
                      <li>
                        Accommodation with an animal is only permitted with the
                        prior consent of the Operator. The owner of the animal
                        is obliged to prove its good health by presenting a
                        valid vaccination certificate if there is is legally
                        obligation to vaccinate this animal. Animals are not
                        allowed to be left unattended in the room. Animals are
                        not allowed to use the bed and other guest facilities.
                        Showers or sinks may not be used for bathing or washing
                        animals.
                      </li>
                    </ol>
                  </ol>
                  <h4 className="mt-5">
                    6. WITHDRAWAL FROM THE CONTRACT BY THE CLIENT
                  </h4>
                  <ol>
                    <li>
                      The Client is entitled to withdraw from the contract at
                      any time before the commencement of accommodation or use
                      of the services provided by the Operator takes place. For
                      the determination of the cancellation fee, the moment of
                      acceptance of the withdrawal from the contract by the
                      Operator is decisive.
                    </li>
                    <li>
                      In the event of withdrawal from the contract by the Client
                      in accordance with the procedure set out in this Article,
                      the Client shall pay the cancellation fee.
                    </li>
                    <li>The cancellation fee shall be:</li>
                    <ol className="n">
                      <li>
                        30 days or more prior to arrival 0% of the total stay
                      </li>
                      <li>
                        29 - 20 days prior to arrival 10% of the total stay
                      </li>
                      <li>
                        19 - 10 days prior to arrival 25% of the total stay
                      </li>
                      <li>
                        9 days or less prior to arrival 40% of the total stay
                      </li>
                    </ol>
                    <li>
                      If the Client cancels participation in the accommodation
                      or cancels the order of the stay, the Operator is obliged
                      to refund the Client the amount lowered by the relevant
                      cancellation fee within 14 days from the date on which the
                      cancellation was accepted by the Operator. Financial
                      advances are refunded by bank transfer to the account
                      number specified by the Client. The transaction costs
                      associated with the refund of the funds shall be covered
                      by the Client.
                    </li>
                  </ol>
                  <h4 className="mt-5">
                    7. EXCLUSION OF THE CLIENT FROM THE STAY
                  </h4>
                  <ol className="v">
                    <li>
                      A client who during his/her stay violates the laws of the
                      Czech Republic, repeatedly violates the obligations set by
                      the accommodation rules, refuses to follow the legitimate
                      requirements and instructions of the Operator, verbally
                      attacks the Operator's representatives, damages the
                      property of the accommodation facility or his/her
                      behaviour restricts the rights of the Operator or other
                      guests, may be excluded from the stay. In such a case
                      his/her right to compensation for unused services will be
                      forfeited.
                    </li>
                  </ol>
                  <h4 className="mt-5">
                    8. WITHDRAWAL FROM THE CONTRACT BY THE OPERATOR
                  </h4>
                  <ol className="v">
                    <li>
                      The Operator is entitled to withdraw from the contract
                      immediately when he finds that the Client has breached the
                      contract in a significant way. For the purposes of this
                      Agreement, a significant breach shall be in particular:
                    </li>
                  </ol>
                  <ol className="n">
                    <li>
                      the Client's default in payment of the price for the
                      services provided
                    </li>
                    <li>
                      failure to keep the booked date for the accommodation
                    </li>
                    <li>violation of the obligations set by the GTC.</li>
                  </ol>
                  <h4 className="mt-5">9. CHANGES TO THE AGREED SERVICES</h4>
                  <ol>
                    <li>
                      If extraordinary or unexpected circumstances or
                      circumstances due to "force majeure" occur, the Operator
                      may cancel the stay or with the agreement of the Client
                      change the stay (date, price). If the Client's stay has
                      already started, the Operator has the right to be paid for
                      the services provided so far.
                    </li>
                    <li>
                      In case of cancellation of the stay by the Operator, the
                      Operator is obliged to notify the Client without undue
                      delay and to refund the Client the amount paid for the
                      unused services within 14 days of the notification.
                    </li>
                  </ol>
                  <h4 className="mt-5">10. COMPLAINTS PROCEDURE</h4>
                  <ol>
                    <li>
                      In case of a complaint, the Client is obliged to submit
                      his/her claims without undue delay directly to the
                      Operator's representative at the time of the stay and at
                      the place of the stay, preferably at the time of arrival
                      and use of the services, so that they can be rectified on
                      the spot. If this is not possible, the Client draws up a
                      written complaint with the Operator. This protocol shall
                      be the basis for the resolution of the complaint. The
                      protocol is usually drawn up in two copies (1 for the
                      Operator and 1 for the Client) and must be signed by both
                      the Client and the Operator.
                    </li>
                    <li>
                      If the complaint is not claimed immediately or as soon as
                      it is by the Operator and the Client possible, the
                      Client's right to compensation for later incurred damages
                      that could have been prevented by timely complaint is
                      extinguished. For the Services provided, damages and
                      material or non-material losses caused by the Client's
                      fault shall not be considered as the subject of a claim.
                    </li>
                  </ol>
                  <h4 className="mt-5">11. PROTECTION OF PERSONAL DATA</h4>
                  <ol>
                    <li>
                      The Provider undertakes to protect and process the
                      personal data provided under the Contract in accordance
                      with the law, in particular Regulation of the European
                      Parliament and the Council (EU) 2016/679 of 27 April 2016
                      on the protection of individual regarding the processing
                      of personal data and on the free movement of such data and
                      repealing Directive 95/46/EC.
                    </li>
                    <li>
                      For security reasons and to protect guests, a CCTV system
                      is installed in the corridors and other public areas of
                      the hotel. The handling of these recordings is carried out
                      in accordance with the relevant legislation on the
                      protection of personality.
                    </li>
                  </ol>
                  <h4 className="mt-5">12. FINAL PROVISIONS</h4>
                  <ol>
                    <li>
                      Matters not covered by these GTC are governed by the law
                      of the Czech Republic, in particular the relevant
                      provisions of Act No. 89/2012 Coll., Civil Code. In the
                      case that any provision of these GTC is or becomes in
                      conflict with mandatory provisions of law, the relevant
                      statutory provisions shall apply and shall not affect the
                      validity of the GTC as a whole.
                    </li>
                    <li>
                      All legal disputes arising in relation with the
                      contractual relationship between the Client and the
                      Operator shall be settled in civil court proceedings by
                      the ordinary courts of the Czech Republic, the consumer
                      shall also have the right to out-of-court settlement. The
                      Czech Trade Inspectorate is the subject of out-of-court
                      settlement pursuant to Act No. 634/1992 Coll., on Consumer
                      Protection. All the details of the out-of-court settlement
                      can be found on the website of the Czech Trade
                      Inspectorate https://www.coi.cz/en/ .
                    </li>
                    <li>
                      The Operator reserves the right to modify other rights and
                      obligations of the contracting parties for selected
                      products beyond the scope of these GTC. This current
                      version of the GTC is valid and effective as of 1 June
                      2022.
                    </li>
                  </ol>
                </div>
              </>
            )}
          </Container>
        </Layout>
      </Fade>
    </>
  );
};

export default Podminky;
