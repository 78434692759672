import React, { Fragment, useState, useContext } from "react";
import Progress from "./Progress";
import axios from "axios";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import Compressor from "compressorjs";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import removeAccents from "remove-accents";
import squash from "just-squash";

const FileUploadEdit = () => {
  const [file, setFile] = useState([]);
  const [filename, setFilename] = useState([]);
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const navigate = useHistory();
  const { id } = useParams();
  const [done, setDone] = useState(false);
  const [{ accessToken }] = useContext(ApplicationContext);

  const onChange = (e) => {
    const image = e.target.files;
    for (let i = 0; i < image.length; i++) {
      new Compressor(image[i], {
        quality: 0.7,
        maxWidth: 1920,
        maxHeight: 1080,
        resize: "cover",
        success: (compressedResult) => {
          setFile((file) => [...file, compressedResult]);
        },
      });
      setFilename((filename) => [
        ...filename,
        squash(removeAccents(e.target.files[i].name)),
      ]);
    }
    setDone(true);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    for (let i = 0; i < file.length; i++) {
      const formData = new FormData();
      formData.append("file", file[i]);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/image/upload/image/gallery/${id}/${filename[i]}`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-url-formencoded",
              Authorization: "Bearer " + accessToken,
            },
            onUploadProgress: (progressEvent) => {
              setUploadPercentage(
                parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              );
            },
          }
        );

        // Clear percentage
        setTimeout(() => setUploadPercentage(0), 10000);

        const { fileName, filePath } = res.data;

        setUploadedFile({ fileName, filePath });

        setMessage("File Uploaded");
        navigate.push("/admin/gallery-list/type/" + id);
      } catch (err) {
        setUploadPercentage(0);
      }
    }
  };

  return (
    <Container>
      <Fragment>
        <form onSubmit={onSubmit}>
          <div className="custom-file mb-4">
            <input
              type="file"
              accept="image/*"
              className="custom-file-input"
              id="customFile"
              multiple
              onChange={onChange}
            />
            <label className="custom-file-label" htmlFor="customFile">
              {filename}
            </label>
          </div>

          <Progress percentage={uploadPercentage} />
          {done ? (
            <input type="submit" value="NAHRÁT" className="login-button" />
          ) : (
            <input type="submit" value="NAHRÁT" className="login-button" disabled/>
          )}
        </form>
        {uploadedFile ? (
          <div className="row mt-5">
            <div className="col-md-6 m-auto">
              <h3 className="text-center">{uploadedFile.fileName}</h3>
              <img
                style={{ width: "100%" }}
                src={uploadedFile.filePath}
                alt=""
              />
            </div>
          </div>
        ) : null}
      </Fragment>
    </Container>
  );
};

export default FileUploadEdit;
