import React, { useContext, useState } from "react";
import LayoutOther from "../layout/LayoutOther";
import { ApplicationContext } from "../../providers/ApplicationProvider";
import AccessDenied from "../pages/AccessDenied";
import { useFormik } from "formik";
import { Form } from "reactstrap";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";

const Help = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);
  var history = useHistory();

  const notify = () => toast.success("Vaše zpráva byla odeslána, odpovím Vám na info@hoteledelweiss.cz!");
  const notifyError = () =>
    toast.error(
      "Někde se stala chyba, zkuste to znovu. Nebo napište správci: tom.kulhavy@seznam.cz"
    );

  const formik = useFormik({
    initialValues: {
      text: "",
    },
    onSubmit: (values) => {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Email/helpDesk`, {
          text: values.text,
        })
        .then(() => {
          setDone(true);
          notify();
        })
        .catch((error) => {
          setError(true);
          notifyError();
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  if (!accessToken) {
    history.push("/");
    return (
      <>
        <AccessDenied />
      </>
    );
  } else if (accessToken) {
    return (
      <>
        <LayoutOther>
          <div className="mt-5 pt-5">
            <h1>HELPDESK</h1>
            <p className="text-center">Napište mi, co je za chybu. Co nejdříve Vám odepíšu a poradím, či opravím.</p>
          </div>
          <Form
            inline
            className="helpdesk-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="contact-form pe-3">
              <div className="form-floating mb-3">
                <textarea
                  className={`form-control`}
                  id="floatingInput"
                  placeholder="Vaše zpráva"
                  name="text"
                  onChange={formik.handleChange}
                  invalid={Boolean(formik.errors.text)}
                  valid={formik.touched.text}
                />
                <label for="floatingInput">Vaše zpráva *</label>
              </div>
              <div className="buttonIco text-start">
                {formik.errors.email ||
                formik.errors.number ||
                formik.errors.text ||
                formik.errors.name ? (
                  <button
                    className="contact-button disabled"
                    type="submit"
                    disabled
                  >
                    ODESLAT
                  </button>
                ) : (
                  <button className="contact-button" type="submit">
                    ODESLAT
                  </button>
                )}
              </div>
            </div>
          </Form>
          <Toaster position="bottom-right" reverseOrder={false} />
        </LayoutOther>
      </>
    );
  }
};

export default Help;
