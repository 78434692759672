import React, { useContext, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";
import Layout from "./layout/Layout";
import { ApplicationContext } from "../providers/ApplicationProvider";
import "./styles/style.css";
import "./styles/typography.css";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const QRCodes_Menu = () => {
  const [{ lang }] = useContext(ApplicationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Moderní QR kod menu přímo ev Vašem telefonu v českém, anglickém a německém jazyce"
        />
        <title>Wellness Hotel Edelweiss | Menu - Restaurace</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Menu - Restaurace"
        />
        <link rel="canonical" href="https://www.hoteledelweiss.cz"></link>
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Menu - Restaurace"
        />
        <meta
          itemprop="description"
          content="Moderní QR kod menu přímo ev Vašem telefonu v českém, anglickém a německém jazyce"
        />
        <meta
          itemprop="image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta
          property="og:url"
          content="https://hoteledelweiss.cz/restaurant/menu/select"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Menu - Restaurace"
        />
        <meta
          property="og:description"
          content="Moderní QR kod menu přímo ev Vašem telefonu v českém, anglickém a německém jazyce"
        />
        <meta
          property="og:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Wellness Hotel Edelweiss | Menu - Restaurace"
        />
        <meta
          name="twitter:description"
          content="Moderní QR kod menu přímo ev Vašem telefonu v českém, anglickém a německém jazyce"
        />
        <meta
          name="twitter:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta
          name="description"
          content="Moderní QR kod menu přímo ev Vašem telefonu v českém, anglickém a německém jazyce"
        />
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Menu - Restaurace"
        />
        <meta
          itemprop="description"
          content="Moderní QR kod menu přímo ev Vašem telefonu v českém, anglickém a německém jazyce"
        />
      </Helmet>
      <Fade>
        <Layout>
          <Container>
            <div className="cards-food qr">
              {lang === "cs" ? (
                <h1>Restaurace - Menu (jídelní a nápojový lístek)</h1>
              ) : lang === "de" ? (
                <h1>Restaurant - Menü (Speise- und Getränkekarte)</h1>
              ) : (
                <h1>Restaurant - Menu (food and drink menu)</h1>
              )}
              <Row className="mt-1 mb-3 mb-sm-3 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-green mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>ČESKÉ</h4>
                        <p>MENU</p>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <a
                        target="_blank"
                        href="https://hoteledelweiss.cz/QRcodes/CZ/Menu.html"
                      >
                        <button className="card-button">MENU</button>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>ENGLISH</h4>
                        <p>MENU</p>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <a
                        target="_blank"
                        href="https://hoteledelweiss.cz/QRcodes/EN/Menu.html"
                      >
                        <button className="card-button">MENU</button>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>DEUTSCH</h4>
                        <p>MENU</p>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <a
                        target="_blank"
                        href="https://hoteledelweiss.cz/QRcodes/DE/Menu.html"
                      >
                        <button className="card-button">MENU</button>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
            <br></br>
            <br></br>
          </Container>
        </Layout>
      </Fade>
    </>
  );
};

export default QRCodes_Menu;
