import React, { useEffect, useContext, useState } from "react";
import Layout from "./layout/LayoutContact";
import {
  ApplicationContext,
  SET_SLIDER,
} from "../providers/ApplicationProvider";
import { Col, Container, Row, Form } from "reactstrap";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import contact1 from "../assets/images/contact/_30A5973.jpg";
import contact2 from "../assets/images/contact/_30A5967.jpg";
import contact3 from "../assets/images/contact/_30A5930.jpg";
import contact4 from "../assets/images/contact/_30A5895.jpg";
import contact5 from "../assets/images/contact/_30A5873.jpg";
import contact6 from "../assets/images/contact/_30A5995.jpg";
import contact7 from "../assets/images/contact/_30A5970.jpg";
import Slider from "./SliderImage";
import { Fade } from "react-awesome-reveal";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

const Contact = () => {
  const [{ lang }] = useContext(ApplicationContext);
  const [{ slider }, dispatch] = useContext(ApplicationContext);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const notify = () => toast.success("Vaše zpráva byla odeslána!");
  const notifyError = () =>
    toast.error("Někde se stala chyba, zkuste to znovu.");

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = " ";
    }
    if (!values.email) {
      errors.email = " ";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email =
        lang === "De"
          ? "Ungültige E-Mail-Adresse"
          : "Neplatná e-mailová adresa";
    }
    if (!values.number) {
      errors.number = " ";
    } else if (
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(values.number)
    ) {
      errors.number =
        lang === "De" ? "Ungültige Telefonnummer" : "Neplatné telefonní číslo";
    }
    if (!values.text) {
      errors.text = " ";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      number: "",
      text: "",
    },
    validate: validate,
    onSubmit: (values, { resetForm }) => {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Email/sendEmail`, {
          name: values.name,
          email: values.email,
          number: values.number,
          text: values.text,
        })
        .then(() => {
          setDone(true);
          notify();
          history.push("/thank-you");
          resetForm();
        })
        .catch((error) => {
          setError(true);
          notifyError();
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  function down(index) {
    if (slider[index] - 1 < 0) {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index],
      });
    } else {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index] - 1,
      });
    }
  }

  function up(index) {
    if (slider[index] + 1 > 3) {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index],
      });
    } else {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index] + 1,
      });
    }
  }

  const images = [
    contact5,
    contact1,
    contact2,
    contact3,
    contact4,
    contact6,
    contact7,
  ];

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Náš hotel jsme pojmenovali po horské květině Protěž alpská, která odolává ve vysokých horách mrazu i suchu. Přestože v okolí Ještědu neroste, inspirovala nás svojí krásou a houževnatostí. Podle lidové tradice je darování protěže příslibem oddanosti. Naším mottem tedy je připravit našim hostům místo, kde si odpočinou a načerpají síly."
        />
        <title>Wellness Hotel Edelweiss | Kontakt</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Kontakt"
        />
        <link
          rel="canonical"
          href="https://www.hoteledelweiss.cz/contact"
        ></link>
        <meta itemprop="name" content="Wellness Hotel Edelweiss | Kontakt" />
        <meta
          itemprop="description"
          content="Náš hotel jsme pojmenovali po horské květině Protěž alpská, která odolává ve vysokých horách mrazu i suchu. Přestože v okolí Ještědu neroste, inspirovala nás svojí krásou a houževnatostí. Podle lidové tradice je darování protěže příslibem oddanosti. Naším mottem tedy je připravit našim hostům místo, kde si odpočinou a načerpají síly."
        />
        <meta
          itemprop="image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta property="og:url" content="https://hoteledelweiss.cz/contact" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Kontakt"
        />
        <meta
          property="og:description"
          content="Náš hotel jsme pojmenovali po horské květině Protěž alpská, která odolává ve vysokých horách mrazu i suchu. Přestože v okolí Ještědu neroste, inspirovala nás svojí krásou a houževnatostí. Podle lidové tradice je darování protěže příslibem oddanosti. Naším mottem tedy je připravit našim hostům místo, kde si odpočinou a načerpají síly."
        />
        <meta
          property="og:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Wellness Hotel Edelweiss | Kontakt"
        />
        <meta
          name="twitter:description"
          content="Náš hotel jsme pojmenovali po horské květině Protěž alpská, která odolává ve vysokých horách mrazu i suchu. Přestože v okolí Ještědu neroste, inspirovala nás svojí krásou a houževnatostí. Podle lidové tradice je darování protěže příslibem oddanosti. Naším mottem tedy je připravit našim hostům místo, kde si odpočinou a načerpají síly."
        />
        <meta
          name="twitter:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta
          name="description"
          content="Náš hotel jsme pojmenovali po horské květině Protěž alpská, která odolává ve vysokých horách mrazu i suchu. Přestože v okolí Ještědu neroste, inspirovala nás svojí krásou a houževnatostí. Podle lidové tradice je darování protěže příslibem oddanosti. Naším mottem tedy je připravit našim hostům místo, kde si odpočinou a načerpají síly."
        />
        <meta itemprop="name" content="Wellness Hotel Edelweiss | Kontakt" />
        <meta
          itemprop="description"
          content="Náš hotel jsme pojmenovali po horské květině Protěž alpská, která odolává ve vysokých horách mrazu i suchu. Přestože v okolí Ještědu neroste, inspirovala nás svojí krásou a houževnatostí. Podle lidové tradice je darování protěže příslibem oddanosti. Naším mottem tedy je připravit našim hostům místo, kde si odpočinou a načerpají síly."
        />
      </Helmet>
      <Fade>
        <Layout>
          <Container className="contact-page">
            <Form
              inline
              className="contact-form-flex"
              onSubmit={formik.handleSubmit}
            >
              <Row>
                <Col lg={7} className="pe-lg-0">
                  <div className="contact-form pe-3">
                    <h4>
                      {lang === "cs"
                        ? "Kontaktujte nás"
                        : lang === "en"
                        ? "Contact us"
                        : "Kontaktieren Sie uns"}
                    </h4>
                    <div className="form-floating mb-3">
                      <input
                        type="name"
                        name="name"
                        className="form-control"
                        id="floatingInput"
                        placeholder={
                          lang === "cs"
                            ? "Jméno a příjmení"
                            : lang === "en"
                            ? "Name"
                            : "Name"
                        }
                        onChange={formik.handleChange}
                        invalid={Boolean(formik.errors.name)}
                        valid={formik.touched.name}
                      />
                      <label for="floatingInput">
                        {lang === "cs"
                          ? "Jméno a příjmení"
                          : lang === "en"
                          ? "Name"
                          : "Name"}{" "}
                        *
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        name="number"
                        type="tel"
                        className="form-control"
                        id="floatingInput"
                        placeholder={
                          lang === "cs"
                            ? "Telefonní číslo"
                            : lang === "en"
                            ? "Telephone number"
                            : "Telefonnummer"
                        }
                        onChange={formik.handleChange}
                        invalid={Boolean(formik.errors.number)}
                        valid={formik.touched.number}
                      />
                      <label for="floatingInput">
                        {lang === "cs"
                          ? "Telefonní číslo"
                          : lang === "en"
                          ? "Telephone number"
                          : "Telefonnummer"}{" "}
                        *
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="E-mail"
                        onChange={formik.handleChange}
                        invalid={Boolean(formik.errors.email)}
                        valid={formik.touched.email}
                      />
                      <label for="floatingInput">E-mail *</label>
                    </div>
                    <div className="form-floating mb-3">
                      <textarea
                        className={`form-control`}
                        id="floatingInput"
                        placeholder={
                          lang === "cs"
                            ? "Vaše zpráva"
                            : lang === "en"
                            ? "Message"
                            : "Nachricht"
                        }
                        name="text"
                        onChange={formik.handleChange}
                        invalid={Boolean(formik.errors.text)}
                        valid={formik.touched.text}
                      />
                      <label for="floatingInput">
                        {lang === "cs"
                          ? "Vaše zpráva"
                          : lang === "en"
                          ? "Message"
                          : "Nachricht"}{" "}
                        *
                      </label>
                    </div>
                    <p className="text-start text-white mt-1">
                      * -{" "}
                      {lang === "cs"
                        ? "Povinné"
                        : lang === "en"
                        ? "Mandatory"
                        : "Pflichtfeld"}
                    </p>
                    <div className="buttonIco text-start">
                      {formik.errors.email ||
                      formik.errors.number ||
                      formik.errors.text ||
                      formik.errors.name ? (
                        <button
                          className="contact-button disabled"
                          type="submit"
                          disabled
                        >
                          {lang === "cs"
                            ? "ODESLAT"
                            : lang === "en"
                            ? "SEND"
                            : "SENDEN"}
                        </button>
                      ) : (
                        <button className="contact-button" type="submit">
                          {lang === "cs"
                            ? "ODESLAT"
                            : lang === "en"
                            ? "SEND"
                            : "SENDEN"}
                        </button>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={5} className="ps-lg-0 contact-arrow slider-box">
                  <img
                    src={images[slider[4]]}
                    alt="room"
                    className="contact-photo"
                  />
                  <Slider
                    max="6"
                    index={4}
                    classname="slider contact-s"
                  ></Slider>
                  <div className="left-arrow-c" onClick={() => down(4)}>
                    <ion-icon name="chevron-back-sharp"></ion-icon>
                  </div>
                  <div className="right-arrow-c" onClick={() => up(4)}>
                    <ion-icon name="chevron-forward-sharp"></ion-icon>
                  </div>
                </Col>
              </Row>
            </Form>
            <Toaster position="bottom-right" reverseOrder={false} />
            <Row>
              <Col lg>
                <div className="contact-info">
                  <h4>{lang === "cs" ? "Provozovatel" : "Operator"}</h4>
                  <p>Profibüro s. r. o.</p>
                  <p>IČ: 037&nbsp;32&nbsp;746</p>
                  <p>DIČ: CZ03732746</p>
                  <p>Jir&aacute;skova&nbsp;121/11, 460&nbsp;01, Liberec</p>
                  <p>
                    <a
                      href="mailto:info@profiburo.cz"
                      className="hover-underline-animation"
                    >
                      info@profiburo.cz
                    </a>
                  </p>
                  <p>
                    <a
                      href="tel:+420 777 557 089"
                      className="hover-underline-animation"
                    >
                      +420&nbsp;777&nbsp;557&nbsp;089
                    </a>
                  </p>
                </div>
              </Col>
              <Col lg>
                <div className="contact-info">
                  <h4>Hotel</h4>
                  <p>Wellness Hotel Edelweiss</p>
                  <p>
                    Za&nbsp;Domovem&nbsp;333, Liberec - Horní&nbsp;Hanychov,
                    460&nbsp;08, Česká&nbsp;Republika
                  </p>
                  <p>
                    <a
                      href="mailto:info@hoteledelweiss.cz"
                      className="hover-underline-animation"
                    >
                      info@hoteledelweiss.cz
                    </a>
                  </p>
                  <p>
                    <a
                      href="tel:+420 777 336 810"
                      className="hover-underline-animation"
                    >
                      +420&nbsp;777&nbsp;336&nbsp;810
                    </a>
                  </p>
                  <Row className="mt-3">
                    <div className="fb-link">
                      <a
                        href="https://www.facebook.com/profile.php?id=100086427892621"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook:{" "}
                        <ion-icon
                          name="logo-facebook"
                          style={{ width: "30px", height: "30px" }}
                        ></ion-icon>
                      </a>
                    </div>
                    <div className="fb-link">
                      <a
                        href="https://www.instagram.com/wellness_hotel_edelweiss/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram:{" "}
                        <ion-icon
                          name="logo-instagram"
                          style={{ width: "30px", height: "30px" }}
                        ></ion-icon>
                      </a>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col lg={5} className="ps-lg-0">
                <div className="contact-info">
                  <h4>
                    {lang === "cs"
                      ? "Kde nás najdete?"
                      : lang === "en"
                      ? "Where to find us?"
                      : "Wo finden Sie uns?"}
                  </h4>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8872.52074185214!2d15.012601879809901!3d50.73879416236817!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470937f9a214a88b%3A0x269b03a29e711add!2sWellness%20Hotel%20Edelweiss!5e0!3m2!1scs!2seg!4v1660548764724!5m2!1scs!2seg"
                    width="100%"
                    height="300"
                    allowfullscreen=""
                    loading="lazy"
                    className="hotel-map"
                    referrerpolicy="no-referrer-when-downgrade"
                    title="google_maps"
                  ></iframe>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <a
                  href="https://www.firmy.cz/detail/13372709-wellness-hotel-edelweiss-liberec-xix-horni-hanychov.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-3"
                >
                  <img
                    width="197px"
                    src="https://www.firmy.cz/img/widgets/logo-svetle-pozadi-text.svg"
                    alt="Wellness Hotel Edelweiss na Firmy.cz"
                  />
                </a>
              </Col>
              <Col></Col>
              {lang === "cs" ? (
                <Col>
                  <p>
                    Sdílejte Váš pobyt na sociálních sítích:{" "}
                    <span className="anchor-ref">#wellnesshoteledelweiss</span>
                  </p>
                </Col>
              ) : (
                <Col>
                  <p>
                    Share your stay on social networks:{" "}
                    <span className="anchor-ref">#wellnesshoteledelweiss</span>
                  </p>
                </Col>
              )}
            </Row>
          </Container>
        </Layout>
      </Fade>
    </>
  );
};

export default Contact;
