import React from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Route, Switch } from "react-router";
import { ApplicationProvider } from "./providers/ApplicationProvider";
import Home from "./components/Home";
import NotFound from "./components/pages/NotFound";
import ChangePassword from "./components/admin/Auth/ChangePassword";
import ForgotPassword from "./components/admin/Auth/ForgotPassword";
import LoginPage from "./components/admin/Auth/LoginPage";
import ResetPassword from "./components/admin/Auth/ResetPassword";
import AccessDenied from "./components/pages/AccessDenied";
import AdminProfile from "./components/admin/AdminProfile";
import SpravceProfile from "./components/admin/SpravceProfile";
import RecepceProfile from "./components/admin/RecepceProfile";
import Podminky from "./components/Podminky";
import Contact from "./components/Contact";
import Hotel from "./components/Hotel";
import Restaurant from "./components/Restaurant";
import Wellness from "./components/Wellness";
import Surroundings from "./components/Surroundings";
import FoodByType from "./components/admin/Food/FoodByType";
import FoodAdd from "./components/admin/Food/FoodAdd";
import FoodEdit from "./components/admin/Food/FoodEdit";
import Reservation from "./components/Reservation";
import Help from "./components/admin/Help";
import DailyMenu from "./components/admin/Food/DailyMenu";
import WelcomeCard from "./components/admin/Welcome/WelcomeCard";
import WelcomeCardList from "./components/admin/Welcome/WelcomeCardList";
import MinibarList from "./components/admin/Food/MinibarList";
import SeasonMenuList from "./components/admin/Food/SeasonMenuList";
import DrinkList from "./components/admin/Food/DrinkList";
import Wedding from "./components/Wedding";
import RecepceProfileActions from "./components/admin/Wedding/FoodByType";
import ActionsAdd from "./components/admin/Wedding/FoodAdd";
import ActionEdit from "./components/admin/Wedding/FoodEdit";
import ImageUpload from "./components/admin/Wedding/ImageUpload";
import ImageEdit from "./components/admin/Wedding/ImageEdit";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import "./components/styles/style.css";
import "./components/styles/typography.css";
import "./components/styles/sidebar.css";
import "./components/styles/fonts.css";
import FoodListActions from "./components/admin/Wedding/FoodList";
import ThanksPage from "./components/ThanskPage";
import { CookiesProvider } from "react-cookie";
import QRcodes from "./components/admin/QRcodes";
import MenuSelect from "./components/QRCodes_Menu";
import GalleryListView from "./components/GalleryList";
import Gallery from "./components/Gallery";
import GalleryListAdmin from "./components/admin/Gallery/GalleryListAdmin";
import GalleryListOfCategory from "./components/admin/Gallery/GalleryListOfCategory";
import EditCategoryGallery from "./components/admin/Gallery/EditCategoryGallery";
import AddCategoryGall from "./components/admin/Gallery/AddCategoryGall";
import GalleryUpload from "./components/admin/Gallery/GalleryUpload";
import DomovDailyMenu from "./components/admin/Food/DomovDailyMenu";

function App() {
  const history = useHistory();

  return (
    <div className="App">
      <CookiesProvider>
        <ApplicationProvider>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/hotel" component={Hotel} />
              <Route exact path="/reservation" component={Reservation} />
              <Route exact path="/restaurant" component={Restaurant} />
              <Route
                exact
                path="/restaurant/menu/select"
                component={MenuSelect}
              />
              <Route exact path="/wellness" component={Wellness} />
              <Route exact path="/actions" component={Wedding} />
              <Route exact path="/gallery" component={GalleryListView} />
              <Route exact path="/gallery/:id" component={Gallery} />
              <Route exact path="/thank-you" component={ThanksPage} />
              <Route exact path="/surroundings" component={Surroundings} />
              <Route exact path="/terms-conditions" component={Podminky} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/admin/profile" component={AdminProfile} />
              <Route exact path="/admin/qr-code" component={QRcodes} />
              <Route
                exact
                path="/recepce/actions"
                component={RecepceProfileActions}
              />
              <Route
                exact
                path="/recepce/actions/list"
                component={FoodListActions}
              />
              <Route
                exact
                path="/recepce/actions/edit/:id"
                component={ActionEdit}
              />
              <Route exact path="/recepce/actions/add" component={ActionsAdd} />
              <Route
                exact
                path="/recepce/actions/image/upload/:id"
                component={ImageUpload}
              />
              <Route
                exact
                path="/recepce/actions/image/edit/:id"
                component={ImageEdit}
              />
              <Route exact path="/admin/welcome-card" component={WelcomeCard} />
              <Route
                exact
                path="/admin/gallery-list/type"
                component={GalleryListAdmin}
              />
              <Route
                exact
                path="/admin/gallery-list/type/add"
                component={AddCategoryGall}
              />
              <Route
                exact
                path="/admin/gallery-list/type/:id"
                component={GalleryListOfCategory}
              />
              <Route
                exact
                path="/admin/gallery-list/type/:id/upload"
                component={GalleryUpload}
              />
              <Route
                exact
                path="/admin/gallery-list/type/:id/edit"
                component={EditCategoryGallery}
              />
              <Route
                exact
                path="/admin/welcome-card/list"
                component={WelcomeCardList}
              />
              <Route
                exact
                path="/admin/domov-daily-menu/list"
                component={DomovDailyMenu}
              />
              <Route
                exact
                path="/admin/daily-menu/list"
                component={DailyMenu}
              />
              <Route exact path="/admin/minibar/list" component={MinibarList} />
              <Route
                exact
                path="/admin/season-menu/list"
                component={SeasonMenuList}
              />
              <Route
                exact
                path="/admin/drink-list/list"
                component={DrinkList}
              />
              <Route exact path="/admin/help" component={Help} />
              <Route exact path="/spravce/profile" component={SpravceProfile} />
              <Route exact path="/recepce/profile" component={RecepceProfile} />
              <Route exact path="/admin/food/list/:id" component={FoodByType} />
              <Route exact path="/admin/food/:id/add" component={FoodAdd} />
              <Route exact path="/admin/food/:id/edit" component={FoodEdit} />
              <Route exact path="/password/forgot" component={ForgotPassword} />
              <Route
                exact
                path="/password/change/:id"
                component={ChangePassword}
              />
              <Route path="/password/reset" component={ResetPassword} />
              <Route exact path="/access/denied" component={AccessDenied} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </ApplicationProvider>
      </CookiesProvider>
    </div>
  );
}

export default App;
