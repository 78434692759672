import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import LayoutOther from "../../layout/LayoutOther";
import { Container, Label, Input } from "reactstrap";

const AddCategoryGall = () => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      titleCZ: "",
      titleDE: "",
      titleEN: "",
      descriptionCZ: "",
      descriptionDE: "",
      descriptionEN: "",
      position: 10,
    },
    onSubmit: (values) => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/gallery/upload/gallery`,
          {
            titleCZ: values.titleCZ,
            titleDE: values.titleDE,
            titleEN: values.titleEN,
            DescriptionCZ: values.descriptionCZ,
            DescriptionDE: values.descriptionDE,
            DescriptionEN: values.descriptionEN,
            position: values.position,
          }
        )
        .then((res) => {
          history.push(`/admin/gallery-list/type/${res.data}/upload`);
        });
    },
  });
  return (
    <>
      <LayoutOther>
        <Container>
          <div className="admin-content">
            <h2>Přidat kategorii galerie - {formik.values.titleCZ}</h2>
            <Link to={`/admin/gallery-list/type`}>
              <button className="login-button">Zpět</button>
            </Link>
            <form onSubmit={formik.handleSubmit}>
              <Label className="mt-3" htmlFor="titleCZ">
                Název CS
              </Label>
              <Input
                id="titleCZ"
                name="titleCZ"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.titleCZ}
              />
              <Label className="mt-3" htmlFor="titleDE">
                Název DE
              </Label>
              <Input
                id="titleDE"
                name="titleDE"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.titleDE}
              />
              <Label className="mt-3" htmlFor="titleEN">
                Název EN
              </Label>
              <Input
                id="titleEN"
                name="titleEN"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.titleEN}
              />
              <Label className="mt-3" htmlFor="descriptionCZ">
                Popis CS
              </Label>
              <Input
                id="descriptionCZ"
                name="descriptionCZ"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.descriptionCZ}
              />
              <Label className="mt-3" htmlFor="descriptionDE">
                Popis DE
              </Label>
              <Input
                id="descriptionDE"
                name="descriptionDE"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.descriptionDE}
              />
              <Label className="mt-3" htmlFor="descriptionEN">
                Popis EN
              </Label>
              <Input
                id="descriptionEN"
                name="descriptionEN"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.descriptionEN}
              />
              <button className="login-button mt-3 mb-5" type="submit">
                Přidat
              </button>
            </form>
          </div>
        </Container>
      </LayoutOther>
    </>
  );
};

export default AddCategoryGall;
