import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import Layout from "./layout/Layout";
import { Link } from "react-router-dom";

const ThanksPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Wellness Hotel Edelweiss | Děkujeme</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Děkujeme"
        />
        <link
          rel="canonical"
          href="https://www.hoteledelweiss.cz/cookies"
        ></link>
        <script
          type="text/javascript"
          src="https://c.seznam.cz/js/rc.js"
        ></script>
        <script>
          {`{
        var conversionConf = {
          id: 100164352,
          value: null,
        };

        if (window.rc && window.rc.conversionHit) {
          window.rc.conversionHit(conversionConf);
        }}`}
        </script>
        <script>
          {`{
            gtag('event', 'conversion', {'send_to': 'AW-11046395837/OGAgCIueq4UYEL2_qpMp'});
          }`}
        </script>
      </Helmet>
      <Fade>
        <Layout>
          <Container className="contact-page">
            <Row>
              <Col md={6} className="p-0 well-rest-card">
                <div className="restaurant-text">
                  <h3>
                    Děkujeme!
                    <span></span>
                  </h3>
                  <>
                    <p>
                      Děkujeme za poslání Vaší zprávy. Brzy Vás budeme
                      kontaktovat.
                    </p>
                  </>
                  <div className="well-rest-footer">
                    <Link className="icon-text" to="/">
                      <button className="rest-button">HOME</button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={6} className="p-0 m-0"></Col>
            </Row>
          </Container>
        </Layout>
      </Fade>
    </>
  );
};

export default ThanksPage;
