import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useFormik, FormikProvider } from "formik";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../../providers/ApplicationProvider";
import toast, { Toaster } from "react-hot-toast";
import "../../styles/style.css";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "";
  }
  if (!values.password) {
    errors.password = "";
  }
  return errors;
};

const LoginPage = () => {
  const history = useHistory();
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [error, setError] = useState(false);

  const notifyError = () =>
    toast.error("Někde se stala chyba, zkuste to znovu nebo nám napište.");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      })
      .then(() => {
        if (role === "ADMIN") return history.push("/admin/profile");
        if (role === "SPRAVCE") return history.push("/spravce/profile");
        if (role === "RECEPCE") return history.push("/recepce/profile");
      });
  }, [dispatch, accessToken, role, history]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: validate,
    onSubmit: (values) => {
      setError(false);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/Account/login`,
          {
            email: values.email,
            password: values.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: SET_ACCESS_TOKEN,
            payload: response.data.accessToken,
          });
        })
        .catch(() => {
          setError(true);
          notifyError();
        })
        .then(() => {
          if (role === "ADMIN") return history.push("/admin/profile");
          if (role === "SPRAVCE") return history.push("/spravce/profile");
          if (role === "RECEPCE") return history.push("/recepce/profile");
        });
    },
  });

  return (
    <body className="login">
      <Container>
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <FormikProvider value={formik}>
            <Card className="col-lg-6 p-3 mb-5 bg-white login-form">
              <CardBody className="bg-white">
                <h3 className="mb-4">Přihlásit se</h3>
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup className="m-2">
                    <Label for="email">E-mailová adresa</Label>
                    <Input
                      name="email"
                      id="email"
                      type="email"
                      placeholder="jannovak@email.cz"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      invalid={Boolean(formik.errors.email)}
                      valid={formik.touched.email}
                    />
                    {formik.errors.email ? (
                      <FormFeedback invalid>{formik.errors.email}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="m-2 mb-3">
                    <Label for="password">Heslo</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Heslo"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      invalid={Boolean(formik.errors.password)}
                      valid={formik.touched.password}
                    />
                    {formik.errors.password ? (
                      <FormFeedback invalid>
                        {formik.errors.password}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <Row>
                    <Col lg>
                      <div className="ms-2 mt-0">
                        <button type="submit" className="login-button">
                          Přihlásit&nbsp;se
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </FormikProvider>
          <Toaster position="bottom-right" reverseOrder={false} />
        </div>
      </Container>
    </body>
  );
};

export default LoginPage;
