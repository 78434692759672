import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import LayoutOther from "../../layout/LayoutOther";
import { Container, Label, Input } from "reactstrap"

const EditCategoryGallery = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/gallery/get/gallery/names/` +
          id
      )
      .then((res) => {
        setData(res.data)
      });
  }, [id]);

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: (values) => {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/gallery/edit/gallery/${id}`,
          {
            id: id,
            titleCZ: values.titleCZ,
            titleDE: values.titleDE,
            titleEN: values.titleEN,
            descriptionCZ: values.descriptionCZ,
            descriptionDE: values.descriptionDE,
            descriptionEN: values.descriptionEN,
            galleryTypeId: id,
            position: values.position,
          }
        )
        .then(() => {
          history.push(`/admin/gallery-list/type`);
        });
    },
  });
  return (
    <>
      <LayoutOther>
        <Container>
          <div className="admin-content">
            <h2>Upravit kategorii galerie</h2>
            <Link to={`/admin/gallery-list/type`}>
              <button className="login-button">Zpět</button>
            </Link>
            <form onSubmit={formik.handleSubmit}>
              <Label className="mt-3" htmlFor="titlecz">
                Název CS
              </Label>
              <Input
                id="titleCZ"
                name="titleCZ"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.titleCZ}
              />
              <Label className="mt-3" htmlFor="titlede">
                Název DE
              </Label>
              <Input
                id="titleDE"
                name="titleDE"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.titleDE}
              />
              <Label className="mt-3" htmlFor="titleen">
                Název EN
              </Label>
              <Input
                id="titleEN"
                name="titleEN"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.titleEN}
              />
              <Label className="mt-3" htmlFor="descriptionCZ">
                Popis CS
              </Label>
              <Input
                id="descriptionCZ"
                name="descriptionCZ"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.descriptionCZ}
              />
              <Label className="mt-3" htmlFor="descriptionDE">
              Popis DE
              </Label>
              <Input
                id="descriptionDE"
                name="descriptionDE"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.descriptionDE}
              />
              <Label className="mt-3" htmlFor="descriptionEN">
              Popis EN
              </Label>
              <Input
                id="descriptionEN"
                name="descriptionEN"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.descriptionEN}
              />
              <button className="login-button mt-3 mb-5" type="submit">
                Upravit
              </button>
            </form>
          </div>
        </Container>
      </LayoutOther>
    </>
  );
};

export default EditCategoryGallery;
