import React, { useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import LayoutOther from "../../layout/LayoutOther";
import { Container, Input, Label } from "reactstrap";
import axios from "axios";

const FoodAdd = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  let iid = parseInt(id, 10);

  const formik = useFormik({
    initialValues: {
      namecs: "",
      namede: "",
      nameen: "",
      price: 0,
      type: iid,
      position: 0,
      isActive: true,
      amount: "",
      descriptioncs: "",
      descriptionde: "",
      descriptionen: "",
    },
    onSubmit: (values) => {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Restaurant`, {
          nameCS: values.namecs,
          nameDE: values.namede,
          nameEN: values.nameen,
          descriptionCS: values.descriptioncs,
          descriptionDE: values.descriptionde,
          descriptionEN: values.descriptionen,
          price: values.price,
          type: values.type,
          position: values.position,
          isActive: values.isActive,
          amount: values.amount,
        })
        .then(() => {
          setDone(true);
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
          history.push(`/admin/food/list/${iid}`);
        });
    },
  });

  function renderTable() {
    return (
      <>
        <Label className="mt-3" htmlFor="namede">
          Název DE
        </Label>
        <Input
          id="namede"
          name="namede"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.namede}
        />
        <Label className="mt-3" htmlFor="nameen">
          Název EN
        </Label>
        <Input
          id="nameen"
          name="nameen"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.nameen}
        />
        <Label className="mt-3" htmlFor="descriptioncs">
          Popis CS
        </Label>
        <Input
          id="descriptioncs"
          name="descriptioncs"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.descriptioncs}
        />
        <Label className="mt-3" htmlFor="descriptionde">
          Popis DE
        </Label>
        <Input
          id="descriptionde"
          name="descriptionde"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.descriptionde}
        />
        <Label className="mt-3" htmlFor="descriptionen">
          Popis EN
        </Label>
        <Input
          id="descriptionen"
          name="descriptionen"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.descriptionen}
        />
        <Label className="mt-3" htmlFor="amount">
          Množství [g, ml, cl]
        </Label>
        <Input
          id="amount"
          name="amount"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.amount}
        />
      </>
    );
  }

  return (
    <LayoutOther>
      <Container>
        <div className="admin-content">
          <h2>{iid === 11 ? "Přidat do minibaru" : "Přidat jídlo"}</h2>
          <Link to={`/admin/food/list/${iid}`}>
            <button className="login-button">Zpět</button>
          </Link>
          <form onSubmit={formik.handleSubmit}>
            <Label className="mt-3" htmlFor="namecs">
              Název CS
            </Label>
            <Input
              id="namecs"
              name="namecs"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.namecs}
            />
            {iid === 11 ? null : renderTable()}
            <Label className="mt-3" htmlFor="price">
              Cena
            </Label>
            <Input
              id="price"
              name="price"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.price}
            />
            <button className="login-button mt-3" type="submit">
              Přidat
            </button>
          </form>
        </div>
      </Container>
    </LayoutOther>
  );
};

export default FoodAdd;
