import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../providers/ApplicationProvider";
import LayoutOther from "./layout/LayoutContact";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import axios from "axios";
import { Fade } from "react-awesome-reveal";
import Loading from "./pages/Loading";
import { Helmet } from "react-helmet";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);
  const [{ lang }] = useContext(ApplicationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/gallery/get/gallery`)
      .then((res) => {
        setImageUrl(res.data);
        setLoading(false);
      });
  }, []);

  function renderCards() {
    return imageUrl.map((item, index) => {
      return (
        <>
          <Col lg={4} md={6} sm={12} className="d-flex align-items-stretch mb-3">
            <Card className="card-content-normal-white mt-3 mt-md-5 mb-lg-3 h-100">
              <img className="gallery" src={`${item.src}`} />
              <CardBody className="text-center">
                <CardTitle>
                  <h4 className="white-card">
                    {lang === "cs"
                      ? item.titleCZ
                      : lang === "en"
                      ? item.titleEN
                      : item.titleDE}
                  </h4>
                </CardTitle>
              </CardBody>
              <CardBody className="d-flex flex-column">
                <CardText>
                  <p className="white-card">
                    {lang === "cs"
                      ? item.descriptionCZ
                      : lang === "en"
                      ? item.descriptionEN
                      : item.descriptionDE}
                  </p>
                </CardText>
              </CardBody>
              <div className="card-footer-btn">
                <Link className="icon-text" to={`/gallery/${item.id}`}>
                  <button className="rest-button">
                    {lang === "cs" ? "VÍCE" : lang === "en" ? "MEHR" : "MORE"}
                  </button>
                </Link>
              </div>
            </Card>
          </Col>
        </>
      );
    });
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!loading) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <title>Wellness Hotel Edelweiss | Galerie</title>
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Galerie"
          />
          <link
            rel="canonical"
            href="https://www.hoteledelweiss.cz/gallery"
          ></link>
          <meta itemprop="name" content="Wellness Hotel Edelweiss | Galerie" />
          <meta
            itemprop="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta
            itemprop="image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta property="og:url" content="https://hoteledelweiss.cz/gallery" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Galerie"
          />
          <meta
            property="og:description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta
            property="og:image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Wellness Hotel Edelweiss | Galerie"
          />
          <meta
            name="twitter:description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta
            name="twitter:image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta
            name="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta itemprop="name" content="Wellness Hotel Edelweiss | Galerie" />
          <meta
            itemprop="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
        </Helmet>
        <Fade>
          <LayoutOther>
            <div className="break">{""}</div>
            <h1>
              {lang === "cs"
                ? "GALERIE"
                : lang === "en"
                ? "GALLERY"
                : "GALERIE"}
            </h1>
            <br></br>
            <div className="cards gallery container-lg">
              <div className="card-content">
                <Row className="mt-1 mb-1 mb-md-3 text-center">
                  {renderCards()}
                </Row>
              </div>
            </div>
          </LayoutOther>
        </Fade>
      </>
    );
  }
};

export default Gallery;
