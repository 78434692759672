import React, { useContext, useState } from "react";
import LayoutOther from "../layout/LayoutOther";
import { ApplicationContext } from "../../providers/ApplicationProvider";
import AccessDenied from "../pages/AccessDenied";
import axios from "axios";
import QRCode from "react-qr-code";

import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";

const QRcodes = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  var history = useHistory();

  const send = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/mergePDF`);
  };

  if (!accessToken) {
    history.push("/");
    return (
      <>
        <AccessDenied />
      </>
    );
  } else if (accessToken) {
    return (
      <>
        <LayoutOther className="text-center">
          <div className="mt-5 pt-5">
            <h1>Vygenerovat celé menu</h1>
            <div className="d-flex justify-content-around">
              <button
                className="rest-button qr"
                type="submit"
                onClick={() => send()}
              >
                Vytvořit menu na QR kody
              </button>
            </div>
            <div className="d-flex justify-content-around">
              <a
                href="https://hoteledelweiss.cz/QRcodes/CZ/Menu.html"
                target="_blank"
              >
                <button className="rest-button">ČESKÉ MENU</button>
              </a>
              <a
                href="https://hoteledelweiss.cz/QRcodes/EN/Menu.html"
                target="_blank"
              >
                <button className="rest-button">ANGLICKÉ MENU</button>
              </a>
              <a
                href="https://hoteledelweiss.cz/QRcodes/DE/Menu.html"
                target="_blank"
              >
                <button className="rest-button">NĚMECKÉ MENU</button>
              </a>
            </div>
            <div className="d-flex justify-content-around">
              <div style={{ background: "white", padding: "16px" }}>
                <button className="qr-code">
                  <a href="https://hoteledelweiss.cz/QRcodes/CZ/CZ_qr-code.jpg">
                    <QRCode value="https://hoteledelweiss.cz/QRcodes/CZ/Menu.html" />
                  </a>
                </button>
              </div>
              <div style={{ background: "white", padding: "16px" }}>
                <button className="qr-code">
                  <a href="https://hoteledelweiss.cz/QRcodes/EN/EN_qr-code.jpg">
                    <QRCode value="https://hoteledelweiss.cz/QRcodes/EN/Menu.html" />
                  </a>
                </button>
              </div>
              <div style={{ background: "white", padding: "16px" }}>
                <button className="qr-code">
                  <a href="https://hoteledelweiss.cz/QRcodes/DE/DE_qr-code.jpg">
                    <QRCode value="https://hoteledelweiss.cz/QRcodes/DE/Menu.html" />
                  </a>
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center">
            <a
                href="https://hoteledelweiss.cz/restaurant/menu/select"
                target="_blank"
              >
                <button className="rest-button">ROZDĚLOVNÍK MENU NA WEBU</button>
              </a>
              <div style={{ background: "white", padding: "16px" }}>
                <button className="qr-code">
                  <a href="https://hoteledelweiss.cz/QRcodes/ROZDELOVNIK_QR.jpg">
                    <QRCode value="https://hoteledelweiss.cz/restaurant/menu/select" />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </LayoutOther>
      </>
    );
  }
};

export default QRcodes;
