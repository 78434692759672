import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import Loading from "./Loading";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";

const AccessDenied = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
  }, [accessToken, dispatch]);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Container className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Card className="denied p-3 mb-5 col-sm-6">
            <CardBody>
              <CardTitle tag="h3" className="text-white">
                Na tuto stránku nemáte dostatečná práva!
              </CardTitle>
              <Row>
                <Col>
                  <Link to="/">
                    <button
                      className="mt-5 mb-3 login-button"
                    >
                      Zpět&nbsp;na&nbsp;hlavní&nbsp;stránku
                    </button>
                  </Link>
                </Col>
                <Col>
                  <Link to="/login">
                    <button
                      className="mt-5 mb-3 login-button"
                    >
                      Přihlásit se
                    </button>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
};

export default AccessDenied;
