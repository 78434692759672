import React, { useEffect, useContext, useState } from "react";
import {
  ApplicationContext,
  SET_LANG,
} from "../../providers/ApplicationProvider";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import { Turn as Hamburger } from "hamburger-react";
import Logo from "../../assets/images/logo-nav-01.svg";
import Logo2 from "../../assets/images/logo-nav-w-01.svg";
import "../styles/typography.css";
import "../styles/sidebar.css";

const Navmenu = () => {
  const [{ lang }, dispatch] = useContext(ApplicationContext);
  const [page, setPage] = useState("/");

  useEffect(() => {
    let no = window.localStorage.getItem("lang");
    if (no === null) {
      no = "cs";
    }
    dispatch({ type: SET_LANG, payload: no });
  }, [dispatch]);

  useEffect(() => {
    window.localStorage.setItem("lang", lang);
  }, [lang]);

  function setLangSel(option) {
    dispatch({ type: SET_LANG, payload: option });
  }

  function blockit(boolHamb) {
    var cover = document.getElementById("cover");

    if (boolHamb) {
      cover.classList.add("covering");
    } else if (!boolHamb) {
      cover.classList.remove("covering");
    }
  }

  const [hamb, setHamb] = useState(false);

  useEffect(() => {
    blockit(hamb);
  }, [hamb]);

  useEffect(() => {
    setPage(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <header>
        <nav className={`sidebar ${hamb ? "active" : ""}`}>
          {hamb ? (
            <Link to="/">
              <div className="logo-hamb">
                <img
                  src={Logo2}
                  alt="Wellness Hotel Edelweiss - Liberec pod Ještědem"
                  height="50px"
                  width="auto"
                />
              </div>
            </Link>
          ) : (
            ""
          )}
          <ul class={`sidebar-list ${hamb ? "active" : ""}`}>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/"
                tag={Link}
              >
                {page === "/" ? <strong>HOME</strong> : "HOME"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/hotel"
                tag={Link}
              >
                {page === "/hotel" ? <strong>HOTEL</strong> : "HOTEL"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/restaurant"
                tag={Link}
              >
                {page === "/restaurant" ? (
                  <strong>{lang === "cs" ? "RESTAURACE" : "RESTAURANT"}</strong>
                ) : lang === "cs" ? (
                  "RESTAURACE"
                ) : lang === "en" ? (
                  "RESTAURANT"
                ) : (
                  "RESTAURANT"
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/restaurant#garden-restaurant"
                tag={Link}
              >
                {window.location.hash === "#garden-restaurant" ? (
                  <strong>
                    {lang === "cs"
                      ? "ZAHRADNÍ RESTAURACE"
                      : lang === "en"
                      ? "GARDEN RESTAURANT"
                      : "GARTENRESTAURANT"}
                  </strong>
                ) : lang === "cs" ? (
                  "ZAHRADNÍ RESTAURACE"
                ) : lang === "en" ? (
                  "GARDEN RESTAURANT"
                ) : (
                  "GARTENRESTAURANT"
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/wellness"
                tag={Link}
              >
                {page === "/wellness" ? <strong>WELLNESS</strong> : "WELLNESS"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/gallery"
                tag={Link}
              >
                {page.includes("/gallery") ? (
                  <strong>
                    {lang === "cs"
                      ? "GALERIE"
                      : lang === "en"
                      ? "GALLERY"
                      : "GALERIE"}
                  </strong>
                ) : lang === "cs" ? (
                  "GALERIE"
                ) : lang === "en" ? (
                  "GALLERY"
                ) : (
                  "GALERIE"
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/actions"
                tag={Link}
              >
                {page === "/actions" ? (
                  <strong>
                    {lang === "cs"
                      ? "AKCE"
                      : lang === "en"
                      ? "ACTIONS"
                      : "AKTION"}
                  </strong>
                ) : lang === "cs" ? (
                  "AKCE"
                ) : lang === "en" ? (
                  "ACTIONS"
                ) : (
                  "AKTION"
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation"
                to="/surroundings"
                tag={Link}
              >
                {page === "/surroundings" ? (
                  <strong>
                    {lang === "cs"
                      ? "OKOLÍ"
                      : lang === "en"
                      ? "SURROUNDINGS"
                      : "UMGEBUNG"}
                  </strong>
                ) : lang === "cs" ? (
                  "OKOLÍ"
                ) : lang === "en" ? (
                  "SURROUNDINGS"
                ) : (
                  "UMGEBUNG"
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation sidebar-anchor"
                to="/contact"
                tag={Link}
              >
                {page === "/contact" ? (
                  <strong>
                    {lang === "cs"
                      ? "KONTAKT"
                      : lang === "en"
                      ? "CONTACT"
                      : "KONTAKT"}
                  </strong>
                ) : lang === "cs" ? (
                  "KONTAKT"
                ) : lang === "en" ? (
                  "CONTACT"
                ) : (
                  "KONTAKT"
                )}
              </NavLink>
            </NavItem>
            <NavItem className="devider">
              <hr className="devider-sidebar"></hr>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation lang"
                onClick={() => setLangSel("cs")}
              >
                {lang === "cs" ? <strong>CS</strong> : "CS"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="hover-underline-animation lang"
                onClick={() => setLangSel("en")}
              >
                {lang === "en" ? <strong>EN</strong> : "EN"}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className="hover-underline-animation lang"
                onClick={() => setLangSel("de")}
              >
                {lang === "de" ? <strong>DE</strong> : "DE"}
              </NavLink>
            </NavItem>
          </ul>
        </nav>
        <nav
          className={`colorChange navbar-color ${
            hamb ? "" : "active"
          } top-nav fixed-top`}
        >
          <Link to="/">
            <div className="left">
              <img
                src={Logo}
                alt="Wellness Hotel Edelweiss - Liberec pod Ještědem"
                height="20px"
                width="auto"
              />
            </div>
          </Link>
          <Hamburger
            direction="right"
            className={`hamburger-react`}
            rounded
            color="#cb8e00"
            duration={0.5}
            toggle={setHamb}
            toggled={hamb}
            size={45}
          />
          <div className={`nav-mob ${!hamb ? "active" : ""}`}>
            <div className={`logo-hamb mob ${hamb ? "" : "active"}`}>
              <Link to="/">
                <img
                  src={Logo}
                  alt="Wellness Hotel Edelweiss - Liberec pod Ještědem"
                  height="50px"
                  width="auto"
                />
              </Link>
            </div>
          </div>
          <ul className={`navbar-nav mr-auto mt-lg-0`}>
            <div className="center">
              <NavItem>
                <NavLink
                  className="hover-underline-animation"
                  to="/"
                  tag={Link}
                >
                  {page === "/" ? <strong>HOME</strong> : "HOME"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="hover-underline-animation"
                  to="/hotel"
                  tag={Link}
                >
                  {page === "/hotel" ? <strong>HOTEL</strong> : "HOTEL"}
                </NavLink>
              </NavItem>
              <NavItem className="restaurant-nav-item">
                <NavLink
                  className="hover-underline-animation arrow-navlink"
                  to="/restaurant"
                  tag={Link}
                >
                  {page === "/restaurant" ? (
                    <strong>
                      {lang === "cs" ? "RESTAURACE" : "RESTAURANT"}
                    </strong>
                  ) : lang === "cs" ? (
                    "RESTAURACE"
                  ) : lang === "en" ? (
                    "RESTAURANT"
                  ) : (
                    "RESTAURANT"
                  )}
                  <ion-icon name="caret-down-sharp"></ion-icon>
                </NavLink>
                <NavLink
                  className="restaurant-outside hover-underline-animation"
                  to="/restaurant#garden-restaurant"
                  tag={Link}
                >
                  {window.location.hash === "#garden-restaurant" ? (
                    <strong>
                      {lang === "cs"
                        ? "ZAHRADNÍ RESTAURACE"
                        : lang === "en"
                        ? "GARDEN RESTAURANT"
                        : "GARTENRESTAURANT"}
                    </strong>
                  ) : lang === "cs" ? (
                    "ZAHRADNÍ RESTAURACE"
                  ) : lang === "en" ? (
                    "GARDEN RESTAURANT"
                  ) : (
                    "GARTENRESTAURANT"
                  )}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="hover-underline-animation"
                  to="/wellness"
                  tag={Link}
                >
                  {page === "/wellness" ? (
                    <strong>WELLNESS</strong>
                  ) : (
                    "WELLNESS"
                  )}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="hover-underline-animation sidebar-anchor"
                  to="/gallery"
                  tag={Link}
                >
                  {page.includes("/gallery") ? (
                    <strong>
                      {lang === "cs"
                        ? "GALERIE"
                        : lang === "en"
                        ? "GALLERY"
                        : "GALERIE"}
                    </strong>
                  ) : lang === "cs" ? (
                    "GALERIE"
                  ) : lang === "en" ? (
                    "GALLERY"
                  ) : (
                    "GALERIE"
                  )}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="hover-underline-animation sidebar-anchor"
                  to="/actions"
                  tag={Link}
                >
                  {page === "/actions" ? (
                    <strong>
                      {lang === "cs"
                        ? "AKCE"
                        : lang === "en"
                        ? "ACTIONS"
                        : "AKTION"}
                    </strong>
                  ) : lang === "cs" ? (
                    "AKCE"
                  ) : lang === "en" ? (
                    "ACTIONS"
                  ) : (
                    "AKTION"
                  )}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="hover-underline-animation"
                  to="/surroundings"
                  tag={Link}
                >
                  {page === "/surroundings" ? (
                    <strong>
                      {lang === "cs"
                        ? "OKOLÍ"
                        : lang === "en"
                        ? "SURROUNDINGS"
                        : "UMGEBUNG"}
                    </strong>
                  ) : lang === "cs" ? (
                    "OKOLÍ"
                  ) : lang === "en" ? (
                    "SURROUNDINGS"
                  ) : (
                    "UMGEBUNG"
                  )}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="hover-underline-animation"
                  to="/contact"
                  tag={Link}
                >
                  {page === "/contact" ? (
                    <strong>
                      {lang === "cs"
                        ? "KONTAKT"
                        : lang === "en"
                        ? "CONTACT"
                        : "KONTAKT"}
                    </strong>
                  ) : lang === "cs" ? (
                    "KONTAKT"
                  ) : lang === "en" ? (
                    "CONTACT"
                  ) : (
                    "KONTAKT"
                  )}
                </NavLink>
              </NavItem>
            </div>
            <div className="right">
              <NavItem>
                <NavLink
                  className="hover-underline-animation lang"
                  onClick={() => setLangSel("cs")}
                >
                  {lang === "cs" ? <strong>CS</strong> : "CS"}
                </NavLink>
              </NavItem>
              <NavItem className="navbar-navll">|</NavItem>

              <NavItem>
                <NavLink
                  className="hover-underline-animation lang"
                  onClick={() => setLangSel("en")}
                >
                  {lang === "en" ? <strong>EN</strong> : "EN"}
                </NavLink>
              </NavItem>

              <NavItem className="navbar-navll">|</NavItem>

              <NavItem>
                <NavLink
                  className="hover-underline-animation lang"
                  onClick={() => setLangSel("de")}
                >
                  {lang === "de" ? <strong>DE</strong> : "DE"}
                </NavLink>
              </NavItem>
            </div>
          </ul>
        </nav>
      </header>
      <div id="cover"></div>
    </>
  );
};

export default Navmenu;
