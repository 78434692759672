import React, { useState, useContext, useEffect } from "react";
import { Range, getTrackBackground } from "react-range";
import {
  ApplicationContext,
  SET_SLIDER,
} from "../providers/ApplicationProvider";

const Slider = ({ max, index }) => {
  const STEP = 1;
  const MIN = 0;
  const MAX = max;

  const [values, setValues] = useState([MIN]);
  const [{ slider }, dispatch] = useContext(ApplicationContext);

  useEffect(() => {
    dispatch({
      type: SET_SLIDER,
      index: index,
      payload: values[0],
    });
  }, [values]);

  var widthSlider = "calc(100% / " + max + ")";

  return (
    <>
      <div className="slider-container">
        <ion-icon name="chevron-back-sharp"></ion-icon>
        <p className="divider-slider">
          <strong>|</strong>
        </p>
        <ion-icon name="chevron-forward-sharp"></ion-icon>
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => setValues(values)}
          renderTrack={({ props, children }) => (
            <div
              className="slider-c"
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "15px",
                display: "flex",
                width: "70%",
                position: "absolute",
                bottom: "1rem",
                zIndex: "600",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "3px",
                  width: "100%",
                  background: getTrackBackground({
                    values: values,
                    colors: ["#8D9C7D", "#ccc"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "10px",
                width: widthSlider,
                backgroundColor: "var(--yellow)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 0rem 1rem #AAA",
              }}
            >
              <div
                style={{
                  height: "5px",
                  width: "5px",
                  backgroundColor: "var(--yellow)",
                }}
              />
            </div>
          )}
        />
      </div>
    </>
  );
};

export default Slider;
