import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  useMemo,
} from "react";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import axios from "axios";
import NavmenuOther from "../../layout/NavmenuOther";
import LayoutOther from "../../layout/LayoutOther";
import AccessDenied from "../../pages/AccessDenied";
import Loading from "../../pages/Loading";
import styled from "styled-components";
import { useTable } from "react-table";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

const Styles = styled.div`
  padding: 1rem;

  table {
    transform: translateY(5rem);
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const MinibarList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [{ accessToken }] = useContext(ApplicationContext);

  const columns = useMemo(
    () => [
      {
        Header: "Název",
        accessor: "nameCS",
      },
      {
        Header: "",
        accessor: "download",
      },
      {
        Header: "",
        accessor: "delete",
      },
    ],

    []
  );

  const datas = useMemo(() => renderData(), [renderData()]);

  function renderData() {
    return response.map((item, index) => {
      return {
        nameCS: item.name,
        download: (
          <button
            className="login-button"
            refresh="true"
            onClick={() => open(item.name)}
          >
            OTEVŘÍT
          </button>
        ),
        delete: (
          <button
            className="login-button"
            refresh="true"
            onClick={() => {
              deleteProject(item.name, index);
            }}
          >
            Smazat
          </button>
        ),
      };
    });
  }

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/getActions`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessToken]);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  const removeItem = (index) => {
    response.splice(index, 1);
    setResponse([...response]);
  };

  function deleteProject(name, index) {
    setIsLoading(true);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/actions/${name}`)
      .then(() => {
        removeItem(index);
        setDone(true);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return response;
  }

  function openDoc(url) {
    var windowReferenced = window.open("_blank");
    windowReferenced.location = url;
  }

  function open(name) {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/actions/${name}`, {
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        openDoc(response.data);
      });
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (response) {
      return (
        <>
          <NavmenuOther />
          <LayoutOther>
            <Container>
              {response.length === 0 ? (
                <>
                  <h1 className="mt-5 pt-5">Nic tu není</h1>
                  <Link to="/recepce/actions/add">
                    <button className="login-button">
                      Vygeneruj jídla pro akce
                    </button>
                  </Link>
                </>
              ) : (
                <Styles>
                  <Table columns={columns} data={datas} />
                </Styles>
              )}
            </Container>
          </LayoutOther>
        </>
      );
    }
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default MinibarList;
