import React, { useContext, useEffect, useState } from "react";
import LayoutOther from "./layout/Layout";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from "reactstrap";
import { ApplicationContext } from "../providers/ApplicationProvider";
import KytkaWhite from "../assets/images/logo-white.svg";
import axios from "axios";
import { Fade } from "react-awesome-reveal";
import Loading from "./pages/Loading";
import { Helmet } from "react-helmet";

const Wedding = () => {
  const [{ lang }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [food, setFood] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/restaurant/getByType/22`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setFood(res.data);
      })
      .catch((err) => console.log(err))
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  function renderCards() {
    return food.map((item, index) => {
      return (
        <>
          <Col lg={4} className="d-flex align-items-stretch">
            <Card className="card-content-normal mt-3 mt-md-5">
              <img
                src={`/assets/images/${item.id}/${item.id}.jpeg`}
                alt="img"
              />
              <CardBody className="text-center">
                <CardTitle>
                  <h4>
                    {lang === "cs" ? (
                      <p>{item.nameCS}</p>
                    ) : lang === "en" ? (
                      <p>{item.nameEN}</p>
                    ) : (
                      <p>{item.nameDE}</p>
                    )}
                  </h4>
                </CardTitle>
              </CardBody>
              <CardBody className="d-flex flex-column">
                <CardText>
                  {lang === "cs" ? (
                    <p>{item.descriptionCS}</p>
                  ) : lang === "en" ? (
                    <p>{item.descriptionEN}</p>
                  ) : (
                    <p>{item.descriptionDE}</p>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </>
      );
    });
  }

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!isLoading) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Pomůžeme Vám zorganizovat svatbu, rodinnou oslavu,
                            firemní akci. Budeme rádi, když si pro svůj velký
                            den vyberete náš hotel. Připravíme kompletní
                            svatební menu podle vašich představ a přání.
                            Samozřejmostí je ubytování svatebních hostů.
                            Připravíme i novomanželský apartmán. Nabízíme také
                            fotozónu."
          />
          <title>Wellness Hotel Edelweiss | Akce/svatby/oslavy</title>
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Akce/svatby/oslavy"
          />
          <link
            rel="canonical"
            href="https://www.hoteledelweiss.cz/actions"
          ></link>
          <meta
            itemprop="name"
            content="Wellness Hotel Edelweiss | Akce/svatby/oslavy"
          />
          <meta
            itemprop="description"
            content="Pomůžeme Vám zorganizovat svatbu, rodinnou oslavu,
                            firemní akci. Budeme rádi, když si pro svůj velký
                            den vyberete náš hotel. Připravíme kompletní
                            svatební menu podle vašich představ a přání.
                            Samozřejmostí je ubytování svatebních hostů.
                            Připravíme i novomanželský apartmán. Nabízíme také
                            fotozónu."
          />
          <meta
            itemprop="image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta property="og:url" content="https://hoteledelweiss.cz/actions" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Akce/svatby/oslavy"
          />
          <meta
            property="og:description"
            content="Pomůžeme Vám zorganizovat svatbu, rodinnou oslavu,
                            firemní akci. Budeme rádi, když si pro svůj velký
                            den vyberete náš hotel. Připravíme kompletní
                            svatební menu podle vašich představ a přání.
                            Samozřejmostí je ubytování svatebních hostů.
                            Připravíme i novomanželský apartmán. Nabízíme také
                            fotozónu."
          />
          <meta
            property="og:image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Wellness Hotel Edelweiss | Akce/svatby/oslavy"
          />
          <meta
            name="twitter:description"
            content="Pomůžeme Vám zorganizovat svatbu, rodinnou oslavu,
                            firemní akci. Budeme rádi, když si pro svůj velký
                            den vyberete náš hotel. Připravíme kompletní
                            svatební menu podle vašich představ a přání.
                            Samozřejmostí je ubytování svatebních hostů.
                            Připravíme i novomanželský apartmán. Nabízíme také
                            fotozónu."
          />
          <meta
            name="twitter:image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta
            name="description"
            content="Pomůžeme Vám zorganizovat svatbu, rodinnou oslavu,
                            firemní akci. Budeme rádi, když si pro svůj velký
                            den vyberete náš hotel. Připravíme kompletní
                            svatební menu podle vašich představ a přání.
                            Samozřejmostí je ubytování svatebních hostů.
                            Připravíme i novomanželský apartmán. Nabízíme také
                            fotozónu."
          />
          <meta
            itemprop="name"
            content="Wellness Hotel Edelweiss | Akce/svatby/oslavy"
          />
          <meta
            itemprop="description"
            content="Pomůžeme Vám zorganizovat svatbu, rodinnou oslavu,
                            firemní akci. Budeme rádi, když si pro svůj velký
                            den vyberete náš hotel. Připravíme kompletní
                            svatební menu podle vašich představ a přání.
                            Samozřejmostí je ubytování svatebních hostů.
                            Připravíme i novomanželský apartmán. Nabízíme také
                            fotozónu."
          />
        </Helmet>
        <Fade>
          <LayoutOther>
            <div className="showcase-actions">
              <div className="showcase-content">
                <div className="showcase-hotel-table">
                  <Container>
                    <div className="welcome-text hotel-text col-md-8">
                      <h1>
                        {lang === "cs"
                          ? "AKCE / OSLAVY / SVATBY"
                          : lang === "en"
                          ? "ACTIONS / WEDDINGS / CELEBRATIONS"
                          : "VERANSTALTUNGEN / FEIERN / HOCHZEITEN"}
                      </h1>
                      {lang === "cs" ? (
                        <>
                          <p>
                            Pomůžeme Vám zorganizovat svatbu, rodinnou oslavu,
                            firemní akci. Budeme rádi, když si pro svůj velký
                            den vyberete náš hotel. Připravíme kompletní
                            svatební menu podle vašich představ a přání.
                            Samozřejmostí je ubytování svatebních hostů.
                            Připravíme i novomanželský apartmán. Nabízíme také
                            fotozónu.
                          </p>
                          <p>
                            Zde si můžete prohlédnout vybraná jídla, které Vám
                            můžeme nabídnout pro veškeré akce. <br></br>
                            <strong>
                              Pro více informací nás neváhejte kontaktovat.
                            </strong>
                          </p>
                        </>
                      ) : lang === "en" ? (
                        <>
                          <p>
                            We will help you organize a wedding, family
                            celebration, corporate event. We will be delighted
                            if you choose our hotel for your big day. We will
                            prepare a complete wedding menu according to your
                            ideas and wishes. Accommodation of wedding guests is
                            a matter of course. We will also prepare a honeymoon
                            suite. We also offer a photo zone.
                          </p>
                          <p>
                            Here you can view selected dishes that we can offer
                            you for all events.<br></br>
                            <strong>
                              Do not hesitate to contact us for more
                              information.
                            </strong>
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Wir helfen Ihnen bei der Organisation einer
                            Hochzeit, Familienfeier oder Firmenveranstaltung.
                            Wir freuen uns, wenn Sie unser Hotel für Ihren
                            großen Tag wählen. Wir bereiten für Sie ein
                            komplettes Hochzeitsmenü ganz nach Ihren
                            Vorstellungen und Wünschen vor. Die Unterbringung
                            der Hochzeitsgäste ist selbstverständlich. Wir
                            bereiten auch eine Hochzeitssuite vor. Wir bieten
                            auch eine Fotozone an.
                          </p>
                          <p>
                            Hier können Sie ausgewählte Gerichte einsehen, die
                            wir Ihnen zu allen Veranstaltungen anbieten können.
                            <br></br>
                            <strong>
                              Zögern Sie nicht, uns für weitere Informationen zu
                              kontaktieren.
                            </strong>
                          </p>
                        </>
                      )}

                      <p>
                        <br></br>
                      </p>
                    </div>
                  </Container>
                  <img src={KytkaWhite} className="kytka-white" alt="kytka" />
                </div>
              </div>
            </div>
            <a class="anchor" id="dailymenu"></a>
            <Container fluid className="well-rest rest-menu">
              <div className="cards container-lg">
                <div className="card-content">
                  <Row className="mt-1 mb-1 mb-md-3 text-center">
                    {renderCards()}
                  </Row>
                </div>
              </div>
            </Container>
          </LayoutOther>
        </Fade>
      </>
    );
  }
};

export default Wedding;
