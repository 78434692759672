import React, { Fragment, useState, useContext } from "react";
import Progress from "./Progress";
import axios from "axios";
import { Container } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import Compressor from "compressorjs";
import { ApplicationContext } from "../../../providers/ApplicationProvider";

const FileUploadEdit = () => {
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const navigate = useHistory();
  const { id } = useParams();
  const [{ accessToken }] = useContext(ApplicationContext);

  const onChange = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.7,
      maxWidth: 3840,
      maxHeight: 2160,
      resize: "cover",
      success: (compressedResult) => {
        setFile(compressedResult);
      },
    });
    var type = e.target.files[0].type;
    type = type.split('/')[1];
    setFilename(id + ".jpeg");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/restaurant/upload/image/${id}/${filename}`,
      formData,
      {
        headers: {
          "Content-Type": "application/x-www-url-formencoded",
          Authorization: "Bearer " + accessToken,
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      }
    );

    // Clear percentage
    setTimeout(() => setUploadPercentage(0), 10000);

    const { fileName, filePath } = res.data;

    setUploadedFile({ fileName, filePath });

    setMessage("File Uploaded");
    navigate.push("/recepce/actions");
  };

  return (
    <Container>
      <Fragment>
        <form onSubmit={onSubmit}>
          <div className="custom-file mb-4">
            <input
              type="file"
              accept="image/*"
              className="custom-file-input"
              id="customFile"
              multiple
              onChange={onChange}
            />
            <label className="custom-file-label" htmlFor="customFile">
              {filename}
            </label>
          </div>

          <Progress percentage={uploadPercentage} />

          <input
            type="submit"
            value="Upload"
            className="login-button"
          />
        </form>
        {uploadedFile ? (
          <div className="row mt-5">
            <div className="col-md-6 m-auto">
              <h3 className="text-center">{uploadedFile.fileName}</h3>
              <img
                style={{ width: "100%" }}
                src={uploadedFile.filePath}
                alt=""
              />
            </div>
          </div>
        ) : null}
      </Fragment>
    </Container>
  );
};

export default FileUploadEdit;