import React, { useContext, useEffect, useState } from "react";
import { Container, Table } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { ApplicationContext } from "../../..//providers/ApplicationProvider";
import LayoutOther from "../../layout/LayoutOther";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import axios from "axios";
import Loading from "../../pages/Loading";
import { ReactSortable } from "react-sortablejs";

export default function GalleryListOfCategory() {
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);
  const [names, setNames] = useState();
  const [{ accessToken }] = useContext(ApplicationContext);
  const id = useParams();
  const [count, setCount] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/gallery/get/gallery/names/` +
          id.id
      )
      .then((res) => {
        setNames(res.data.titleCZ);
      })
      .then(() => {
        setLoading(false);
      });
  }, [id.id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery/` + id.id)
      .then((res) => {
        setImageUrl(res.data);
      })
      .then(() => {
        setLoading(false);
        setCount(imageUrl.length);
      });
  }, [id.id, imageUrl.length]);

  function setActive(id, idOfMain, active) {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/image/active/image/gallery/${id}/${idOfMain}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        window.location.reload();
      });
  }

  function renderProjects() {
    const array = imageUrl.map((item, index) => {
      return (
        <tr className="text-white align-middle border" key={item.id}>
          <td>
            <input
              className="ms-3"
              type="checkbox"
              defaultChecked={item.isMain}
              onChange={() =>
                setActive(item.galleryTypeId, item.id, !item.isActive)
              }
            />
          </td>
          <td>
            <span>
              <ion-icon name="menu-outline"></ion-icon>
            </span>
          </td>
          <td>{item.id}</td>
          <td className="id">
            <img className="list-img" src={`${item.src}`} />
          </td>
          <td>
            <span>{item.fileName}</span>
          </td>
          <td
            onClick={() =>
              deleteImage(item.id, item.galleryTypeId, item.fileName)
            }
          >
            <button className="login-button">SMAZAT</button>
          </td>
        </tr>
      );
    });
    return array;
  }
  function sortProjects() {
    imageUrl.map((item, index) => {
      item.position = index + 1;
      sendData(index);
    });
  }

  function sendData(index) {
    var datas = imageUrl[index];
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/image/sort/gallery/images`,
        datas,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {});
  }

  function deleteImage(index, type, name) {
    if (count > 1) {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_URL}/image/delete/image/gallery/${index}/${type}/${name}`
        )
        .then((res) => {
          setImageUrl(res.data);
          window.location.reload();
        });
    } else {
      alert(
        "Nelze smazat poslední obrázek. Nahajte nový a poté smažte požadovaný obrzek."
      );
    }
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!loading) {
    return (
      <>
        <LayoutOther>
          <div className="break dash"></div>
          <Container className="animate-in">
            <div className="spacer mobile main"></div>
            <div className="d-flex justify-content-around">
              <Link to={`/admin/gallery-list/type`}>
                <button className="rest-button qr">ZPĚT</button>
              </Link>
              <h2>{names}</h2>
              <Link to={`/admin/gallery-list/type/${id.id}/upload`}>
                <button className="rest-button qr">PŘIDAT FOTO</button>
              </Link>
            </div>
            <Table
              responsive
              className="bg-light text-dark mt-3 border-radius p-1 rounded"
            >
              <ReactSortable
                group="groupName"
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={imageUrl}
                setList={setImageUrl}
              >
                {renderProjects()}
              </ReactSortable>
            </Table>
            <button className="login-button" onClick={() => sortProjects()}>
              ULOŽIT ŘAZENÍ
            </button>
          </Container>
        </LayoutOther>
      </>
    );
  }
}
