import React from 'react';
import { Route } from "react-router-dom";
import Navmenu from './NavmenuContact';
import Footer from './Footer';

const LayoutOther = ({ component: Component, layout: Layout, ...rest }) => {
  if (Layout === undefined) {
    Layout = (props) => <React.Fragment>{props.children}</React.Fragment>;
  }
  return (
    <>
      <Navmenu />
      <Route
        {...rest}
        render={(props) => (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        )}
      />
      <Footer />
    </>
  );
}

export default LayoutOther;