import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import LayoutOther from "./layout/Layout";
import {
  ApplicationContext,
  SET_SLIDER,
} from "../providers/ApplicationProvider";
import KytkaWhite from "../assets/images/logo-white.svg";
import { Link } from "react-router-dom";
import Slider from "./SliderImage";
import { Fade } from "react-awesome-reveal";
import Helmet from "react-helmet";
import superior1 from "../assets/images/superior/superior(1).jpg";
import superior2 from "../assets/images/superior/superior(2).jpg";
import superior3 from "../assets/images/superior/superior3.jpg";
import superior4 from "../assets/images/superior/superior.jpg";
import superior5 from "../assets/images/superior/superior5.jpg";
import standard1 from "../assets/images/standard/standard2.jpg";
import standard2 from "../assets/images/standard/standard1.jpg";
import standard3 from "../assets/images/standard/standard3.jpg";
import standard4 from "../assets/images/standard/standard4.jpg";
import standard5 from "../assets/images/standard/standard5.jpg";
import deluxe1 from "../assets/images/deluxe/_30A5993.jpg";
import deluxe2 from "../assets/images/deluxe/_30A5987.jpg";
import deluxe3 from "../assets/images/deluxe/_30A5967.jpg";
import deluxe4 from "../assets/images/deluxe/_30A5978.jpg";
import deluxe5 from "../assets/images/deluxe/_30A6003.jpg";
import deluxe6 from "../assets/images/deluxe/_30A6004.jpg";
import "animate.css";

const Hotel = () => {
  const [{ lang }] = useContext(ApplicationContext);
  const [{ slider }, dispatch] = useContext(ApplicationContext);
  const [info, setInfo] = useState(false);
  const [infoStan, setInfoStan] = useState(false);
  const [infoDelux, setInfoDelux] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [superior1, superior2, superior4, superior3, superior5];
  const imagesStan = [standard1, standard2, standard3, standard4, standard5];
  const imagesDelux = [deluxe1, deluxe2, deluxe3, deluxe4, deluxe5, deluxe6];

  function down(index) {
    if (slider[index] - 1 < 0) {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index],
      });
    } else {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index] - 1,
      });
    }
  }

  function up(index, max) {
    if (slider[index] + 1 > max) {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index],
      });
    } else {
      dispatch({
        type: SET_SLIDER,
        index: index,
        payload: slider[index] + 1,
      });
    }
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Wellness Hotel Edelweiss v Liberci nabízí svým hostům komfortní ubytování v deseti nových, stylově zařízených pokojích ve třech kategoriích Standard, Superior a DeLuxe."
        />
        <title>Wellness Hotel Edelweiss | Hotel Liberec</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Hotel Liberec"
        />
        <link rel="canonical" href="https://www.hoteledelweiss.cz/hotel"></link>
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Hotel Liberec"
        />
        <meta
          itemprop="description"
          content="Wellness Hotel Edelweiss nabízí svým hostům ubytování v deseti nových, stylově zařízených pokojích ve třech kategoriích Standard, Superior a DeLuxe."
        />
        <meta
          itemprop="image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta property="og:url" content="https://hoteledelweiss.cz/hotel" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Hotel Liberec"
        />
        <meta
          property="og:description"
          content="Wellness Hotel Edelweiss nabízí svým hostům ubytování v deseti nových, stylově zařízených pokojích ve třech kategoriích Standard, Superior a DeLuxe."
        />
        <meta
          property="og:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Wellness Hotel Edelweiss | Hotel Liberec"
        />
        <meta
          name="twitter:description"
          content="Wellness Hotel Edelweiss nabízí svým hostům ubytování v deseti nových, stylově zařízených pokojích ve třech kategoriích Standard, Superior a DeLuxe."
        />
        <meta
          name="twitter:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta
          name="description"
          content="Wellness Hotel Edelweiss nabízí svým hostům ubytování v deseti nových, stylově zařízených pokojích ve třech kategoriích Standard, Superior a DeLuxe."
        />
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Hotel Liberec"
        />
        <meta
          itemprop="description"
          content="Wellness Hotel Edelweiss nabízí svým hostům ubytování v deseti nových, stylově zařízených pokojích ve třech kategoriích Standard, Superior a DeLuxe."
        />
      </Helmet>
      <Fade>
        <LayoutOther>
          <div className="showcase-hotel">
            <div className="showcase-content">
              <div className="showcase-hotel-table">
                <Container>
                  <div className="surrounding welcome-text hotel-text col-md-12">
                    <h1>
                      {lang === "cs"
                        ? "NEZAPOMENUTELNÉ UBYTOVÁNÍ POD JEŠTĚDEM"
                        : lang === "en"
                        ? "UNFORGETTABLE ACCOMMODATION UNDER JEŠTĚD"
                        : "UNVERGESSLICHE UNTERKUNFT UNTER JEŠTĚD"}
                    </h1>
                    {lang === "cs" ? (
                      <p>
                        Wellness Hotel Edelweiss nabízí svým hostům komfortní ubytování
                        v&nbsp;deseti nových, stylově zařízených pokojích ve
                        třech kategoriích Standard, Superior a&nbsp;DeLuxe.
                        V&nbsp;celém hotelu čeká na návštěvníky komfort, pohodlí
                        a&nbsp;servis personálu, který je připravený splnit
                        všechna přání svých hostů. Součástí hotelu je restaurace
                        s&nbsp;terasou, zahrada s&nbsp;grilem a&nbsp;udírnou,
                        venkovní bazén, soukromé hlídané parkoviště,
                        a&nbsp;prostory na uložení kol či lyží.
                      </p>
                    ) : lang === "en" ? (
                      <p>
                        Wellness Hotel Edelweiss offers its guests accommodation
                        in&nbsp;ten new, stylishly furnished rooms in&nbsp;three
                        categories: Standard, Superior, and&nbsp;DeLuxe.
                        Throughout the&nbsp;hotel, comfort, convenience,
                        and&nbsp;the&nbsp;service of&nbsp;the&nbsp;staff, who
                        are ready to&nbsp;fulfill all the wishes of their
                        guests, await visitors. The&nbsp;hotel includes
                        a&nbsp;restaurant with&nbsp;a&nbsp;terrace,
                        a&nbsp;garden with a&nbsp;grill
                        and&nbsp;a&nbsp;smokehouse, an&nbsp;outdoor pool,
                        a&nbsp;private guarded parking lot, and&nbsp;spaces for
                        storing bikes or skis.
                      </p>
                    ) : (
                      <p>
                        Das Wellnesshotel Edelweiss bietet seinen Gästen
                        Unterkunft in zehn neuen, stilvoll eingerichteten
                        Zimmern in drei Kategorien: Standard, Superior und
                        DeLuxe. Im gesamten Hotel erwarten die Besucher Komfort,
                        Bequemlichkeit und der Service des Personals, das bereit
                        ist, alle Wünsche seiner Gäste zu erfüllen. Das Hotel
                        verfügt über ein Restaurant mit Terrasse, einen Garten
                        mit Grill und Räucherei, einen Außenpool, einen
                        bewachten Privatparkplatz und Abstellräume für Fahrräder
                        oder Skier.
                      </p>
                    )}
                    {lang === "cs" ? (
                      <p>
                        Na další možnosti zapůjčení sportovního vybavení se
                        ptejte na naší recepci.
                      </p>
                    ) : lang === "en" ? (
                      <p>
                        Ask about other options for renting sports equipment
                        at&nbsp;our reception.
                      </p>
                    ) : (
                      <p>
                        Fragen Sie nach weiteren Möglichkeiten, Sportgeräte zu
                        mieten an unserer Rezeption.
                      </p>
                    )}
                    {lang === "cs" ? (
                      <p>
                        Každý pokoj v&nbsp;hotelu je vybavený pohodlnou
                        dvoulůžkovou postelí s&nbsp;denním i&nbsp;nočním
                        osvětlením, televizí s&nbsp;připojením na internet, plně
                        vybavenou koupelnou se sprchovým koutem, úložnými
                        prostory, minibarem a&nbsp;trezorem.
                      </p>
                    ) : lang === "en" ? (
                      <p>
                        Each room in&nbsp;the&nbsp;hotel is equipped with
                        a&nbsp;comfortable double bed with&nbsp;day
                        and&nbsp;night lighting, a&nbsp;television with
                        an&nbsp;Internet connection, a&nbsp;fully equipped
                        bathroom with&nbsp;a&nbsp;shower, storage space,
                        minibar, and&nbsp;safe.
                      </p>
                    ) : (
                      <p>
                        Jedes Zimmer im Hotel ist mit einem bequemen Doppelbett
                        mit Tages- und Nachtbeleuchtung, Fernseher mit
                        Internetanschluss, voll ausgestattetem Badezimmer mit
                        Dusche, Stauraum, Minibar und Safe ausgestattet.
                      </p>
                    )}
                    {lang === "cs" ? (
                      <p>
                        Všechny prostory pravidelně dezinfikujeme technologií
                        Germitec.
                      </p>
                    ) : lang === "en" ? (
                      <p>
                        We regularly disinfect all spaces with&nbsp;Germitec
                        technology.
                      </p>
                    ) : (
                      <p>
                        Wir desinfizieren regelmäßig alle Räume mit
                        Germitec-Technologie.
                      </p>
                    )}
                    {lang === "cs" ? (
                      <p>
                        Speciální pozornost jsme věnovali při výběru postelí!
                        Proč? Chceme, abyste se u&nbsp;nás vyspali lépe než doma
                        a&nbsp;odpočinuli si na další náročné výzvy.
                      </p>
                    ) : lang === "en" ? (
                      <p>
                        We paid special attention when choosing the&nbsp;beds!
                        Why? We want you to&nbsp;sleep better with us than
                        at&nbsp;home and&nbsp;relax for the&nbsp;next
                        challenging challenges.
                      </p>
                    ) : (
                      <p>
                        Bei der Auswahl der Betten haben wir besonders darauf
                        geachtet! Wieso den? Wir möchten, dass Sie bei uns
                        besser schlafen als zu Hause und sich entspannt den
                        nächsten herausfordernden Herausforderungen stellen.
                      </p>
                    )}
                    <p></p>
                    <Link to="/reservation">
                      <button className="rest-button">
                        {lang === "cs"
                          ? "REZERVOVAT"
                          : lang === "en"
                          ? "RESERVE"
                          : "MEHR"}
                      </button>
                    </Link>
                  </div>
                </Container>
                <img
                  src={KytkaWhite}
                  className="kytka-white kytka-hotel"
                  alt="kytka"
                />
              </div>
            </div>
          </div>
          <div className="hotel container-lg">
            <Row className="hotel-row-1">
              <div className="col-lg-4 row-sm hotel-text-green p-0">
                <h3>DELUXE</h3>
                <div
                  className={`animate__animated deluxe-text${
                    infoDelux ? "-deactive" : "-active animate__fadeIn"
                  }`}
                >
                  <span
                    className={`${infoDelux ? "deactive" : "active"}`}
                    onClick={() => setInfoDelux(true)}
                  >
                    {lang === "cs"
                      ? "specifikace pokoje"
                      : lang === "en"
                      ? "room specifications"
                      : "Zimmerspezifikationen"}
                  </span>
                  {lang === "cs" ? (
                    <p>
                      Největší hotelové dvoulůžkové pokoje o&nbsp;velikosti
                      49&nbsp;m<sup>2</sup> s&nbsp;vlastní terasou
                      s&nbsp;posezením a&nbsp;jedinečným výhledem na Ještěd či
                      Liberec mají svoji neopakovatelnou atmosféru. Díky své
                      velikosti nabízejí možnost přistýlky a&nbsp;jsou tak
                      ideální volbou při cestování s&nbsp;dětmi, nebo pro
                      náročnější hosty, kteří chtějí mít víc prostoru. Součástí
                      pokoje jsou také křesílka a&nbsp;konferenční stolek.
                    </p>
                  ) : lang === "en" ? (
                    <p>
                      The hotel's largest double rooms measuring 49&nbsp;m
                      <sup>2</sup> with&nbsp;their terrace with&nbsp;seating
                      and&nbsp;a&nbsp;unique view of Ještěd or Liberec have
                      their unique atmosphere. Thanks to their size, they offer
                      the&nbsp;option of an&nbsp;extra bed and&nbsp;are thus an
                      ideal choice when traveling with children or for more
                      demanding guests who want more space. The room also
                      includes armchairs and&nbsp;a&nbsp;coffee table.
                    </p>
                  ) : (
                    <p>
                      Die größten Doppelzimmer des Hotels mit einer Größe von
                      49&nbsp;m<sup>2</sup> mit eigener Terrasse mit
                      Sitzgelegenheiten und einem einzigartigen Blick auf Ještěd
                      oder Liberec haben eine einzigartige Atmosphäre. Dank
                      ihrer Größe bieten sie die Möglichkeit einer Aufbettung
                      und sind somit eine ideale Wahl für Reisen mit Kindern
                      oder für anspruchsvollere Gäste, die mehr Platz wünschen.
                      Das Zimmer ist außerdem mit Sesseln und einem Couchtisch
                      ausgestattet.
                    </p>
                  )}
                </div>
                <div
                  className={`animate__animated info-room-deluxe${
                    infoDelux ? "-active animate__fadeIn" : "-deactive"
                  }`}
                >
                  <span
                    className={`${infoDelux ? "active" : "deactive"}`}
                    onClick={() => setInfoDelux(false)}
                  >
                    {lang === "cs"
                      ? "popis pokoje"
                      : lang === "en"
                      ? "room description"
                      : "Zimmerbeschreibung"}
                  </span>
                  {lang === "cs" ? (
                    <ul>
                      <li>
                        49 m<sup>2</sup>
                      </li>
                      <li>
                        Soukromá terasa s posezením 15 m<sup>2</sup>
                      </li>
                      <li>SMART TV LG</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Trezor</li>
                      <li>Kouřové detektory, protipožární dveře</li>
                      <li>Psací stůl</li>
                      <li>Rychlovarná konvice, káva a čaj na pokoji zdarma</li>
                      <li>
                        Koupelna se sprchovým koutem, vysoušečem vlasů a
                        kvalitní kosmetikou
                      </li>
                      <li>Křesílka na pokoji</li>
                      <li>Režim světla denní/noční</li>
                      <li>Dezinfekce Germitec</li>
                    </ul>
                  ) : lang === "en" ? (
                    <ul>
                      <li>
                        49 m<sup>2</sup>
                      </li>
                      <li>
                        Private terrace with seating 15 m<sup>2</sup>
                      </li>
                      <li>LG SMART TV</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Vault</li>
                      <li>Smoke detectors, fire doors</li>
                      <li>Writing desk</li>
                      <li>
                        Instant kettle, coffee, and tea in the room for free
                      </li>
                      <li>
                        Bathroom with shower, hairdryer, and quality cosmetics
                      </li>
                      <li>Armchair in the room</li>
                      <li>Day/night light mode</li>
                      <li>Germitec disinfection</li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        49 m<sup>2</sup>
                      </li>
                      <li>
                        Private Terrasse mit Sitzgelegenheit 15 m<sup>2</sup>
                      </li>
                      <li>LG SMART-TV</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Tresor</li>
                      <li>Rauchmelder, Brandschutztüren</li>
                      <li>Schreibtisch</li>
                      <li>
                        Wasserkocher, Kaffee und Tee kostenlos auf dem Zimmer
                      </li>
                      <li>
                        Badezimmer mit Dusche, Haartrockner und Qualität
                        Kosmetika
                      </li>
                      <li>Sessel im Zimmer</li>
                      <li>Tag-/Nachtlichtmodus</li>
                      <li>Germitec-Desinfektion</li>
                    </ul>
                  )}
                </div>
                <div className="card-footer-btn">
                  <Link className="icon-text" to="/reservation">
                    <button className="card-button">
                      {lang === "cs"
                        ? "REZERVOVAT"
                        : lang === "en"
                        ? "RESERVE"
                        : "MEHR"}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg row-sm p-0 hotel-green-image slider-box">
                <img
                  src={imagesDelux[slider[2]]}
                  alt="Deluxe luxusní pokoj Wellness Hotel Edelweiss Liberec"
                  className="hotel-img"
                />
                <Slider max="5" index={2} classname="slider"></Slider>
                <div className="left-arrow" onClick={() => down(2)}>
                  <ion-icon name="chevron-back-sharp"></ion-icon>
                </div>
                <div className="right-arrow" onClick={() => up(2, 5)}>
                  <ion-icon name="chevron-forward-sharp"></ion-icon>
                </div>
              </div>
            </Row>
            <Row className="hotel-row">
              <div className="col-lg-4 row-sm hotel-text-white box p-0">
                <h3>SUPERIOR</h3>
                <div
                  className={`animate__animated superior-text${
                    info ? "-deactive" : "-active animate__fadeIn"
                  }`}
                >
                  <span
                    className={`${info ? "deactive" : "active"}`}
                    onClick={() => setInfo(true)}
                  >
                    {lang === "cs"
                      ? "specifikace pokoje"
                      : lang === "en"
                      ? "room specifications"
                      : "Zimmerspezifikationen"}
                  </span>
                  {lang === "cs" ? (
                    <p className="white-card">
                      Komfortní dvoulůžkové pokoje o&nbsp;velikosti 25&nbsp;m
                      <sup>2</sup> s&nbsp;jedinečným výhledem na Ještěd či
                      Liberec nabízí vysoký standard pro pohodlí svých hostů.
                      Díky své velikosti nabízejí možnost přistýlky a&nbsp;jsou
                      tak ideální volbou při cestování s&nbsp;dětmi.
                    </p>
                  ) : lang === "en" ? (
                    <p className="white-card">
                      Comfortable double rooms with a&nbsp;size of 25&nbsp;m
                      <sup>2</sup> with&nbsp;a&nbsp;unique view of Ještěd or
                      Liberec offer a high standard for the&nbsp;comfort of its
                      guests. Thanks to their size, they offer the&nbsp;option
                      of an&nbsp;extra bed and&nbsp;are thus an&nbsp;ideal
                      choice when traveling with&nbsp;children.
                    </p>
                  ) : (
                    <p className="white-card">
                      Komfortable Doppelzimmer mit einer Größe von 25&nbsp;m
                      <sup>2</sup> mit einzigartigem Blick auf Ještěd oder
                      Liberec bieten einen hohen Standard für den Komfort seiner
                      Gäste. Dank ihrer Größe bieten sie die Möglichkeit einer
                      Aufbettung und sind somit eine ideale Wahl für Reisen mit
                      Kindern.
                    </p>
                  )}
                </div>
                <div
                  className={`animate__animated info-room-superior${
                    info ? "-active animate__fadeIn" : "-deactive"
                  }`}
                >
                  <span
                    className={` ${info ? "active" : "deactive"}`}
                    onClick={() => setInfo(false)}
                  >
                    {lang === "cs"
                      ? "popis pokoje"
                      : lang === "en"
                      ? "room description"
                      : "Zimmerbeschreibung"}
                  </span>
                  {lang === "cs" ? (
                    <ul>
                      <li>
                        25 m<sup>2</sup>
                      </li>
                      <li>SMART TV LG</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Trezor</li>
                      <li>Kouřové detektory, protipožární dveře</li>
                      <li>Psací stůl</li>
                      <li>Rychlovarná konvice, káva a čaj na pokoji zdarma</li>
                      <li>
                        Koupelna se sprchovým koutem, vysoušečem vlasů a
                        kvalitní kosmetikou
                      </li>
                      <li>Křesílka na pokoji</li>
                      <li>Režim světla denní/noční</li>
                      <li>Dezinfekce Germitec</li>
                    </ul>
                  ) : lang === "en" ? (
                    <ul>
                      <li>
                        25 m<sup>2</sup>
                      </li>
                      <li>LG SMART TV</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Vault</li>
                      <li>Smoke detectors, fire doors</li>
                      <li>Writing desk</li>
                      <li>
                        Instant kettle, coffee and tea in the room for free
                      </li>
                      <li>
                        Bathroom with shower, hairdryer and quality cosmetics
                      </li>
                      <li>Armchair in the room</li>
                      <li>Day/night light mode</li>
                      <li>Germitec disinfection</li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        25 m<sup>2</sup>
                      </li>
                      <li>LG SMART-TV</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Tresor</li>
                      <li>Rauchmelder, Brandschutztüren</li>
                      <li>Schreibtisch</li>
                      <li>
                        Wasserkocher, Kaffee und Tee kostenlos auf dem Zimmer
                      </li>
                      <li>
                        Badezimmer mit Dusche, Haartrockner und Qualität
                        Kosmetika
                      </li>
                      <li>Sessel im Zimmer</li>
                      <li>Tag-/Nachtlichtmodus</li>
                      <li>Germitec-Desinfektion</li>
                    </ul>
                  )}
                </div>
                <div className="card-footer-btn">
                  <Link className="icon-text" to="/reservation">
                    <button className="rest-button">
                      {lang === "cs"
                        ? "REZERVOVAT"
                        : lang === "en"
                        ? "RESERVE"
                        : "MEHR"}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg row-sm p-0 hotel-white-image slider-box">
                <span className="top">
                  {lang === "cs"
                    ? "Nejoblíbenější"
                    : lang === "en"
                    ? "Favorite"
                    : "Favorit"}
                </span>
                <span className="ribbon"></span>
                <span className="transparent"></span>
                <img src={images[slider[1]]} alt="Wellness Hotel Edelweiss" className="hotel-img" />
                <Slider max="4" index={1} classname="slider"></Slider>
                <div className="left-arrow" onClick={() => down(1)}>
                  <ion-icon name="chevron-back-sharp"></ion-icon>
                </div>
                <div className="right-arrow" onClick={() => up(1, 4)}>
                  <ion-icon name="chevron-forward-sharp"></ion-icon>
                </div>
              </div>
            </Row>
            <Row className="hotel-row">
              <div className="col-lg-4 row-sm hotel-text-green p-0">
                <h3>STANDARD</h3>
                <div
                  className={`animate__animated standard-text${
                    infoStan ? "-deactive" : "-active animate__fadeIn"
                  }`}
                >
                  <span
                    className={` ${infoStan ? "deactive" : "active"}`}
                    onClick={() => setInfoStan(true)}
                  >
                    {lang === "cs"
                      ? "specifikace pokoje"
                      : lang === "en"
                      ? "room specifications"
                      : "Zimmerspezifikationen"}
                  </span>
                  {lang === "cs" ? (
                    <p>
                      Pohodlné dvoulůžkové pokoje o velikosti 18&nbsp;m
                      <sup>2</sup> s&nbsp;koupelnou a&nbsp;sprchovým koutem,
                      pracovním stolkem a&nbsp;minibarem. V&nbsp;tomto pokoji
                      najdou hosté veškeré pohodlí ve dvou pro svůj čas
                      odpočinku a&nbsp;relaxace.
                    </p>
                  ) : lang === "en" ? (
                    <p>
                      Comfortable double rooms with a&nbsp;size of 18&nbsp;m
                      <sup>2</sup>
                      with&nbsp;bathroom and&nbsp;shower, work desk,
                      and&nbsp;minibar. In&nbsp;this room, guests will find all
                      the&nbsp;comforts of two for their time of relaxation.
                    </p>
                  ) : (
                    <p>
                      Komfortable Doppelzimmer mit einer Größe von 18&nbsp;m
                      <sup>2</sup> mit Bad und Dusche, Schreibtisch und Minibar.
                      In diesem Zimmer finden die Gäste allen Komfort von zwei
                      für ihre Zeit der Ruhe und Entspannung.
                    </p>
                  )}
                </div>
                <div
                  className={`animate__animated info-room-standard${
                    infoStan ? "-active animate__fadeIn" : "-deactive"
                  }`}
                >
                  <span
                    className={` ${infoStan ? "active" : "deactive"}`}
                    onClick={() => setInfoStan(false)}
                  >
                    {lang === "cs"
                      ? "popis pokoje"
                      : lang === "en"
                      ? "room description"
                      : "Zimmerbeschreibung"}
                  </span>
                  {lang === "cs" ? (
                    <ul>
                      <li>
                        18 m<sup>2</sup>
                      </li>
                      <li>SMART TV LG</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Trezor</li>
                      <li>Kouřové detektory, protipožární dveře</li>
                      <li>Psací stůl</li>
                      <li>Rychlovarná konvice, káva a čaj na pokoji zdarma</li>
                      <li>
                        Koupelna se sprchovým koutem, vysoušečem vlasů a
                        kvalitní kosmetikou
                      </li>
                      <li>Režim světla denní/noční</li>
                      <li>Dezinfekce Germitec</li>
                    </ul>
                  ) : lang === "en" ? (
                    <ul>
                      <li>
                        18 m<sup>2</sup>
                      </li>
                      <li>LG SMART TV</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Vault</li>
                      <li>Smoke detectors, fire doors</li>
                      <li>Writing desk</li>
                      <li>
                        Instant kettle, coffee and tea in the room for free
                      </li>
                      <li>
                        Bathroom with shower, hairdryer and quality cosmetics
                      </li>
                      <li>Day/night light mode</li>
                      <li>Germitec disinfection</li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        18 m<sup>2</sup>
                      </li>
                      <li>LG SMART-TV</li>
                      <li>Wi-Fi</li>
                      <li>Minibar</li>
                      <li>Tresor</li>
                      <li>Rauchmelder, Brandschutztüren</li>
                      <li>Schreibtisch</li>
                      <li>
                        Wasserkocher, Kaffee und Tee kostenlos auf dem Zimmer
                      </li>
                      <li>
                        Badezimmer mit Dusche, Haartrockner und Qualität
                        Kosmetika
                      </li>
                      <li>Tag-/Nachtlichtmodus</li>
                      <li>Germitec-Desinfektion</li>
                    </ul>
                  )}
                </div>
                <div className="card-footer-btn">
                  <Link className="icon-text" to="/reservation">
                    <button className="card-button">
                      {lang === "cs"
                        ? "REZERVOVAT"
                        : lang === "en"
                        ? "RESERVE"
                        : "MEHR"}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg row-sm p-0 hotel-green-image slider-box">
                <img
                  src={imagesStan[slider[0]]}
                  alt="Standardní pokoj Wellness Hotel Edelweiss Liberec"
                  className="hotel-img"
                />
                <Slider max="4" index={0} classname="slider"></Slider>
                <div className="left-arrow" onClick={() => down(0)}>
                  <ion-icon name="chevron-back-sharp"></ion-icon>
                </div>
                <div className="right-arrow" onClick={() => up(0, 4)}>
                  <ion-icon name="chevron-forward-sharp"></ion-icon>
                </div>
              </div>
            </Row>
          </div>
        </LayoutOther>
      </Fade>
    </>
  );
};

export default Hotel;
