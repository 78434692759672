import React, { createContext, useReducer, useContext } from "react";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_LANG = "SET_LANG";
export const SET_SLIDER = "SET_SLIDER";

const copyMultidimensionalArray = (arr) => {
  return JSON.parse(JSON.stringify(arr));
};

const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

const userId = {
  accessToken: null,
  role: null,
  lang: 'cs',
  slider: [0, 0, 0, 0, 0, 0],
};

export const ApplicationContext = createContext(userId);

export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN: {
      let temp = copyMultidimensionalArray(state);
      temp.accessToken = action.payload;
      var tempParse = parseJwt(action.payload);
      temp.role = tempParse.family_name;
      return temp;
    }
    case SET_LANG: {
      let temp = copyMultidimensionalArray(state);
      temp.lang = action.payload;
      return temp;
    }
    case SET_SLIDER: {
      let temp = copyMultidimensionalArray(state);
      temp.slider[action.index] = action.payload;
      return temp;
    }
    default:
      return state;
  }
};

export const ApplicationProvider = ({ children, ...rest }) => {
  const [state, dispatch] = useReducer(appReducer, userId);
  return (
    <>
      <ApplicationContext.Provider value={[state, dispatch]}>
        {children}
      </ApplicationContext.Provider>
    </>
  );
};

export const useAuthContext = () => useContext(ApplicationContext);