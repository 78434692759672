import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import LayoutOther from "../../layout/LayoutOther";
import { Container, Input, Label } from "reactstrap";
import axios from "axios";

const FoodEdit = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Restaurant/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: (values) => {
      axios
        .put(`${process.env.REACT_APP_BACKEND_URL}/Restaurant`, {
          Id: id,
          nameCS: values.nameCS,
          nameDE: values.nameDE,
          nameEN: values.nameEN,
          price: values.price,
          descriptionCS: values.descriptionCS,
          descriptionDE: values.descriptionDE,
          descriptionEN: values.descriptionEN,
          typeoffood: values.typeOfFood,
          position: values.position,
          isActive: values.isActive,
          amount: values.amount
        })
        .then(() => {
          setDone(true);
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
          history.push(`/admin/food/list/${data.typeOfFood}`);
        });
    },
  });

  return (
    <LayoutOther>
      <Container>
        <div className="admin-content">
          <h2>Upravit jídlo</h2>
          <Link to={`/admin/food/list/${data.typeOfFood}`}>
            <button className="login-button">Zpět</button>
          </Link>
          <form onSubmit={formik.handleSubmit}>
            <Label className="mt-3" htmlFor="nameCS">
              Název CS
            </Label>
            <Input
              id="nameCS"
              name="nameCS"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nameCS}
            />
            <Label className="mt-3" htmlFor="nameDE">
              Název DE
            </Label>
            <Input
              id="nameDE"
              name="nameDE"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nameDE}
            />
            <Label className="mt-3" htmlFor="nameEN">
              Název EN
            </Label>
            <Input
              id="nameEN"
              name="nameEN"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.nameEN}
            />
            <Label className="mt-3" htmlFor="descriptionCS">
              Popis CS
            </Label>
            <Input
              id="descriptionCS"
              name="descriptionCS"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.descriptionCS}
            />
            <Label className="mt-3" htmlFor="descriptionDE">
              Popis DE
            </Label>
            <Input
              id="descriptionDE"
              name="descriptionDE"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.descriptionDE}
            />
            <Label className="mt-3" htmlFor="descriptionEN">
            Popis EN
            </Label>
            <Input
              id="descriptionEN"
              name="descriptionEN"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.descriptionEN}
            />
            <Label className="mt-3" htmlFor="amount">
              Množství [g, ml, cl]
            </Label>
            <Input
              id="amount"
              name="amount"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.amount}
            />
            <Label className="mt-3" htmlFor="price">
              Cena
            </Label>
            <Input
              id="price"
              name="price"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.price}
            />
            <button className="login-button mt-4" type="submit">
              Uložit
            </button>
          </form>
        </div>
      </Container>
    </LayoutOther>
  );
};

export default FoodEdit;
