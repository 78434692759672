import React, { useContext, useEffect, useState } from "react";
import { Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../../..//providers/ApplicationProvider";
import LayoutOther from "../../layout/LayoutOther";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import axios from "axios";
import Loading from "../../pages/Loading";
import { ReactSortable } from "react-sortablejs";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);
  const [{ accessToken }] = useContext(ApplicationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/gallery/get/gallery`)
      .then((res) => {
        setImageUrl(res.data);
        setLoading(false);
      });
  }, []);

  function renderProjects() {
    const array = imageUrl.map((item, index) => {
      return (
        <tr className="text-white align-middle border" key={item.id}>
          <td>
            <span>
              <ion-icon name="menu-outline"></ion-icon>
            </span>
          </td>
          <td>{item.id}</td>
          <td className="id">
            <img className="list-img" src={`${item.src}`} />
          </td>
          <td>
            <span>{item.titleCZ}</span>
          </td>
          <td>
            <Link to={`/admin/gallery-list/type/${item.id}/edit`}>
              <button className="login-button">UPRAVIT INFO</button>
            </Link>
          </td>
          <td>
            <Link to={`/admin/gallery-list/type/${item.id}`}>
              <button className="login-button">UPRAVIT FOTO</button>
            </Link>
          </td>
          <td onClick={() => deleteImage(item.id)}>
            <button className="login-button">SMAZAT</button>
          </td>
        </tr>
      );
    });
    return array;
  }
  function sortProjects() {
    imageUrl.map((item, index) => {
      item.position = index + 1;
      sendData(index);
    });
  }

  function sendData(index) {
    var datas = imageUrl[index];
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/gallery/sort/gallery`, datas, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {});
  }

  function deleteImage(index) {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/gallery/delete/gallery/${index}`
      )
      .then((res) => {
        setImageUrl(res.data);
        window.location.reload();
      });
  }
  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!loading) {
    return (
      <>
        <LayoutOther>
          <div className="break dash"></div>
          <Container className="animate-in">
            <div className="spacer mobile main"></div>
            <div className="d-flex justify-content-around">
              <h2>GALERIE</h2>
              <Link to="/admin/gallery-list/type/add">
                <button className="rest-button qr">PŘIDAT</button>
              </Link>
            </div>
            <Table responsive className="bg-light mt-3 p-1">
              <tbody>
                <ReactSortable
                  animation={200}
                  delayOnTouchStart={true}
                  delay={2}
                  list={imageUrl}
                  setList={setImageUrl}
                >
                  {renderProjects()}
                </ReactSortable>
              </tbody>
            </Table>
            <button className="login-button" onClick={() => sortProjects()}>
              ULOŽIT ŘAZENÍ
            </button>
          </Container>
        </LayoutOther>
      </>
    );
  }
};

export default Gallery;
