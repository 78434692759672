import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { ApplicationContext } from "../providers/ApplicationProvider";
import LayoutOther from "./layout/LayoutContact";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import PhotoAlbum from "react-photo-album";
import axios from "axios";
import { Fade } from "react-awesome-reveal";
import Loading from "./pages/Loading";
import { Helmet } from "react-helmet";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);
  const [names, setNames] = useState();
  const [{ lang }] = useContext(ApplicationContext);
  const [index, setIndex] = useState(-1);
  const id = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/gallery/get/gallery/names/${id.id}`
      )
      .then((res) => {
        setNames(res.data);
      })
      .then(() => {
        setLoading(false);
      });
  }, [id.id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery/` + id.id)
      .then((res) => {
        setImageUrl(res.data);
      })
      .then(() => {
        setLoading(false);
      });
  }, [id.id]);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!loading) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <title>Wellness Hotel Edelweiss | Galerie</title>
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Galerie"
          />
          <link
            rel="canonical"
            href="https://www.hoteledelweiss.cz/gallery"
          ></link>
          <meta itemprop="name" content="Wellness Hotel Edelweiss | Galerie" />
          <meta
            itemprop="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta
            itemprop="image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta property="og:url" content="https://hoteledelweiss.cz/gallery" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Wellness Hotel Edelweiss | Galerie"
          />
          <meta
            property="og:description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta
            property="og:image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Wellness Hotel Edelweiss | Galerie"
          />
          <meta
            name="twitter:description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta
            name="twitter:image"
            content="https://hoteledelweiss.cz/static/media/actions.047898f58524cbad1ba5.jpeg"
          />

          <meta
            name="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
          <meta itemprop="name" content="Wellness Hotel Edelweiss | Galerie" />
          <meta
            itemprop="description"
            content="Galerie našeho hotelu Edelweiss pod Ještědem v Liberci. Objevte krásy přírody."
          />
        </Helmet>
        <Fade>
          <LayoutOther>
            <div className="break">{""}</div>
            <Container>
              <h1>
                {lang === "cs"
                  ? names.titleCZ
                  : lang === "en"
                  ? names.titleEN
                  : names.titleDE}
              </h1>
              <p>
                {lang === "cs"
                  ? names.descriptionCZ
                  : lang === "en"
                  ? names.descriptionEN
                  : names.descriptionDE}
              </p>
              <div className="gallery__image">
                <Link className="icon-text" to="/gallery">
                  <button className="rest-button mb-3">
                    {lang === "cs"
                      ? "ZPĚT DO VÝBĚRU GALERIÍ"
                      : lang === "en"
                      ? "BACK TO GALLERY LIST"
                      : "ZURÜCK ZUR GALERIE-LISTE"}
                  </button>
                </Link>
                <PhotoAlbum
                  layout="rows"
                  columns={3}
                  photos={imageUrl}
                  onClick={({ index }) => setIndex(index)}
                  renderPhoto={({
                    photo,
                    wrapperStyle,
                    renderDefaultPhoto,
                  }) => (
                    <div style={{ position: "relative", ...wrapperStyle }}>
                      {renderDefaultPhoto({ wrapped: true })}
                      {photo.title && (
                        <div
                          style={{
                            position: "absolute",
                            overflow: "hidden",
                            backgroundColor: "rgba(255 255 255 / .6)",
                            inset: "auto 0 0 0",
                            padding: 10,
                          }}
                        >
                          {photo.fileName}
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
              <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={imageUrl}
                plugins={[Zoom, Counter]}
                counter={{ container: { style: { top: "unset", bottom: 0 } } }}
              />
            </Container>
          </LayoutOther>
        </Fade>
      </>
    );
  }
};

export default Gallery;
