import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import LayoutOther from "../layout/LayoutOther";
import { ApplicationContext } from "../../providers/ApplicationProvider";
import AccessDenied from "../pages/AccessDenied";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";

const AdminProfile = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const history = useHistory();

  if (!accessToken || role !== "ADMIN") {
    history.push("/");
    return (
      <>
        <AccessDenied />
      </>
    );
  } else if (accessToken && role === "ADMIN") {
    return (
      <>
        <LayoutOther>
          <Container>
            <div className="cards-food">
              <Row>
                <h1>RESTAURACE</h1>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-green mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>DENNÍ - HOTOVKY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/0">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>DENNÍ - POLÉVKY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/12">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card
                    className="card-content-normal mt-3 mt-md-5 mb-lg-3"
                    style={{ visibility: "hidden" }}
                  >
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>***</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/18">
                        <button className="card-button" disabled>
                          UPRAVIT
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>POLÉVKY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/2">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>PŘEDRKMY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/1">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>KUŘECÍ MASO</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/3">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>VEPŘOVÉ MASO</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/4">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>HOVĚZÍ MASO</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/5">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>TĚSTOVINY A GNOCCHI</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/6">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>SALÁTY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/7">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>PŘÍLOHY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/8">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>NAŠE SPECIALITY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/23">
                        <button className="card-button">
                          UPRAVIT
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>UKRAJINSKÁ JÍDLA</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/24">
                        <button className="card-button">
                          UPRAVIT
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>GRILL</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/10">
                        <button className="card-button">
                          UPRAVIT
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>OMÁČKY A DIPY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/18">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>PIZZY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/25">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card
                    className="card-content-normal mt-3 mt-md-5 mb-lg-3"
                  >
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>BURGER, TWISTER</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/26">
                        <button className="card-button">
                          UPRAVIT
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>DEZERTY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/9">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <h1>NÁPOJOVÝ LÍSTEK A MINIBAR</h1>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>MINIBAR</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/11">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>KÁVA A ČAJ</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/13">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>PIVO A ČEPOVANÉ</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/14">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>APERITIVY A DESTILÁTY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/15">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>VÍNO</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/16">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>NEALKO</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/17">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>NEALKO. KOKTEJLY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/20">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>ALKO. KOKTEJLY</h4>
                      </CardTitle>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/admin/food/list/21">
                        <button className="card-button">UPRAVIT</button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </LayoutOther>
      </>
    );
  }
};

export default AdminProfile;
