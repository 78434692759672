import React from "react";
import { Link } from "react-router-dom";
import LayoutOther from "../../layout/LayoutOther";
import { Container } from "reactstrap";
import FileUploadEdit from "./FileUploadMain";

const GalleryUpload = () => {
  return (
    <LayoutOther>
      <Container>
        <div className="admin-content">
          <Link to={`/admin/gallery-list/type`}>
            <button className="login-button">Zpět</button>
          </Link>
          <FileUploadEdit />
        </div>
      </Container>
    </LayoutOther>
  );
};

export default GalleryUpload;
