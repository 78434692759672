import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container } from "reactstrap";

const NotFound = () => {
  return (
    <>
      <Container className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <Card className="error404 p-3 mb-5 col-sm-6">
          <CardBody>
            <CardTitle tag="h4" className="text-white">
              ERROR 404 | NOT FOUND
            </CardTitle>
            <Link to="/">
              <button className="mt-5 mb-3 login-button">
                Zpět na hlavní stránku
              </button>
            </Link>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default NotFound;
