import React, { useEffect, useContext } from "react";
import Layout from "./layout/Layout";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { Row, Col, Container } from "reactstrap";
import jested from "../assets/images/jested-glider-min.jpg";
import muzeum from "../assets/images/muzeum-min.jpg";
import zoo from "../assets/images/zoo-min.jpg";
import radnice from "../assets/images/radnice-min.jpg";
import iqlandia from "../assets/images/iqlandia-min.jpg";
import vysina from "../assets/images/vysina-min.jpg";
import KytkaWhite from "../assets/images/logo-white.svg";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const Surroundings = () => {
  const [{ lang }] = useContext(ApplicationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Páté největší město České republiky nabídne to nejlepší z města a současně z přírody. Unikátní poloha uprostřed hor. Liberec"
        />
        <title>Wellness Hotel Edelweiss | Okolí Liberec, Ještěd</title>
        <meta property="og:title" content="Wellness Hotel Edelweiss | Okolí Liberec, Ještěd" />
        <link
          rel="canonical"
          href="https://www.hoteledelweiss.cz/surroundings"
        ></link>
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Okolí Liberec, Ještěd"
        />
        <meta
          itemprop="description"
          content="Páté největší město České republiky nabídne to nejlepší z města a současně z přírody. Unikátní poloha uprostřed hor. Liberec"
        />
        <meta
          itemprop="image"
          content="https://hoteledelweiss.cz/static/media/city-glider-min.a5941f7f10516fdfcc2f.jpg"
        />

        <meta
          property="og:url"
          content="https://hoteledelweiss.cz/surrondings"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Okolí Liberec, Ještěd"
        />
        <meta
          property="og:description"
          content="Páté největší město České republiky nabídne to nejlepší z města a současně z přírody. Unikátní poloha uprostřed hor. Liberec"
        />
        <meta
          property="og:image"
          content="https://hoteledelweiss.cz/static/media/city-glider-min.a5941f7f10516fdfcc2f.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Wellness Hotel Edelweiss | Okolí Liberec, Ještěd"
        />
        <meta
          name="twitter:description"
          content="Páté největší město České republiky nabídne to nejlepší z města a současně z přírody. Unikátní poloha uprostřed hor. Liberec"
        />
        <meta
          name="twitter:image"
          content="https://hoteledelweiss.cz/static/media/city-glider-min.a5941f7f10516fdfcc2f.jpg"
        />

        <meta
          name="description"
          content="Páté největší město České republiky nabídne to nejlepší z města a současně z přírody. Unikátní poloha uprostřed hor. Liberec"
        />
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Okolí Liberec, Ještěd"
        />
        <meta
          itemprop="description"
          content="Páté největší město České republiky nabídne to nejlepší z města a současně z přírody. Unikátní poloha uprostřed hor. Liberec"
        />
      </Helmet>
      <Fade>
        <Layout>
          <div className="showcase-surroundings">
            <div className="showcase-content">
              <div className="showcase-hotel-table">
                <Container>
                  <div className="surrounding welcome-text hotel-text col-md-8">
                    <h1>
                      {lang === "cs"
                        ? "Liberec a okolí"
                        : lang === "en"
                        ? "Liberec and surroundings"
                        : "Liberec und sehenswürdigkeiten"}
                    </h1>
                    {lang === "cs" ? (
                      <>
                        <p>
                          Páté největší město České republiky nabídne to
                          nejlepší z&nbsp;města a&nbsp;současně z&nbsp;přírody.
                          Unikátní poloha uprostřed hor umožňuje návštěvníkům
                          všech věkových kategorií užít si dosyta z&nbsp;každého
                          něco. Liberec nabízí zábavu i&nbsp;sportovní vyžití
                          celých 12&nbsp;měsíců v&nbsp;roce.
                        </p>
                        <p>
                          Na své si přijdou turisté, cyklisté, běžkaři,
                          sjezdaři, milovníci koupání, ale také zábavy
                          a&nbsp;městského života. Tramvají, která má zastávku
                          nedaleko Hotelu Edelweiss se během několika minut
                          dostanou naši hosté do nejstarší české ZOO, botanické
                          zahrady, Severočeského muzea či galerie, nebo do
                          centra města, které nabízí historické náměstí či
                          divadlo. Rodiče zabaví děti v&nbsp;nedalekém
                          aquaparku, zábavních centrech IQlandii a&nbsp;IQparku,
                          DinoParku, Naivním divadle či Technickém muzeu.
                          <a
                            href="https://www.visitliberec.eu/"
                            target="_blank"
                            className="surroundings-link"
                            rel="noreferrer"
                          >
                            <button className="rest-button">
                              {lang === "cs"
                                ? "VÍCE"
                                : lang === "en"
                                ? "MORE"
                                : "MEHR"}
                            </button>
                          </a>
                        </p>
                      </>
                    ) : lang === "en" ? (
                      <>
                        <p>
                          The&nbsp;fifth largest city in&nbsp;the&nbsp;Czech
                          Republic will offer the&nbsp;best
                          of&nbsp;the&nbsp;city and&nbsp;nature
                          at&nbsp;the&nbsp;same time. the&nbsp;unique location
                          in&nbsp;the&nbsp;middle of the&nbsp;mountains allows
                          visitors of all ages to enjoy something for everyone.
                          Liberec offers entertainment and&nbsp;sports for all
                          12 months of the&nbsp;year.
                        </p>
                        <p>
                          Tourists, cyclists, cross-country skiers, downhill
                          skiers, and&nbsp;lovers of swimming, but also
                          entertainment and&nbsp;city life will find their
                          place. Our guests can take the&nbsp;tram, which stops
                          near Hotel Edelweiss, in&nbsp;a&nbsp;few minutes to
                          the&nbsp;oldest Czech Zoo, botanical garden, North
                          Bohemian Museum or gallery, or to the&nbsp;city
                          center, which offers a&nbsp;historic square or
                          theater. Parents can entertain their children
                          in&nbsp;the&nbsp;nearby water park, entertainment
                          centers IQlandii and&nbsp;IQpark, DinoPark, Naive
                          theater or Technical Museum.
                          <a
                            href="https://www.visitliberec.eu/"
                            target="_blank"
                            className="surroundings-link"
                            rel="noreferrer"
                          >
                            <button className="rest-button">MORE</button>
                          </a>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          Die fünftgrößte Stadt Tschechiens bietet gleichzeitig
                          das Beste aus Stadt und Natur. Die einzigartige Lage
                          mitten in den Bergen ermöglicht Besuchern jeden
                          Alters, für jeden etwas zu erleben. Liberec bietet
                          Unterhaltung und Sport alle 12 Monate im Jahr.
                        </p>
                        <p>
                          Touristen, Radfahrer, Langläufer, Skifahrer, Liebhaber
                          des Schwimmens, aber auch der Unterhaltung und des
                          Stadtlebens werden ihren Platz finden. Unsere Gäste
                          können mit der Straßenbahn, die in der Nähe des Hotels
                          Edelweiss hält, in wenigen Minuten zum ältesten
                          tschechischen Zoo, Botanischen Garten, Nordböhmischen
                          Museum oder Galerie oder ins Stadtzentrum mit
                          historischem Platz oder Theater gelangen. Eltern
                          können ihre Kinder im nahe gelegenen Wasserpark, in
                          den Unterhaltungszentren IQlandii und IQpark, im
                          DinoPark, im Naiv Theater oder im Technischen Museum
                          unterhalten.
                          <a
                            href="https://www.visitliberec.eu/"
                            target="_blank"
                            className="surroundings-link"
                            rel="noreferrer"
                          >
                            <button className="rest-button">MEHR</button>
                          </a>
                        </p>
                      </>
                    )}
                  </div>
                </Container>
                <img src={KytkaWhite} className="kytka-white" alt="kytka" />
              </div>
            </div>
          </div>
          <Container fluid className="well-rest rest-menu surroundings">
            <Row>
              <Col md={6} className="p-0 well-rest-card">
                <div className="restaurant-text">
                  <h3>
                    Ještěd
                    <span></span>
                  </h3>
                  {lang === "cs" ? (
                    <>
                      <p>
                        Unikátní symbol města i&nbsp;celého Libereckého kraje
                        najdete jen několik metrů od&nbsp;Wellness Hotelu
                        Edelweiss. Vysílač Ještěd majestátně shlíží
                        ze&nbsp;stejnojmenné hory. Jedinečná stavba architekta
                        Karla Hubáčka byla otevřena v&nbsp;roce 1973 a&nbsp;je
                        držitelem takových titulů jako národní kulturní památka
                        ČR nebo stavba 20.&nbsp;století. Rotační hyperboloid je
                        držitelem prestižní ceny Augusta Perreta.
                      </p>
                      <p>
                        Kabinová lanovka je aktuálně mimo provoz. Využít můžete
                        čtyřsedačkovou lanovku Skalka.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <>
                      <p>
                        The&nbsp;unique symbol of the&nbsp;city
                        and&nbsp;the&nbsp;entire Liberec region can be found
                        just a&nbsp;few meters from&nbsp;the&nbsp;Wellness Hotel
                        Edelweiss. The&nbsp;Ještěd transmitter majestically
                        looks down from the&nbsp;mountain of&nbsp;the&nbsp;same
                        name. The&nbsp;unique building
                        of&nbsp;the&nbsp;architect Karel Hubáček was opened
                        in&nbsp;1973 and&nbsp;holds such titles as
                        a&nbsp;national cultural monument of&nbsp;the&nbsp;Czech
                        Republic or a&nbsp;building of
                        the&nbsp;20th&nbsp;century. The&nbsp;rotating
                        hyperboloid is&nbsp;the&nbsp;recipient
                        of&nbsp;the&nbsp;prestigious August Perret Prize.
                      </p>
                      <p>
                        The&nbsp;cable car is&nbsp;currently out
                        of&nbsp;service. You can use the&nbsp;Skalka four-seater
                        cable car.
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Das einzigartige Wahrzeichen der Stadt und der gesamten
                        Region Liberec finden Sie nur wenige Meter vom Wellness
                        Hotel Edelweiss entfernt. Der Sender Ještěd blickt
                        majestätisch vom gleichnamigen Berg herab. Das
                        einzigartige Gebäude des Architekten Karel Hubáček wurde
                        1973 eröffnet und trägt Titel wie nationales
                        Kulturdenkmal der Tschechischen Republik oder Gebäude
                        des 20. Jahrhunderts. Das rotierende Hyperboloid wurde
                        mit dem renommierten August-Perret-Preis ausgezeichnet.
                      </p>
                      <p>
                        Die Seilbahn ist derzeit außer Betrieb. Sie können die
                        Viersitzer-Seilbahn Skalka benutzen.
                      </p>
                    </>
                  )}
                  <div className="well-rest-footer">
                    <a
                      className="icon-text"
                      href="https://www.jested.cz/cs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="rest-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} className="p-0 m-0">
                <img src={jested} alt="restaurant" className="restaurant-img" />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-0 m-0 order-5 order-md-1">
                <img src={muzeum} alt="restaurant" className="wellness-img" />
              </Col>
              <Col
                md={6}
                className="p-0 well-rest-card green order-1 order-md-5"
              >
                <div className="wellness-text">
                  {lang === "cs" ? (
                    <>
                      <h3>SEVEROČESKÉ MUZEUM a galerie</h3>
                      <p>
                        Severočeské muzeum v&nbsp;Liberci patří mezi největší
                        muzea v&nbsp;České&nbsp;republice. Vzniklo v&nbsp;roce
                        1873 jako uměleckoprůmyslové muzeum
                        a&nbsp;v&nbsp;českých zemích je nejstarším muzeem tohoto
                        typu. Oblastní galerie Liberec je pátou největší galerií
                        v České republice, byla založena v&nbsp;roce 1953
                        a&nbsp;od&nbsp;roku&nbsp;2014 sídlí v&nbsp;budově
                        bývalých městských lázní. Galerie spravuje sbírky více
                        než 21&nbsp;000 uměleckých děl z&nbsp;Čech, Německa,
                        Rakouska, Francie, Nizozemí a&nbsp;dalších evropských
                        států a&nbsp;právem se tedy označuje jako Muzeum
                        evropského umění.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <>
                      <h3>NORTH BOHEMIAN MUSEUM and&nbsp;GALLERY</h3>
                      <p>
                        The&nbsp;North Bohemian Museum in&nbsp;Liberec is one
                        of&nbsp;the&nbsp;largest museums in&nbsp;the&nbsp;Czech
                        Republic. It was established in&nbsp;1873 as an&nbsp;art
                        and&nbsp;industry museum and&nbsp;is the&nbsp;oldest
                        museum of this type in&nbsp;the&nbsp;Czech lands.
                        the&nbsp;Liberec Regional Gallery is&nbsp;the&nbsp;fifth
                        largest gallery in&nbsp;the&nbsp;Czech Republic, it was
                        founded in&nbsp;1953 and&nbsp;since 2014 has been housed
                        in&nbsp;the&nbsp;building of the&nbsp;former city spa.
                        The&nbsp;gallery manages collections of more than
                        21,000&nbsp;works of art from the&nbsp;Czech Republic,
                        Germany, Austria, France, the&nbsp;Netherlands,
                        and&nbsp;other European countries, and&nbsp;is therefore
                        rightly called the&nbsp;Museum of European Art.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>NORDBÖHMISCHES MUSEUM UND GALERIE</h3>
                      <p>
                        Das Nordböhmische Museum in Liberec ist eines der
                        größten Museen in der Tschechischen Republik. Es wurde
                        1873 als Kunst- und Industriemuseum gegründet und ist
                        das älteste Museum dieser Art in den böhmischen Ländern.
                        Die Regionalgalerie Liberec ist die fünftgrößte Galerie
                        in der Tschechischen Republik, sie wurde 1953 gegründet
                        und ist seit 2014 im Gebäude des ehemaligen
                        Stadtkurortes untergebracht. Die Galerie verwaltet
                        Sammlungen von mehr als 21.000 Kunstwerken aus der
                        Tschechischen Republik, Deutschland, Österreich,
                        Frankreich, den Niederlanden und anderen europäischen
                        Ländern und trägt daher zu Recht den Namen Museum of
                        European Art.
                      </p>
                    </>
                  )}
                  <div className="well-rest-footer">
                    <a
                      className="icon-text"
                      href="https://www.muzeumlb.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="card-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-0 well-rest-card">
                <div className="restaurant-text">
                  {lang === "cs" ? (
                    <>
                      <h3>
                        botanická zahrada a ZOO
                        <span></span>
                      </h3>
                      <p>
                        Liberecká botanická zahrada je nejstarší
                        v&nbsp;České&nbsp;republice, pro veřejnost byla otevřena
                        již v&nbsp;roce 1895. V&nbsp;zahradě najdete
                        nejpestřejší sbírku orchidejí, kapradin
                        a&nbsp;masožravých rostlin v&nbsp;ČR nebo nejstarší
                        kamelie a&nbsp;nejdéle pěstovanou bonsaj v&nbsp;Evropě.
                        Oblíbený je také pavilon akvárií s&nbsp;úžasným vodním
                        tunelem. Zoologická zahrada Liberec byla založena roku
                        1904 a&nbsp;je nejstarší zoo na&nbsp;území republiky. Na
                        ploše 14&nbsp;ha můžete obdivovat přes 170&nbsp;druhů
                        zvířat ze všech kontinentů.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <>
                      <h3>
                        BOTANICAL GARDEN AND ZOO
                        <span></span>
                      </h3>
                      <p>
                        The&nbsp;Liberec Botanical Garden
                        is&nbsp;the&nbsp;oldest in&nbsp;the&nbsp;Czech Republic,
                        it was opened to&nbsp;the&nbsp;public in&nbsp;1895.
                        In&nbsp;the&nbsp;garden you will find the&nbsp;most
                        diverse collection of orchids, ferns
                        and&nbsp;carnivorous plants in&nbsp;the&nbsp;Czech
                        Republic, or the&nbsp;oldest camellia
                        and&nbsp;the&nbsp;longest cultivated bonsai
                        in&nbsp;Europe. The aquarium pavilion with its amazing
                        water tunnel is also popular. The&nbsp;Liberec Zoo was
                        founded in&nbsp;1904 and&nbsp;is the&nbsp;oldest zoo
                        in&nbsp;the&nbsp;republic. On an&nbsp;area of
                        14&nbsp;hectares, you can admire over 170 species of
                        animals from all continents.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>
                        BOTANISCHER GARTEN UND ZOO
                        <span></span>
                      </h3>
                      <p>
                        Der Botanische Garten Liberec ist der älteste in der
                        Tschechischen Republik, er wurde 1895 der Öffentlichkeit
                        zugänglich gemacht. Im Garten finden Sie die
                        vielfältigste Sammlung von Orchideen, Farnen und
                        fleischfressenden Pflanzen in der Tschechischen Republik
                        oder die älteste Kamelie und die längste kultivierter
                        Bonsai in Europa. Beliebt ist auch der Aquarienpavillon
                        mit seinem tollen Wassertunnel. Der Zoo Liberec wurde
                        1904 gegründet und ist der älteste Zoo in der Republik.
                        Auf einer Fläche von 14 Hektar können Sie über 170
                        Tierarten aus allen Kontinenten bestaunen.
                      </p>
                    </>
                  )}

                  <div className="well-rest-footer">
                    <a
                      className="icon-text"
                      href="https://www.zooliberec.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="rest-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} className="p-0 m-0" id="garden-restaurant">
                <img src={zoo} alt="restaurant" className="restaurant-img" />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-0 m-0 order-5 order-md-1">
                <img src={vysina} alt="restaurant" className="wellness-img" />
              </Col>
              <Col
                md={6}
                className="p-0 well-rest-card green order-1 order-md-5"
              >
                <div className="wellness-text">
                  {lang === "cs" ? (
                    <>
                      <h3>liberecká výšina</h3>
                      <p>
                        Romantickou rozhlednu s&nbsp;restaurací v&nbsp;podobě
                        středověkého hradu nechal postavit Heinrich Liebieg
                        na&nbsp;počátku 20.&nbsp;století. Stavba měla původně
                        vše, co rytířskému sídlu náleží – most, pomyslný hradní
                        příkop, kamennou bránou v&nbsp;hradbách
                        se&nbsp;strážními domky i&nbsp;rumpálovou studnu. Hrádku
                        dominovala mohutná věž. Po letech chátrání byla
                        Liberecká výšina opravena a&nbsp;opět slouží svým
                        návštěvníkům.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <>
                      <h3>liberecká výšina</h3>
                      <p>
                        The&nbsp;romantic lookout tower
                        with&nbsp;a&nbsp;restaurant in&nbsp;the&nbsp;form
                        of&nbsp;a&nbsp;medieval castle was built by Heinrich
                        Liebieg at&nbsp;the&nbsp;beginning
                        of&nbsp;the&nbsp;20th&nbsp;century. The&nbsp;building
                        originally had everything that belongs to
                        a&nbsp;knight's seat - a&nbsp;bridge, an&nbsp;imaginary
                        castle moat, a&nbsp;stone gate in&nbsp;the&nbsp;walls
                        with&nbsp;guard houses and&nbsp;a&nbsp;well
                        with&nbsp;a&nbsp;tiller. The&nbsp;castle was dominated
                        by a&nbsp;massive tower. After years of decay, Liberecká
                        výšina has been repaired and&nbsp;is once again serving
                        its visitors.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>liberecká výšina</h3>
                      <p>
                        Der romantische Aussichtsturm mit Restaurant in Form
                        einer mittelalterlichen Burg wurde Anfang des 20.
                        Jahrhunderts von Heinrich Liebieg erbaut. Das Gebäude
                        hatte ursprünglich alles, was zu einem Rittersitz gehört
                        – eine Brücke, einen imaginären Burggraben, ein Steintor
                        in den Mauern mit Wachhäuschen und einen Brunnen mit
                        Pinne. Die Burg wurde von einem massiven Turm
                        beherrscht. Nach Jahren des Verfalls wurde die Liberecká
                        výšina repariert und steht wieder ihren Besuchern zur
                        Verfügung.
                      </p>
                    </>
                  )}
                  <div className="well-rest-footer">
                    <a
                      className="icon-text"
                      href="https://www.libereckavysina.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="card-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-0 well-rest-card">
                <div className="restaurant-text">
                  {lang === "cs" ? (
                    <>
                      <h3>
                        iqlandia
                        <span></span>
                      </h3>
                      <p>
                        iQLANDIA je unikátní vědecko-zábavní centrum
                        s&nbsp;planetáriem v&nbsp;Liberci. Přijeďte si užít den
                        plný hravé zábavy. Vše si můžete osahat a&nbsp;vyzkoušet
                        na vlastní kůži. V&nbsp;jedenácti expozicích vás čeká
                        přes 400 interaktivních exponátů.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <>
                      <h3>
                        iqlandia
                        <span></span>
                      </h3>
                      <p>
                        iQLANDIA is a&nbsp;unique science and&nbsp;entertainment
                        center with&nbsp;a&nbsp;planetarium in&nbsp;Liberec.
                        Come and&nbsp;enjoy a&nbsp;day full of playful fun. You
                        can touch everything and&nbsp;try it yourself. Over
                        400&nbsp;interactive exhibits await you in&nbsp;eleven
                        exhibitions.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>
                        iqlandia
                        <span></span>
                      </h3>
                      <p>
                        iQLANDIA ist ein einzigartiges Wissenschafts- und
                        Unterhaltungszentrum mit einem Planetarium in Liberec.
                        Kommen Sie und genießen Sie einen Tag voller
                        spielerischer Freude. Sie können alles anfassen und
                        selbst ausprobieren. In elf Ausstellungen erwarten Sie
                        über 400 interaktive Exponate.
                      </p>
                    </>
                  )}
                  <div className="well-rest-footer">
                    <a
                      className="icon-text"
                      href="https://iqlandia.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="rest-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} className="p-0 m-0" id="garden-restaurant">
                <img
                  src={iqlandia}
                  alt="restaurant"
                  className="restaurant-img"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-0 m-0 order-5 order-md-1">
                <img src={radnice} alt="restaurant" className="wellness-img" />
              </Col>
              <Col
                md={6}
                className="p-0 well-rest-card green order-1 order-md-5"
              >
                <div className="wellness-text">
                  {lang === "cs" ? (
                    <>
                      <h3>liberecká radnice</h3>
                      <p>
                        Novorenesanční budova liberecké radnice byla postavena
                        v&nbsp;roce 1893 podle projektu vídeňského architekta
                        F.&nbsp;Neumanna. Známá je její blízká podoba
                        s&nbsp;radnicí ve&nbsp;Vídni. Nad&nbsp;mohutným vstupním
                        portálem nás upoutá kamenný reliéf znázorňující založení
                        staré i&nbsp;nové radnice a&nbsp;balustrádový balkón, ze
                        kterého hovořily mnohé významné osobnosti.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <>
                      <h3>LIBEREC CITY HALL</h3>
                      <p>
                        The&nbsp;Neo-Renaissance building
                        of&nbsp;the&nbsp;Liberec Town Hall was built
                        in&nbsp;1893 according to the&nbsp;project
                        of&nbsp;the&nbsp;Viennese architect F.&nbsp;Neumann. Its
                        close resemblance to the&nbsp;town hall in&nbsp;Vienna
                        is well known. Above the&nbsp;massive entrance portal,
                        a&nbsp;stone relief depicting the&nbsp;founding
                        of&nbsp;the&nbsp;old and&nbsp;new town halls
                        and&nbsp;a&nbsp;balustraded balcony from&nbsp;which many
                        important personalities spoke attract our attention.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>LIBEREC RATHAUS</h3>
                      <p>
                        Das Neorenaissance-Gebäude des Rathauses von Liberec
                        wurde 1893 nach dem Projekt des Wiener Architekten F.
                        Neumann erbaut. Seine große Ähnlichkeit mit dem Rathaus
                        in Wien ist bekannt. Über dem massiven Eingangsportal
                        erregen ein Steinrelief, das die Fundamente des alten
                        und neuen Rathauses darstellt, und ein
                        Balustradenbalkon, von dem aus viele bedeutende
                        Persönlichkeiten sprachen, unsere Aufmerksamkeit.
                      </p>
                    </>
                  )}
                  <div className="well-rest-footer">
                    <a
                      className="icon-text"
                      href="https://www.visitliberec.eu/vse-o-liberci/turisticke-cile/historicke-stavby/?view=min&cat=zajimavosti_a_cile&detail=826"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="card-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Layout>
      </Fade>
    </>
  );
};

export default Surroundings;
