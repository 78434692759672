import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import { ApplicationContext } from "../providers/ApplicationProvider";
import Navmenu from "./layout/NavmenuContact";

const Reservation = () => {
  const [{ lang }] = useContext(ApplicationContext);

  return (
    <>
      <Helmet>
        <title>Wellness Hotel Edelweiss | Rezervace</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Rezervace"
        />
        <link
          rel="canonical"
          href="https://www.hoteledelweiss.cz/reservation"
        ></link>
      </Helmet>
      <Navmenu />
      <div className="showcase-hotelres">
        <div className="showcase-content">
          <div className="showcase-hotel-table">
            <Container>
              <div className="surrounding welcome-text hotel-text">
                <h1>
                  {lang === "cs"
                    ? "REZERVACE"
                    : lang === "en"
                    ? "RESERVATION"
                    : "RESERVIERUNG"}
                </h1>
                <div className="previo-reservation-form">
                  <iframe
                    src={`https://booking.previo.cz/?hotId=758959&theme=green&lang=${lang}`}
                    scrolling="no"
                    frameborder="0"
                    width="100%"
                    height="4000px"
                    name="previo-booking-iframe"
                    id="previo-booking-iframe"
                    allowTransparency="true"
                    title="previo"
                  ></iframe>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
