import React, { useContext, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import LayoutOther from "./layout/Layout";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import KytkaWhite from "../assets/images/logo-white.svg";
import jacuzzi from "../assets/images/jacuzzi.JPG";
import masage from "../assets/images/masage.jpg";
import finskasauna from "../assets/images/finskasauna.JPG";
import infra from "../assets/images/infra.JPG";

const Wellness = () => {
  const [{ lang }] = useContext(ApplicationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Nyní jsme pro Vás otevřeli nové moderní wellness v Liberci! Přijďte si užít
                        naše wellness centrum s masážemi, které
                        jsou k&nbsp;dispozici nejen pro hotelové hosty."
        />
        <title>Wellness Hotel Edelweiss | Wellness Liberec</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Wellness Liberec"
        />
        <link
          rel="canonical"
          href="https://www.hoteledelweiss.cz/wellness"
        ></link>
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Wellness Liberec"
        />
        <meta
          itemprop="description"
          content="Nyní jsme pro Vás otevřeli nové moderní wellness v Liberci! Přijďte si užít
                        naše wellness centrum s masážemi, které
                        jsou k&nbsp;dispozici nejen pro hotelové hosty."
        />
        <meta
          itemprop="image"
          content="https://hoteledelweiss.cz/static/media/jacuzzi.b5f7c17b974bacae5fb4.JPG"
        />

        <meta
          property="og:url"
          content="https://hoteledelweiss.cz/surrondings"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Wellness Liberec"
        />
        <meta
          property="og:description"
          content="Nyní jsme pro Vás otevřeli nové moderní wellness! Přijďte si užít
                        naše wellness centrum s masážemi, které
                        jsou k&nbsp;dispozici nejen pro hotelové hosty."
        />
        <meta
          property="og:image"
          content="https://hoteledelweiss.cz/static/media/jacuzzi.b5f7c17b974bacae5fb4.JPG"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Wellness Hotel Edelweiss | Wellness Liberec"
        />
        <meta
          name="twitter:description"
          content="Nyní jsme pro Vás otevřeli nové moderní wellness! Přijďte si užít
                        naše wellness centrum s masážemi, které
                        jsou k&nbsp;dispozici nejen pro hotelové hosty."
        />
        <meta
          name="twitter:image"
          content="https://hoteledelweiss.cz/static/media/jacuzzi.b5f7c17b974bacae5fb4.JPG"
        />

        <meta
          name="description"
          content="Nyní jsme pro Vás otevřeli nové moderní wellness! Přijďte si užít
                        naše wellness centrum s masážemi, které
                        jsou k&nbsp;dispozici nejen pro hotelové hosty."
        />
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Wellness Liberec"
        />
        <meta
          itemprop="description"
          content="Nyní jsme pro Vás otevřeli nové moderní wellness! Přijďte si užít
                        naše wellness centrum s masážemi, které
                        jsou k&nbsp;dispozici nejen pro hotelové hosty."
        />
      </Helmet>
      <Fade>
        <LayoutOther>
          {/*
          <div className="showcase-hotel">
            <div className="showcase-content">
              <div className="showcase-wellness">
                <Container>
                  <div className="surrounding welcome-text hotel-text col-md-8">
                    <h1>WELLNESS</h1>
                    {lang === "cs" ? (
                      <>
                        <p>
                          Moderní wellness pro naše hosty již brzy! Aktuálně
                          pracujeme na přípravě moderního wellness centra, které
                          bude k&nbsp;dispozici nejen hotelovým hostům.
                        </p>
                        <p>
                          Do světa relaxace a&nbsp;odpočinku přenesou
                          návštěvníky hotelu maséři. K&nbsp;využití bude
                          jacuzzi, finská sauna, infrasauna
                          a&nbsp;odpočívárna&nbsp;+&nbsp;hotelové služby.
                        </p>
                      </>
                    ) : lang === "en" ? (
                      <>
                        <p>
                          Modern wellness for our guests soon! We are currently
                          working on the&nbsp;preparation of a&nbsp;modern
                          wellness center that will be available not only for
                          hotel guests.
                        </p>
                        <p>
                          Masseurs will transport hotel visitors to a&nbsp;world
                          of relaxation. There will be a&nbsp;jacuzzi,
                          a&nbsp;Finnish sauna, an&nbsp;infrared sauna,
                          and&nbsp;a&nbsp;rest room + hotel services.
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          Bald moderne Wellness für unsere Gäste! Wir arbeiten
                          derzeit an der Vorbereitung eines modernen
                          Wellnesscenters, das nicht nur Hotelgästen zur
                          Verfügung stehen wird.
                        </p>
                        <p>
                          Masseure entführen die Hotelgäste in die Welt der
                          Entspannung und Erholung. Es wird einen jacuzzi, eine
                          finnische Sauna, eine Infrarotsauna und einen Ruheraum
                          + Hotelservice geben.
                        </p>
                      </>
                    )}
                  </div>
                </Container>
              </div>
            </div>
          </div>*/}
          <div className="">
            <div className="showcase-wellness">
              <div className="showcase-content">
                <div className="showcase-hotel-table">
                  <Container>
                    <div className="welcome-text hotel-text col-md-8">
                      <h3>WELLNESS</h3>
                      {lang === "cs" ? (
                        <>
                          <p>
                            Nyní jsme pro Vás otevřeli nové moderní wellness!
                            Přijďte si užít naše wellness centrum s masážemi,
                            které jsou k&nbsp;dispozici nejen pro hotelové
                            hosty.
                          </p>
                          <p>
                            Do světa relaxace a&nbsp;odpočinku přenesou
                            návštěvníky hotelu maséři. K&nbsp;využití je
                            jacuzzi, finská sauna, infrasauna
                            a&nbsp;odpočívárna&nbsp;+&nbsp;hotelové služby.
                          </p>
                          <p>
                            Hotelové SPA je otevřené od 11:00 do 22:00. Nabízíme
                            také noční wellness od 22:00 do 8:00. Nebo privátní
                            pronájem prostoru wellness maximálně pro
                            10&nbsp;osob.
                          </p>
                          <p>
                            <strong>
                              Wellness a masáže se musí rezervovat minimálně
                              3&nbsp;dny předem.
                            </strong>
                          </p>
                        </>
                      ) : lang === "de" ? (
                        <>
                          <p>
                            Wir haben jetzt ein neues modernes Wellnesscenter
                            für Sie eröffnet! Kommen Sie und genießen Sie unser
                            Wellnesscenter mit Massagen, die nicht nur
                            Hotelgästen zur Verfügung stehen Gäste.
                          </p>
                          <p>
                            Sie entführen Sie in die Welt der Entspannung und
                            Erholung Masseurinnen für Hotelbesucher. Es ist zur
                            Verwendung bestimmt Whirlpool, finnische Sauna,
                            Infrarotsauna und Toilette + Hoteldienstleistungen.
                          </p>
                          <p>
                            Das Hotel-SPA ist von 11:00 bis 22:00 Uhr geöffnet.
                            Wir bieten auch Nachtwellness von 22:00 bis 08:00
                            Uhr. Oder privat Vermietung des Wellnessbereichs für
                            maximal 10&nbsp;Personen.
                          </p>
                          <p>
                            <strong>
                              Wellness und Massagen müssen mindestens gebucht
                              werden 3&nbsp;Tage im Voraus.
                            </strong>
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            We have now opened a new modern wellness center for
                            you! Come and enjoy our wellness center with
                            massages that are available not only for hotel
                            guests.
                          </p>
                          <p>
                            They will transport visitors to the world of
                            relaxation and rest hotel masseuses. Jacuzzi,
                            Finnish sauna, infrared sauna and rest room + hotel
                            services.
                          </p>
                          <p>
                            The hotel SPA is open from 11:00 a.m. to 10:00 p.m.
                            We offer also night wellness from 10:00 p.m. to 8:00
                            a.m. Or private rental of the wellness space for a
                            maximum of 10 people.
                          </p>
                          <p>
                            <strong>
                              Wellness and massages must be booked at least 3
                              days in advance.
                            </strong>
                          </p>
                        </>
                      )}
                    </div>
                  </Container>
                  <img src={KytkaWhite} className="kytka-white" alt="kytka" />
                </div>
              </div>
            </div>
            <div className="restaurant-content-mobile">
              <div className="restaurant-page container wellness">
                <Row className="hotel-row">
                  <Card>
                    <CardBody>
                      {lang === "cs" ? (
                        <>
                          <Col lg>
                            <h3 className="text-center">Ceník</h3>
                            <p>V ceně je parkování a Wi-Fi zdarma</p>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Denní wellness</strong>
                              </p>
                              <p className="price">
                                <strong>490&nbsp;Kč/osobu/hod</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                11:00 - 22:00 minimálně 2 osoby ve wellness
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Noční wellness</strong>
                              </p>
                              <p className="price">
                                <strong>6 500&nbsp;Kč/noc</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                22:00 - 08:00 maximálně 2 osoby ve wellness
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Privátní wellness</strong>
                              </p>
                              <p className="price">
                                <strong>3 490&nbsp;Kč/hod</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                po předchozí domluvě v jakýkoliv čas, maximálně
                                10 osob ve wellness
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Sportovní masáž</strong>
                              </p>
                              <p className="price">
                                <strong>60 minut</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                cena po telefonu/e-mailu
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Klasická masáž</strong>
                              </p>
                              <p className="price">
                                <strong>30/60 minut</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                cena po telefonu/e-mailu
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Relaxační masáž</strong>
                              </p>
                              <p className="price">
                                <strong>30/60 minut</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                cena po telefonu/e-mailu
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>
                                  Osušky, ručníky, župany, prostěradla
                                  a&nbsp;pantofle jsou k zapůjčení ve wellness
                                  hotelu
                                </strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                recepce hotelu má právo v individuálních
                                případech vyžadovat před vstupem do SPA kauci
                              </p>
                            </div>
                          </Col>
                        </>
                      ) : lang === "de" ? (
                        <>
                          <Col lg>
                            <h3 className="text-center">Preisliste</h3>
                            <p>
                              Parkplätze und kostenfreies WLAN sind im Preis
                              inbegriffen
                            </p>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Tageswellness</strong>
                              </p>
                              <p className="price">
                                <strong>490&nbsp;Kč/Person/Stunde</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                11:00 - 22:00 / Mindestens 2 Personen im
                                Wellnessbereich
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Nachtswellness</strong>
                              </p>
                              <p className="price">
                                <strong>6 500&nbsp;Kč/nacht</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                22:00 - 08:00 / Maximal 2 Personen im
                                Wellnessbereich
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Privatwellness</strong>
                              </p>
                              <p className="price">
                                <strong>3 490&nbsp;Kč/Stunde</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                nach vorheriger Vereinbarung jederzeit, maximal
                                10 Personen im Wellnessbereich
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Sportmassage</strong>
                              </p>
                              <p className="price">
                                <strong>60 Minuten</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                Preis per Telefon/E-Mail
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Klassische Massage</strong>
                              </p>
                              <p className="price">
                                <strong>30/60 Minuten</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                Preis per Telefon/E-Mail
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Entspannungsmassage</strong>
                              </p>
                              <p className="price">
                                <strong>30/60 Minuten</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                Preis per Telefon/E-Mail
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>
                                  Badetücher, Handtücher, Bademäntel, Bettwäsche
                                  Im Wellnessbereich können Badeschlappen
                                  ausgeliehen werden Hotel
                                </strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                Das Recht dazu hat die Hotelrezeption im
                                Einzelfall In allen Fällen ist vor dem Betreten
                                des SPA eine Anzahlung erforderlich
                              </p>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col lg>
                            <h3 className="text-center">Price list</h3>
                            <p>
                              Parking and free Wi-Fi are included in the price
                            </p>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Daily wellness</strong>
                              </p>
                              <p className="price">
                                <strong>490&nbsp;Kč/person/hour</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                11:00 - 22:00 at least 2 people in the wellness
                                area
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Night wellness</strong>
                              </p>
                              <p className="price">
                                <strong>6 500&nbsp;Kč/night</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                22:00 - 08:00 maximum 2 people in the wellness
                                area
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Private wellness</strong>
                              </p>
                              <p className="price">
                                <strong>3 490&nbsp;Kč/hour</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                by prior arrangement at any time, maximum 10
                                people in wellness
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Sports massage</strong>
                              </p>
                              <p className="price">
                                <strong>60 minutes</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                price by phone/email
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Classic massage</strong>
                              </p>
                              <p className="price">
                                <strong>30/60 minutes</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                price by phone/email
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>Relaxation massage</strong>
                              </p>
                              <p className="price">
                                <strong>30/60 minutes</strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                price by phone/email
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="name-head">
                                <strong>
                                  Bath towels, towels, bathrobes, sheets and
                                  slippers can be rented in the wellness area
                                  hotel
                                </strong>
                              </p>
                            </div>
                            <div className="menu-text">
                              <p className="menu-text-desc">
                                the hotel reception has the right in individual
                                cases require a deposit before entering the SPA
                              </p>
                            </div>
                          </Col>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Row>
              </div>
            </div>
            <div className="restaurant-content-others">
              <div className="restaurant-page container wellness">
                {lang === "cs" ? (
                  <>
                    <Row>
                      <h3 className="text-center">Ceník</h3>
                    </Row>
                    <Row>
                      <p>V ceně je parkování a Wi-Fi zdarma</p>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Denní wellness / 11:00 - 22:00</strong>
                        </p>
                        <p className="price">
                          <strong>490&nbsp;Kč/osobu/hod</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          minimálně 2 osoby ve wellness
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Noční wellness / 22:00 - 08:00</strong>
                        </p>
                        <p className="price">
                          <strong>6 500&nbsp;Kč/noc</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          maximálně 2 osoby ve wellness
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Privátní pronájem wellness</strong>
                        </p>
                        <p className="price">
                          <strong>3 490&nbsp;Kč/hod</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          po předchozí domluvě v jakýkoliv čas, maximálně 10
                          osob ve wellness
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Sportovní masáž</strong>
                        </p>
                        <p className="price">
                          <strong>cena po telefonu/e-mailu</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">60 minut</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Klasická masáž</strong>
                        </p>
                        <p className="price">
                          <strong>cena po telefonu/e-mailu</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">30/60 minut</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Relaxační masáž</strong>
                        </p>
                        <p className="price">
                          <strong>cena po telefonu/e-mailu</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">30/60 minut</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>
                            Osušky, ručníky, župany, prostěradla a&nbsp;pantofle
                            jsou k zapůjčení ve wellness hotelu
                          </strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          recepce hotelu má právo v individuálních případech
                          vyžadovat před vstupem do SPA kauci
                        </p>
                      </div>
                    </Row>
                  </>
                ) : lang === "de" ? (
                  <>
                    <Row>
                      <h3 className="text-center">Preisliste</h3>
                    </Row>
                    <Row>
                      <p>
                        Parkplätze und kostenfreies WLAN sind im Preis
                        inbegriffen
                      </p>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Tageswellness / 11:00 - 22:00</strong>
                        </p>
                        <p className="price">
                          <strong>490&nbsp;Kč/Person/Stunde</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          Mindestens 2 Personen im Wellnessbereich
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Nachtswellness / 22:00 - 08:00</strong>
                        </p>
                        <p className="price">
                          <strong>6 500&nbsp;Kč/nacht</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          Maximal 2 Personen im Wellnessbereich
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Privatwellness</strong>
                        </p>
                        <p className="price">
                          <strong>3 490&nbsp;Kč/Stunde</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          nach vorheriger Vereinbarung jederzeit, maximal 10
                          Personen im Wellnessbereich
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Sportmassage</strong>
                        </p>
                        <p className="price">
                          <strong>Preis per Telefon/E-Mail</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">60 Minuten</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Klassische Massage</strong>
                        </p>
                        <p className="price">
                          <strong>Preis per Telefon/E-Mail</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">30/60 Minuten</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Entspannungsmassage</strong>
                        </p>
                        <p className="price">
                          <strong>Preis per Telefon/E-Mail</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">30/60 Minuten</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>
                            Badetücher, Handtücher, Bademäntel, Bettwäsche Im
                            Wellnessbereich können Badeschlappen ausgeliehen
                            werden Hotel
                          </strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          Das Recht dazu hat die Hotelrezeption im Einzelfall In
                          allen Fällen ist vor dem Betreten des SPA eine
                          Anzahlung erforderlich
                        </p>
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <h3 className="text-center">Price list</h3>
                    </Row>
                    <Row>
                      <p>Parking and free Wi-Fi are included in the price</p>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Daily wellness / 11:00 - 22:00</strong>
                        </p>
                        <p className="price">
                          <strong>490&nbsp;Kč/person/hour</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          at least 2 people in the wellness area
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Night wellness / 22:00 - 08:00</strong>
                        </p>
                        <p className="price">
                          <strong>6 500&nbsp;Kč/night</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          a maximum of 2 people in the wellness area
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Private wellness rental</strong>
                        </p>
                        <p className="price">
                          <strong>3 490&nbsp;Kč/hour</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          by prior arrangement at any time, maximum 10 of people
                          in wellness
                        </p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Sports massage</strong>
                        </p>
                        <p className="price">
                          <strong>price by phone/email</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">60 minutes</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Classic massage</strong>
                        </p>
                        <p className="price">
                          <strong>price by phone/email</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">30/60 minutes</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>Relaxation massage</strong>
                        </p>
                        <p className="price">
                          <strong>price by phone/email</strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">30/60 minutes</p>
                      </div>
                    </Row>
                    <Row>
                      <div className="menu-text">
                        <p className="name-head">
                          <strong>
                            Bath towels, towels, bathrobes, sheets and slippers
                            they can be rented in the wellness hotel
                          </strong>
                        </p>
                      </div>
                      <div className="menu-text">
                        <p className="menu-text-desc">
                          the hotel reception has the right in individual cases
                          require a deposit before entering the SPA
                        </p>
                      </div>
                    </Row>
                  </>
                )}
              </div>
            </div>
            <div className="hotel container-lg">
              <Row className="hotel-row-1">
                <div className="col-lg-4 row-sm hotel-text-green box p-0">
                  {lang === "cs" ? (
                    <>
                      <h3>INFRASAUNA</h3>
                      <p>
                        Infrasauna poskytuje svým uživatelům radost ze saunování
                        při relativně nízké teplotě. O&nbsp;nic však nebudete
                        ochuzeni&nbsp;-&nbsp;s&nbsp;infračerveným teplem se
                        dokonale uvolníte, vypotíte a zrehabilitujete celé tělo.
                        Infrasauna je ideálním řešením pro ty, kterým je
                        klasická finská sauna ze zdravotních důvodů zapovězena.
                        Místnost je typicky vyhřívána na 45&nbsp;-&nbsp;55°C.
                      </p>
                    </>
                  ) : lang === "de" ? (
                    <>
                      <h3>INFRASAUNA</h3>
                      <p>
                        Eine Infrarotsauna bietet ihren Benutzern die Freude am
                        Saunabesuch bei relativ niedriger Temperatur. Aber Sie
                        werden nichts dagegen tun
                        verarmt-&nbsp;mit&nbsp;Infrarot-Wärme se Sie werden sich
                        perfekt entspannen, schwitzen und den ganzen Körper
                        rehabilitieren. Die Infrarotsauna ist die ideale Lösung
                        für diejenigen, die es sind klassische finnische Sauna
                        aus gesundheitlichen Gründen verboten. Der Raum wird
                        typischerweise auf 45&nbsp;–&nbsp;55&nbsp;°C beheizt.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>INFRASAUNA</h3>
                      <p>
                        An infrared sauna provides its users with the joy of
                        sauna use at a relatively low temperature. However, you
                        won't lose anything impoverished - with infrared heat se
                        you will perfectly relax, sweat and rehabilitate the
                        whole body. The infrared sauna is the ideal solution for
                        those who are classic Finnish sauna prohibited for
                        health reasons. The room is typically heated to 45 -
                        55°C.
                      </p>
                    </>
                  )}
                  <div className="card-footer-btn">
                    <Link className="icon-text" to="/contact">
                      <button className="card-button">
                        {lang === "cs"
                          ? "REZERVOVAT"
                          : lang === "en"
                          ? "RESERVE"
                          : "MEHR"}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg row-sm p-0 hotel-white-image">
                  <img src={infra} alt="room" className="hotel-img" />
                </div>
              </Row>
              <Row className="hotel-row">
                <div className="col-lg-4 row-sm hotel-text-white p-0">
                  {lang === "cs" ? (
                    <>
                      <h3>FINSKÁ SAUNA</h3>
                      <p>
                        Tradiční severský způsob relaxace, který dokonale
                        prohřeje vaše tělo, aktivuje váš metabolismus, prokrví
                        kůži, uvolní unavené svaly, zklidní mozek, pomůže vám
                        zbavit se stresu, migrén i&nbsp;deprese a&nbsp;vyplaví
                        škodlivé látky z těla. Jedná se o&nbsp;finskou suchou
                        saunu, proto je nezbytné abyste se před vstupem důkladně
                        osušili a&nbsp;nevstupovali mokří. Princip je založen na
                        střídání extrémních teplot s ochlazením studenou vodou.
                        Teplota se zde udržuje od 90&nbsp;-&nbsp;100°C. Po
                        opuštění sauny je možnost pro ochlazení využít sprchy
                        nebo ochlazovacího vědra s&nbsp;ledovou vodou. Pobyt ve
                        finské sauně doporučujeme 10&nbsp;-&nbsp;15&nbsp;minut.
                        Tento cyklus se opakuje 2&nbsp;-&nbsp;3x.
                      </p>
                    </>
                  ) : lang === "de" ? (
                    <>
                      <h3>FINNISCHE SAUNA</h3>
                      <p>
                        Eine traditionelle nordische Art der Entspannung, die
                        perfekt ist Es wärmt Ihren Körper, aktiviert Ihren
                        Stoffwechsel und fördert die Blutzirkulation Haut,
                        entspannt müde Muskeln, beruhigt das Gehirn, hilft Ihnen
                        Stress, Migräne und Depressionen loswerden und
                        auswaschen Schadstoffe aus dem Körper. Dies ist eine
                        finnische Dürre Daher ist es wichtig, dass Sie sich vor
                        dem Betreten der Sauna gründlich reinigen Sie trockneten
                        und wurden nicht nass. Das Prinzip basiert auf Extreme
                        Temperaturen wechseln sich mit Kaltwasserkühlung ab. Die
                        Temperatur wird hier zwischen 90 und 100&nbsp;°C
                        gehalten. Nach Nach dem Verlassen der Sauna können Sie
                        die Dusche zum Abkühlen nutzen oder ein Kühleimer mit
                        Eiswasser. Bleib drinnen Wir empfehlen
                        10&nbsp;-&nbsp;15&nbsp;Minuten zur finnischen Sauna.
                        Dieser Zyklus wird 2&nbsp;-&nbsp;3x wiederholt.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>FINNISH SAUNA</h3>
                      <p>
                        A traditional Nordic method of relaxation that perfectly
                        warms up your body, activates your metabolism, blood
                        circulation to the skin, relaxes tired muscles, calms
                        the brain, helps you get rid of stress, migraines and
                        depression and flushes harmful substances from the body.
                        This is a Finnish dry sauna, so it is essential that you
                        dry thoroughly before entering and do not enter wet. The
                        principle is based on alternating extreme temperatures
                        with cooling with cold water. The temperature here is
                        maintained from 90 - 100°C. After leaving the sauna, it
                        is possible to use a shower or a cooling bucket with ice
                        water to cool down. We recommend staying in the Finnish
                        sauna for 10 - 15 minutes. This cycle is repeated 2-3
                        times.
                      </p>
                    </>
                  )}
                  <div className="card-footer-btn">
                    <Link className="icon-text" to="/contact">
                      <button className="rest-button">
                        {lang === "cs"
                          ? "REZERVOVAT"
                          : lang === "en"
                          ? "RESERVE"
                          : "MEHR"}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="hotel-green-image col-lg row-sm p-0">
                  <img src={finskasauna} alt="room" className="hotel-img" />
                </div>
              </Row>
              <Row className="hotel-row">
                <div className="col-lg-4 row-sm hotel-text-green box p-0 massage">
                  {lang === "cs" ? (
                    <>
                      <h3>MASÁŽE</h3>
                      <h4>Sportovní masáž</h4>
                      <ul>
                        je masáž složená z&nbsp;uspořádaného souboru vhodných
                        masérských hmatů, které mají připravit sportovce jak
                        z&nbsp;fyzické tak z&nbsp;psychické stránky na podání
                        určitého sportovního výkonu.
                      </ul>
                      <h4>Klasická masáž</h4>
                      <ul>
                        pomáhá udržovat zdraví a&nbsp;odbourávat emocionální
                        stres. Působí relaxačně, uvolňující, omlazující
                        a&nbsp;zintenzivňuje oběh energie. Uvolňuje napjaté
                        svalstvo a&nbsp;pomáhá odstranit zatvrdliny na svalech
                        a&nbsp;úponech.
                      </ul>
                      <h4>Relaxační masáž</h4>
                      <ul>
                        odstraňuje únavu, stres, nespavost, bolesti hlavy,
                        navozuje příjemné pocity a&nbsp;působí velmi relaxačně.
                        Masáž je prováděna na celé tělo nebo jen na vybrané
                        části. Relaxační masáž je vhodná při přepracovanosti,
                        stresu či bolestech zad.
                      </ul>
                    </>
                  ) : lang === "de" ? (
                    <>
                      <h3>MASSAGE</h3>
                      <h4>Sportmassage</h4>
                      <ul>
                        ist eine Massage, die aus einem organisierten Satz
                        geeigneter Massagen besteht massierende Berührungen, die
                        Sportler darauf vorbereiten sollen sowohl von der
                        physischen als auch von der psychischen Seite zu
                        unterwerfen bestimmte sportliche Leistung.
                      </ul>
                      <h4>Klassische Massage</h4>
                      <ul>
                        hilft, die Gesundheit zu erhalten und Emotionen
                        abzubauen Stress. Es hat eine entspannende, entspannende
                        und verjüngende Wirkung und&nbsp;intensiviert die
                        Energiezirkulation. Löst Spannungen Muskeln
                        und&nbsp;hilft, Hornhaut an den Muskeln zu entfernen und
                        Anhänge.
                      </ul>
                      <h4>Entspannungsmassage</h4>
                      <ul>
                        beseitigt Müdigkeit, Stress, Schlaflosigkeit,
                        Kopfschmerzen, löst angenehme Gefühle aus und wirkt sehr
                        entspannend. Die Massage wird am ganzen Körper oder nur
                        an ausgewählten Körperteilen durchgeführt Teile. Die
                        Entspannungsmassage eignet sich für überanstrengte,
                        Stress oder Rückenschmerzen.
                      </ul>
                    </>
                  ) : (
                    <>
                      <h3>MASSAGE</h3>
                      <h4>Sports massage</h4>
                      <ul>
                        is a massage consisting of an organized set of suitable
                        massage touches, which are intended to prepare athletes
                        both physically and psychologically for the performance
                        of a certain sports performance.
                      </ul>
                      <h4>Classic massage</h4>
                      <ul>
                        helps maintain health and relieve emotional stress. It
                        has a relaxing, relaxing, rejuvenating and intensifying
                        effect energy cycle. It relaxes tense muscles and helps
                        remove calluses on muscles and tendons.
                      </ul>
                      <h4>Relaxation massage</h4>
                      <ul>
                        removes fatigue, stress, insomnia, headaches, it induces
                        pleasant feelings and has a very relaxing effect.
                        Massage it is performed on the whole body or only on
                        selected parts. Relaxation massage is suitable for
                        overwork, stress or back pain
                      </ul>
                    </>
                  )}
                  <div className="card-footer-btn">
                    <Link className="icon-text" to="/contact">
                      <button className="card-button">
                        {lang === "cs"
                          ? "REZERVOVAT"
                          : lang === "en"
                          ? "RESERVE"
                          : "MEHR"}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg row-sm p-0 hotel-white-image">
                  <span className="top">TOP</span>
                  <span className="ribbon"></span>
                  <span className="transparent"></span>
                  <img src={masage} alt="room" className="hotel-img" />
                </div>
              </Row>
              <Row className="hotel-row">
                <div className="col-lg-4 row-sm hotel-text-white p-0">
                  {lang === "cs" ? (
                    <>
                      <h3>JACUZZI</h3>
                      <p>
                        Vířívá koupel napomáhá k&nbsp;uvolnění
                        a&nbsp;flexibilitě svalů celého těla. Svaly se při této
                        proceduře zahřejí a&nbsp;stanou se ohebnější, což nám
                        umožní větší rozsah pohybu a&nbsp;taktéž větší hybnost,
                        která pomáhá předcházet zraněním. Podle umístění trysek
                        můžeme libovolně uvolňovat jednotlivé části těla či
                        záda.
                      </p>
                      <div className="card-footer-btn">
                        <Link className="icon-text" to="/contact">
                          <button className="rest-button">
                            {lang === "cs"
                              ? "REZERVOVAT"
                              : lang === "en"
                              ? "RESERVE"
                              : "MEHR"}
                          </button>
                        </Link>
                      </div>
                    </>
                  ) : lang === "de" ? (
                    <>
                      <h3>JACUZZI</h3>
                      <p>
                        Ein Whirlpoolbad hilft beim Entspannen und Flexibilität
                        der Muskeln des gesamten Körpers. Muskeln damit Die
                        Abläufe werden sie wärmer machen und flexibler werden,
                        was wir tun ermöglicht einen größeren Bewegungsbereich
                        und auch einen größeren Schwung, was Verletzungen
                        vorbeugt. Je nach Lage der Düsen Wir können einzelne
                        Körperteile frei loslassen bzw zurück.
                      </p>
                      <div className="card-footer-btn">
                        <Link className="icon-text" to="/contact">
                          <button className="rest-button">
                            {lang === "cs"
                              ? "REZERVOVAT"
                              : lang === "en"
                              ? "RESERVE"
                              : "MEHR"}
                          </button>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <h3>JACUZZI</h3>
                      <p>
                        A whirlpool bath helps to relax and flex the muscles of
                        the whole body. During this procedure, the muscles warm
                        up and become more flexible, which allows us a greater
                        range of motion and also greater momentum, which helps
                        prevent injuries. Depending on the location of the
                        nozzles, we can freely release individual parts of the
                        body or the back.
                      </p>
                      <div className="card-footer-btn">
                        <Link className="icon-text" to="/contact">
                          <button className="rest-button">
                            {lang === "cs"
                              ? "REZERVOVAT"
                              : lang === "en"
                              ? "RESERVE"
                              : "MEHR"}
                          </button>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                <div className="hotel-green-image col-lg row-sm p-0">
                  <img src={jacuzzi} alt="room" className="hotel-img" />
                </div>
              </Row>
            </div>
          </div>
        </LayoutOther>
      </Fade>
    </>
  );
};

export default Wellness;
